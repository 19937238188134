import React from "react";
import clsx from "clsx";
import { Button } from "react-bootstrap";

import { colors } from "../../../Theme/Colors";
import { text } from "../../../Theme/Text";
import styled from "styled-components";
import { ITableButton } from "./TableButton";

export const TableButton: React.FC<ITableButton.IProps> = ({
  handleClick,
  label,
  isDisable = false,
  isActive = false,
}) => {
  return (
    <TableButtons
      isActive={isActive}
      variant=""
      className={clsx(
        isDisable && "disabled",
        isActive ? "activeTableButton" : "tableButton"
      )}
      onClick={handleClick}
    >
      {label}
    </TableButtons>
  );
};

const TableButtons = styled(Button)<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? colors.black90 : "transparent")};
  color: ${({ isActive }) =>
    isActive ? colors.white_pure : colors.black90} !important;
  ${text.tableButton}
  border: none;
  min-width: fit-content;
  &:hover {
    background: ${({ isActive }) =>
      isActive ? colors.black90 : "transparent"};
    color: ${colors.black70} !important;
    border: none;
  }
`;
