import { Formik, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { Grid, Upload } from "antd";
import ImgCrop from "antd-img-crop";

import {
  Buttons,
  FormFields,
  FormFieldsProps,
  Typography,
} from "src/components/Basic";

import {
  CompanyProfileSettingsContainer,
  HeadingContainer,
  DetailsContainer,
  ImgContainer,
  EditContainer,
  FieldContainer,
  SocialAccountsContainer,
  CommonContainer,
  GalleryContainer,
  Container,
  ProfileContainer,
  GalleryEditContainer,
} from "./style";
import { ICONS } from "../../../assets/custom_Icons";
import { CompanyProfileSettingsFields, idPrefix, uploadPhoto } from "./utils";
import { useAppSelector } from "src/Redux";
import { formikSubmitHandler } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { API_CALLS } from "src/API_CALLS";
import { getCountryFromId } from "src/components/Basic/FormFields/FormikFields/countrySelector";
import { PayLoadCompanyProfileDetail } from "./CompanyProfileSettings";

const CompanyProfileSettings = () => {
  const screen = Grid.useBreakpoint();
  const { companyProfileDetail, company_image, cover_image, companyGallery } =
    useAppSelector((state) => state.settings);
  const [isAddPhotoButtonLoading, setIsAddPhotoButtonLoading] = useState(false);
  const [isProfileChangesButtonLoading, setIsProfileChangesButtonLoading] =
    useState(false);

  useEffect(() => {
    API_CALLS.COMPANY_SETTINGS.getCompanyDetails();
    API_CALLS.COMPANY_SETTINGS.getCompanyLogo();
    API_CALLS.COMPANY_SETTINGS.getCompanyCover();
    API_CALLS.COMPANY_SETTINGS.getCompanyGallery();
  }, []);

  const onSubmitHandler = (values: FormikValues) => {
    const body: PayLoadCompanyProfileDetail = {
      company_name: companyProfileDetail.company_name,
      display_name: values.display_name,
      city: values.city.value,
      size: values.size,
      company_bio: values.company_bio,
      company_description: values.company_description,
      company_website: values.company_website,
      social_linkedin: values.social_linkedin,
      social_facebook: values.social_facebook,
      social_twitter: values.social_twitter,
      social_instagram: values.social_instagram,
      social_youtube: values.social_youtube,
      stripe_id: companyProfileDetail.stripe_id,
    };
    API_CALLS.COMPANY_SETTINGS.updateCompanyDetails(body,setIsProfileChangesButtonLoading);
  };

  return (
    <CompanyProfileSettingsContainer>
      <HeadingContainer>
        <Typography
          content="COMPANY PROFILE SETTINGS"
          styleName="tableButton"
          color="black80"
        />
        <Typography
          content="Choose Template. Choose Template. Choose Template. Choose template and add information. Choose Template. This is where we talk talk talk."
          styleName="text1"
          color="black80"
        />
      </HeadingContainer>
      <DetailsContainer>
        <ProfileContainer>
          <div>
            <Typography
              content="Company Image"
              color="black80"
              styleName="inputLabel"
            />
            <ImgContainer width={screen.lg ? "200px" : "150px"}>
              {company_image ? (
                <img
                  src={`${process.env.REACT_APP_NOVA_BASE_URL}${company_image}`}
                  alt=""
                  width={screen.lg ? 200 : 150}
                  height={screen.lg ? 200 : 150}
                />
              ) : (
                <ICONS.AddImageIcon />
              )}
              <ImgCrop
                rotationSlider
                zoomSlider
                modalTitle="Company Image"
                modalOk="Upload"
              >
                <Upload
                  accept="image/png, image/jpeg"
                  listType="picture"
                  maxCount={1}
                  customRequest={({ file }) => {
                    API_CALLS.COMPANY_SETTINGS.uploadCompanyLogo(file as File);
                  }}
                >
                  <EditContainer right={"10px"}>
                    <ICONS.PenIcon />
                  </EditContainer>
                </Upload>
              </ImgCrop>
            </ImgContainer>
          </div>
          <div>
            <Typography
              content="Cover Image"
              color="black80"
              styleName="inputLabel"
            />
            <ImgContainer width={screen.lg ? "508px" : "320px"}>
              {cover_image ? (
                <img
                  src={`${process.env.REACT_APP_NOVA_BASE_URL}${cover_image}`}
                  alt=""
                  width={screen.lg ? 508 : 320}
                  height={screen.lg ? 200 : 150}
                />
              ) : (
                <ICONS.AddImageIcon />
              )}

              <ImgCrop
                rotationSlider
                zoomSlider
                aspect={2.5}
                modalTitle="Cover Image"
                modalOk="Upload"
              >
                <Upload
                  accept="image/png, image/jpeg"
                  listType="picture"
                  maxCount={1}
                  customRequest={({ file }) => {
                    API_CALLS.COMPANY_SETTINGS.uploadCompanyCover(file as File);
                  }}
                >
                  <EditContainer right={"24px"}>
                    <ICONS.PenIcon />
                  </EditContainer>
                </Upload>
              </ImgCrop>
            </ImgContainer>
          </div>
        </ProfileContainer>
        <Formik
          enableReinitialize
          initialValues={{
            ...companyProfileDetail,
            display_name: companyProfileDetail?.display_name ?? "",
            country:
              companyProfileDetail.country_code &&
              getCountryFromId(
                companyProfileDetail.country_code as FormFieldsProps.countryNames
              ),
            city: companyProfileDetail?.city_name
              ? {
                  label: companyProfileDetail?.city_name,
                  value: companyProfileDetail?.city_code,
                }
              : null,
          }}
          onSubmit={() => {}}
        >
          {(formik) => {
            return (
              <FieldContainer>
                <FormFields.Input
                  {...CompanyProfileSettingsFields.display_name}
                />
                <FormFields.CountrySelector
                  {...CompanyProfileSettingsFields.country}
                />
                <FormFields.CitySearch {...CompanyProfileSettingsFields.city} />
                <FormFields.Dropdown
                  {...CompanyProfileSettingsFields.company_size}
                />
                <CommonContainer>
                  <FormFields.TextArea
                    {...CompanyProfileSettingsFields.company_bio}
                  />
                </CommonContainer>
                <CommonContainer>
                  <FormFields.RichTextArea
                    {...CompanyProfileSettingsFields.company_description}
                  />
                </CommonContainer>
                <CommonContainer>
                  <FormFields.Input {...CompanyProfileSettingsFields.website} />
                </CommonContainer>
                <SocialAccountsContainer>
                  <FormFields.InputWithIcon
                    {...CompanyProfileSettingsFields.linkdin}
                  />
                  <FormFields.InputWithIcon
                    {...CompanyProfileSettingsFields.facebook}
                  />
                  <FormFields.InputWithIcon
                    {...CompanyProfileSettingsFields.twitter}
                  />
                  <FormFields.InputWithIcon
                    {...CompanyProfileSettingsFields.instagram}
                  />
                  <FormFields.InputWithIcon
                    {...CompanyProfileSettingsFields.youtube}
                  />
                </SocialAccountsContainer>
                <GalleryContainer>
                  <Typography
                    content="Gallery"
                    styleName="inputLabel"
                    color="black80"
                  />
                  <Container>
                    <Buttons
                      handleClick={() =>
                        uploadPhoto(setIsAddPhotoButtonLoading)
                      }
                      label="Add Photo"
                      variant="primary"
                      isDisable={isAddPhotoButtonLoading}
                    />
                    <div className="d-flex gap-2 flex-wrap">
                      {companyGallery.map((data) => (
                        <div className="gallery-container">
                          <img
                            src={`${process.env.REACT_APP_NOVA_BASE_URL}${data.path}`}
                            alt=""
                            width={50}
                            height={50}
                          />
                          <GalleryEditContainer
                            onClick={() => {
                              API_CALLS.COMPANY_SETTINGS.deleteCompanyGallery(
                                data.id,
                                setIsAddPhotoButtonLoading
                              );
                            }}
                          >
                            <ICONS.CrossIcon height={10} width={10} />
                          </GalleryEditContainer>
                        </div>
                      ))}
                    </div>
                  </Container>
                </GalleryContainer>
                <div className="w-auto">
                  <Buttons
                    handleClick={() => {
                      formikSubmitHandler({
                        formFields: CompanyProfileSettingsFields,
                        formik,
                        onSubmitHandler,
                        idPrefix,
                      });
                    }}
                    label="Save Profile Changes"
                    variant="success"
                    isDisable={isProfileChangesButtonLoading}
                  />
                </div>
              </FieldContainer>
            );
          }}
        </Formik>
      </DetailsContainer>
    </CompanyProfileSettingsContainer>
  );
};

export default CompanyProfileSettings;
