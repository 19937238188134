import instance from "src/axios/axios-user";
import { IOverview } from "src/components/Settings/Overview/Overview";
import { ITeamManagement } from "src/components/pages/TeamManagement/TeamManagement";
import { API_PATHS } from "src/utils/API_PATHS";

const getTeamOverview = () => instance.get(API_PATHS.companyTeamOverview);

const getUnverifiedMemberDetails = (email: string, token: string) =>
  instance.get(
    `${API_PATHS.companyTeamMemberVerify}?email=${email}&token=${token}`
  );

const activeDeactiveUser = (isactive: boolean, id: string) => {
  const formdata = new FormData();
  formdata.append("member_id", id);
  return instance.put(
    isactive
      ? API_PATHS.companyTeamMemberDeactivate
      : API_PATHS.companyTeamMemberActivate,
    formdata,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

const addMemberCompany = (value: ITeamManagement.CompanyMember) => {
  const formdata = new FormData();
  formdata.append("first_name", value.first_name);
  formdata.append("last_name", value.last_name);
  formdata.append("email", value.email);
  formdata.append("token", value.token);
  formdata.append("password", value.password);
  formdata.append("verify_password", value.verify_password);
  return instance.post(API_PATHS.addMemberCompany, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const addTeamMember = (value: IOverview.TeamMember) => {
  const formdata = new FormData();
  formdata.append("first_name", value.first_name);
  formdata.append("last_name", value.last_name);
  formdata.append("email", value.email);
  formdata.append("user_role", value.user_role);
  formdata.append("base_url", value.base_url);
  return instance.post(API_PATHS.addTeamMember, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const updateTeamMember = (value: IOverview.UpdateTeamMember) => {
  const formdata = new FormData();
  formdata.append("first_name", value.first_name);
  formdata.append("last_name", value.last_name);
  formdata.append("email", value.email);
  formdata.append("user_role", value.user_role);
  formdata.append("member_id", value.member_id);
  return instance.put(API_PATHS.updateTeamMember, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const deleteInvitedMember = (id: string) => {
  return instance.delete(`${API_PATHS.deleteInvitedMember}/${id}`);
};
const updateInvitedMember = (
  id: string,
  first_name: string,
  last_name: string
) => {
  const formdata = new FormData();
  formdata.append("id", id);
  formdata.append("first_name", first_name);
  formdata.append("last_name", last_name);
  return instance.put(API_PATHS.updateInvitedMember, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const TEAM_OVERVIEW = {
  getTeamOverview,
  activeDeactiveUser,
  getUnverifiedMemberDetails,
  addMemberCompany,
  addTeamMember,
  updateTeamMember,
  deleteInvitedMember,
  updateInvitedMember,
};
