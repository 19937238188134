import { theme } from "src/components/Theme";
import styled from "styled-components";

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 44px;
  @media (min-width: 992px) {
    padding: 0;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.black10};
  margin-bottom: 40px;
  @media (min-width: 992px) {
    display: none;
  }
`;
const AuthBackGroundWrapper = styled.div`
  display: none;
  min-width: 450px;
  padding-bottom: 44px;
  border-right: 1px solid ${theme.colors.black30};
  @media (min-width: 992px) {
    display: block;
    min-height: 100vh;
    background: white;
  }
`;
const SubContainer = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    display: flex;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-item: center;
    padding-top: 64px;
  }
`;
const LogoContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  height:37px;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    margin: 0 auto 48px;
    width: 190px;
    height: 95px;
  }
  }
`;
const PreContainer = styled.pre`
  ${({ theme }) => theme.text.authText};
  background: transparent;
  color: ${({ theme }) => theme.colors.white_pure};
  padding: 0px;
  overflow: hidden;
  margin: 30px 0 27px 40px;
`;
const TextContainer = styled.div`
  position: absolute;
  width: 366px;
  top: 66px;
  left: 42px;
  background: transparent;
`;
const AuthBackGround = styled.div`
  background: url(/static/images/AuthLayout/rocket_with_clouds.svg);
  background-repeat: no-repeat;
  background-position: center calc(100% + 40px);
  background-size: 1000px 326px;

  @media (min-width: 768px) {
    background-size: 1180px 356px;
    background-position: center calc(100% + 60px);
    background-size: 1100px 326px;
  }
  @media (min-width: 992px) {
    background: none;
  }
`;

export const AuthLayoutStyles = {
  AuthContainer,
  Line,
  AuthBackGroundWrapper,
  AuthBackGround,
  SubContainer,
  Wrapper,
  LogoContainer,
  PreContainer,
  TextContainer,
};
