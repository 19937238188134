import React from "react";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { colors } from "src/components/Theme/Colors";
import styled from "styled-components";
import { IDashboard } from "./Dashboard";
import clsx from "clsx";

const Cards: React.FC<IDashboard.ICard> = ({
  title,
  lastWeekCount,
  totalCount,
  icon,
  isShownLastWeek = false,
  isBottom = false,
}) => {
  return (
    <CustomCard>
      <CardHeading isBottom={isBottom}>
        <span className=" tx-13 ">{title} </span>
        {isShownLastWeek && <div className=" tx-11 ">(Last 30 days)</div>}
      </CardHeading>
      <h4
        className={clsx(
          "tx-20 font-weight-semibold",
          isBottom ? "mb-0" : "mb-3"
        )}
      >
        {totalCount}
      </h4>
      <div className="d-flex flex-column">
        <p className=" tx-12  d-flex gap-2 align-items-center mb-0 ">
          <span className="text-muted"> Last week</span>
          <FontAwesomeIcon
            icon={faCaretUp}
            style={{
              color:
                lastWeekCount >= 0
                  ? colors.success_default
                  : colors.danger_default,
              transform: lastWeekCount < 0 ? "rotate(180deg)" : "",
            }}
          />
        </p>
        <span
          className={clsx(
            " font-weight-semibold",
            lastWeekCount >= 0 ? "text-success" : "text-danger"
          )}
          style={{marginTop:"2px"}}
        >
          {lastWeekCount >= 0 && "+"}
          {lastWeekCount}
        </span>
      </div>
      {icon}
    </CustomCard>
  );
};

export default Cards;
const CustomCard = styled(Card)`
  width: 100%;
  padding: 16px 16px 16px 24px;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    width: 100%;
  } ;
`;
const CardHeading = styled.h6<{ isBottom: boolean }>`
  display: ${({ isBottom }) => (isBottom ? "block" : "flex")};
  margin-bottom: 11px;
  justify-content: start;
  align-items: center;
  gap: 4px;
  .dark-theme & {
    color:${colors.black20}
  }
`;
