import { CalendarIcon } from "./CalendarIcon";
import { DatePickerIcons } from "./DatePicker";
import { HidePasswordIcon } from "./HidePasswordIcon";
import { InfoIcon } from "./InfoIcon";
import { Decrement, Increment } from "./PlusAndMinusIcons";
import { ShowPasswordIcon } from "./ShowPasswordIcon";

export const FieldIcons = {
  Increment,
  Decrement,
  HidePasswordIcon,
  ShowPasswordIcon,
  CalendarIcon,
  InfoIcon,
  DatePickerIcons,
};
