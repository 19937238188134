import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const getTag = () => {
  return instance.get(API_PATHS.messageTemplateTag);
};

const getMessageTemplate = () => {
  return instance.get(API_PATHS.messageTemplate);
};
const createMessageTemplate = (body: {
  template_name: string;
  subject: string;
  message: string;
}) => {
  return instance.post(API_PATHS.messageTemplate, body);
};
const updateMessageTemplate = (body: {
  template_name: string;
  subject: string;
  message: string;
  id: number;
}) => {
  return instance.put(`${API_PATHS.messageTemplate}/${body.id}`, body);
};

const deleteMessageTemplate = (id: number) => {
  return instance.delete(`${API_PATHS.messageTemplate}/${id}`);
};

export const MESSAGE_TEMPLATE = {
  getTag,
  getMessageTemplate,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
};
