import styled from "styled-components";

const Container = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 50px 0;

  @media (min-width: 992px) {
    width: 100%;
    margin: 0;
    padding: 50px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  .d-md-block {
    margin-left: 0;
  }
  .form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (min-width: 768px) {
      flex-direction: initial;
      gap: 0;
    }
  }
`;

export const FormExampleStyles = { Container };
