import { Typography } from "src/components/Basic";
import { FormFieldCommon } from "src/components/Basic/FormFields";
import { useAppSelector } from "src/Redux";
import { BoostedCampaignStyles } from "../../Styles";
import { BidSlider } from "./BidSlider";
import { BidInputs } from "./BidInputs";

export const BidData = () => {
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const bidHelpText = { info: "Setup the campaign", title: "campaign" };

  return (
    <>
      <BoostedCampaignStyles.BidPerView>
        <Typography
          content="Bid Per View"
          styleName="subtitle3"
          color={isDarkTheme ? "black10" : "black_pure"}
        />
        <FormFieldCommon.Info helpText={bidHelpText} />
      </BoostedCampaignStyles.BidPerView>
      <BoostedCampaignStyles.BidData>
        <BidInputs />
        <BidSlider />
      </BoostedCampaignStyles.BidData>
    </>
  );
};
