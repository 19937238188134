import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import { store, actions } from "src/Redux";

const isTokenExpired = (token: string) => {
  if (token) {
    return (
      Date.now() >= JSON.parse(window.atob(token.split(".")[1])).exp * 1000
    );
  }
};
const isUserLoggedIn = () => {
  const { login } = store.getState();
  if (login.isLoggedin) return true;

  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
  if (!accessToken) {
    store.dispatch(actions.login.setLoginState(false));
    return false;
  }

  const isExpired = isTokenExpired(accessToken);
  if (isExpired) {
    store.dispatch(actions.login.setLoginState(false));
    return false;
  }

  store.dispatch(actions.login.setLoginState(true));
  return true;
};

export const HELPERS = { isUserLoggedIn };
