const FacebookIcon = () => {
  return (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08448 3.65402H9.87048V0.633028C9.00574 0.543108 8.13688 0.498713 7.26749 0.500028C4.68349 0.500028 2.91649 2.07703 2.91649 4.96502V7.45402H0V10.836H2.91649V19.5H6.41249V10.836H9.31948L9.75648 7.45402H6.41249V5.29752C6.41249 4.30002 6.67849 3.65402 8.08448 3.65402Z"
        fill="#58B4FA"
      />
    </svg>
  );
};

export default FacebookIcon;
