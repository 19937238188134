import { createSlice } from "@reduxjs/toolkit";
import { CandidateProfile } from "src/components/Settings/CandidateProfile/CandidateProfile";
import {
  CompanyProfileDetail,
  Image,
} from "src/components/Settings/CompanyProfileSettings/CompanyProfileSettings";
import { IEmailNotification } from "src/components/Settings/EmailNotification";
import { TableProps as OverviewTable } from "src/components/Settings/Overview/Overview";
import { TableProps as ReceiptTable } from "src/components/Settings/Receipts/Receipts";

const initialState: {
  userData: OverviewTable.column[];
  editUserData: OverviewTable.column;
  receiptData: ReceiptTable.Receipt[];
  companyGallery: Image[];
  company_image: string;
  cover_image: string;
  profile_picture: string;
  companyProfileDetail: CompanyProfileDetail;
  unverifiedMemberDetails: OverviewTable.UnverifiedMemberDetails;
  candidateDetails: CandidateProfile.CandidateDetails;
  emailNotification: IEmailNotification;
} = {
  userData: [],
  editUserData: {} as OverviewTable.column,
  receiptData: [],
  companyGallery: [],
  company_image: "",
  cover_image: "",
  profile_picture: "",
  companyProfileDetail: {
    company_name: "",
    display_name: "",
    city_code: "",
    city_name: "",
    country_code: "",
    size: "",
    company_bio: "",
    company_description: "",
    company_website: "",
    social_linkedin: "",
    social_facebook: "",
    social_twitter: "",
    social_instagram: "",
    social_youtube: "",
    stripe_id: "",
  },
  unverifiedMemberDetails: {
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    company_logo: "",
    valid_token: false,
  },
  candidateDetails: {
    first_name: "",
    last_name: "",
    email: "",
  },
  emailNotification: {} as IEmailNotification,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setReceiptData: (state, action) => {
      state.receiptData = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUnverifiedMemberDetails: (state, action) => {
      state.unverifiedMemberDetails = action.payload;
    },
    setEditUserData: (state, action) => {
      state.editUserData = action.payload;
    },
    setCompanyProfileDetail: (state, action) => {
      state.companyProfileDetail = action.payload;
    },
    setCompanyImage: (state, action) => {
      state.company_image = action.payload;
    },
    setCoverImage: (state, action) => {
      state.cover_image = action.payload;
    },
    setCompanyGallery: (state, action) => {
      state.companyGallery = action.payload;
    },
    setCandidateDetails: (state, action) => {
      state.candidateDetails = action.payload;
    },
    setCandidateProfilePhoto: (state, action) => {
      state.profile_picture = action.payload;
    },
    setEmailNotification: (state, action) => {
      state.emailNotification = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
