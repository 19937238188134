import { useState } from "react";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";

import { Buttons, FormFields, FormFieldsProps } from "src/components/Basic";
import { jobCreateUtils } from "../CreateJob/1-JobCreate/Utils";
import { CreateJobStyles } from "../CreateJob/Styles";
import { JobProps } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";
import { BoostedCampaign } from "../CreateJob/2-Advertise/BoostedCampaign";

type ProgramaticForm = {
  country: FormFieldsProps.Country;
  city: { label: string; value: string };
  job_title: string;
};
export const Programatic = () => {
  const { job_title, country, city } = jobCreateUtils.initialJobCreateFields;

  const [submitClicked, setsubmitClicked] = useState(false);
  const onChangeHandler = () => setsubmitClicked(false);

  return (
    <Formik initialValues={{} as ProgramaticForm} onSubmit={() => {}}>
      {(formik) => {
        return (
          <div style={{ padding: "40px", paddingBottom: "84px" }}>
            <CreateJobStyles.CreateJobFormContainer>
              <Row>
                <Col md={6} lg={5} xxl={4} className="left-side">
                  <FormFields.Input {...job_title} {...{ onChangeHandler }} />
                  <FormFields.CountrySelector
                    {...country}
                    {...{ onChangeHandler }}
                  />
                  <FormFields.CitySearch {...city} {...{ onChangeHandler }} />
                </Col>
              </Row>
              <CreateJobStyles.NextButtonWrapper>
                <Buttons
                  label="Next"
                  variant="primary"
                  isDisable={false}
                  handleClick={() => {
                    setsubmitClicked(true);
                    const { job_title, city } = formik.values;
                    const data: JobProps.getChartDataAPI = {
                      job_title,
                      city_id: parseInt(city?.value),
                    };
                    API_CALLS.JOBS.getChartData(data);
                  }}
                  width="fit-content"
                />
              </CreateJobStyles.NextButtonWrapper>
            </CreateJobStyles.CreateJobFormContainer>
            {submitClicked && (
              <div style={{ paddingTop: "40px" }}>
                <BoostedCampaign />
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};
