import styled from "styled-components";

import { theme } from "src/components/Theme";
import { AdvertiseProps } from "./Advertise";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 20px 10px 84px 10px;

  @media (min-width: 576px) {
    padding: 20px;
    padding-bottom: 116px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 768px) {
    padding-bottom: 140px;
  }
  @media (min-width: 992px) {
    justify-content: center;
  }
`;
const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  max-width: 392px;
`;
const CardListItem = styled.div<AdvertiseProps.Card>`
  width: 100%;
  height: 45px;
  border: 1px solid ${theme.colors.black10};
  border-radius: 5px;
  background: ${({ isOpen }) =>
    isOpen
      ? theme.colors.primary_default + " !important"
      : theme.colors.white_pure};
  box-shadow: 1px 1px 2px 0px rgba(154, 154, 204, 0.3);
  padding: 8px 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
const Card = styled.div<AdvertiseProps.Card>`
  width: 100%;
  max-width: 350px;
  min-height: 45px;
  border: 1px solid ${theme.colors.black10};
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  justify-content: ${({ isOpen }) => (isOpen ? "start" : "center")};
  align-items: ${({ isOpen }) => (isOpen ? "start" : "center")};
  padding: ${({ isOpen }) => (isOpen ? "18px 16px 14px 16px" : "0 16px")};
  box-shadow: ${({ advertType, isOpen }) => {
    const existingBoxShadow = "1px 1px 7px rgba(154, 154, 204, 0.1)";
    if (advertType === "boosted" || !isOpen) return existingBoxShadow;
    const mobileShadow = theme.Effects.job_card_shadow;
    return `${existingBoxShadow} , ${mobileShadow}`;
  }};
  transition: padding 0.5s linear, height 0.5s linear, background 0s linear;

  @media (max-width: 575px) {
    background: ${({ advertType, isOpen }) => {
      if (isOpen) return theme.colors.white_pure;
      if (advertType === "boosted")
        return `${theme.colors.primary_default} !important`;
      return theme.colors.black10;
    }};
  }
  @media (min-width: 576px) {
    width: 275px;

    padding: 20px;
    padding-top: 42px;
    position: relative;
    align-items: center;
    box-shadow: none;
    justify-content: start;
    background: ${theme.colors.white_pure};
  }
  @media (min-width: 992px) {
    padding-top: 36px;
    height: 490px;
  }
  @media (min-width: 1200px) {
    width: 300px;
  }
  @media (min-width: 1350px) {
    width: 300px;
    padding: 36px;
  }

  &:hover {
    @media (min-width: 576px) {
      border-color: ${theme.colors.primary_default};
      box-shadow: ${theme.Effects.hover_active_blue};
    }
  }
`;
const SelectButtonWrapper = styled.div`
  padding-top: 25px;
  padding-left: 4px;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-select {
      width: 228px;
      color: ${theme.colors.black70} !important;
      font-weight: 400 !important;
    }
  }
  &:hover {
    .btn-select {
      color: ${theme.colors.white_pure} !important;
    }
  }
  @media (min-width: 992px) {
    padding: 0;
    position: absolute;
    bottom: 36px;
  }
`;
const PointsWrapper = styled.div`
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
  @media (min-width: 576px) {
    padding-top: 23px;
  }
`;
const StandardPointer = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50px;
  background-color: ${theme.colors.black70};
`;
const PointerWrapper = styled.span<AdvertiseProps.PointerWrapper>`
  padding-top: ${({ advertType }) => {
    if (advertType === "standard") return 8;
    if (advertType === "boosted") return 6;
    return 0;
  }}px;
  padding-left: 4px;
  @media (min-width: 576px) {
    padding-left: 0px;
  }
`;
const Point = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  gap: 14px;
`;
const AdvertsLeft = styled.div`
  padding: 3px 18px;
  background: ${theme.colors.success_default};
  border-radius: 800px;
  @media (min-width: 576px) {
    position: absolute;
    top: 12px;
    right: 12px;
  }
`;
const AdvertsLeftTitle = styled.div`
  padding: 3px 18px;
  background: ${theme.colors.success_default};
  border-radius: 800px;
  position: absolute;
  top: -16px;
  left: 0px;
  @media (min-width: 768px) {
    position: relative;
    top: 0;
    left: 14px;
  }
`;
const SetCampaignBudget = styled.div`
  background: ${theme.colors.primary_default};
  padding: 3px 18px;
  margin-top: 20px;
  border-radius: 800px;
  @media (min-width: 576px) {
    margin: 0;
    position: absolute;
    top: 12px;
  }
`;
const SetCampaignBudgetTitle = styled.div<{ isOpen: boolean }>`
  background: ${theme.colors.primary_default};
  padding: 3px 18px;
  margin-top: 20px;
  border-radius: 800px;
  margin: 0;
  position: absolute;
  top: -16px;
  left: 0px;
  @media (min-width: 768px) {
    position: relative;
    top: 0;
    left: 24px;
    background: ${({ isOpen }) =>
      isOpen ? theme.colors.white_pure : theme.colors.primary_default};
    box-shadow: ${({ isOpen }) =>
      isOpen ? "0px 4px 4px 0px rgba(76, 93, 118, 0.25) inset" : "none"};
  }
`;
const VerticalLine = styled.div`
  width: 0.1px;
  height: 490px;
  border: 0.5px dashed ${theme.colors.primary_default};
`;
const TitleBar = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 576px) {
    flex-direction: column;
    gap: 0;
    justify-content: start;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: start;
  @media (min-width: 576px) {
    flex-direction: column;
    gap: 4px;
  }
  @media (min-width: 992px) {
    gap: 0;
  }
`;

export const AdvertiseStyles = {
  Container,
  CardList,
  CardListItem,
  Card,
  SelectButtonWrapper,
  PointsWrapper,
  PointerWrapper,
  Point,
  StandardPointer,
  AdvertsLeft,
  SetCampaignBudget,
  AdvertsLeftTitle,
  SetCampaignBudgetTitle,
  VerticalLine,
  TitleBar,
  TitleWrapper,
};
