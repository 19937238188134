import { IIconProps } from "./customIcons";

export const BackArrowIcon = ({ fill, height, width }: IIconProps.Iprops) => {
  return (
    <svg
      width={width || "5"}
      height={height || "8"}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76724 0.224482C4.91632 0.366671 5 0.559015 5 0.759505C5 0.959996 4.91632 1.15234 4.76724 1.29453L1.93369 4.01897L4.76724 6.70547C4.91632 6.84766 5 7.04001 5 7.2405C5 7.44099 4.91632 7.63333 4.76724 7.77552C4.69283 7.84665 4.6043 7.90311 4.50676 7.94164C4.40922 7.98016 4.3046 8 4.19893 8C4.09326 8 3.98864 7.98016 3.8911 7.94164C3.79356 7.90311 3.70503 7.84665 3.63062 7.77552L0.236767 4.55779C0.161744 4.48724 0.102196 4.40331 0.0615588 4.31083C0.0209218 4.21835 -4.30559e-07 4.11916 -4.29364e-07 4.01897C-4.28169e-07 3.91879 0.0209218 3.8196 0.0615588 3.72712C0.102196 3.63464 0.161744 3.5507 0.236767 3.48016L3.63062 0.224482C3.70503 0.153352 3.79356 0.0968942 3.8911 0.0583663C3.98864 0.0198378 4.09326 9.204e-07 4.19893 9.24277e-07C4.3046 9.28155e-07 4.40922 0.0198378 4.50676 0.0583663C4.6043 0.0968942 4.69283 0.153352 4.76724 0.224482Z"
        fill={fill || "#4A4A69"}
      />
    </svg>
  );
};
