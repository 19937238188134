import { FormFieldsProps } from "src/components/Basic/FormFields";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const idPrefix = "verify";

const first_name: FormFieldsProps.Input = {
  placeholder: "First Name",
  label: "First Name",
  fieldName: "first_name",
  idPrefix,
  isRequired: false,
};
const last_name: FormFieldsProps.Input = {
  fieldName: "last_name",
  idPrefix,
  isRequired: false,
  placeholder: "Last Name",
  label: "Last Name",
};
const email: FormFieldsProps.Input = {
  placeholder: "Email",
  label: "Email",
  fieldName: "email",
  idPrefix,
  isRequired: false,
  disabled: true,
};
const password: FormFieldsProps.Password = {
  placeholder: "password",
  label: "Password",
  fieldName: "password",
  idPrefix,
  isRequired: true,
};
const verify_password: FormFieldsProps.Password = {
  placeholder: "Confirm Password",
  label: "Confirm Password",
  fieldName: "verify_password",
  idPrefix,
  isRequired: true,
};

const TeamManagementContainer = styled.div`
  display: flex;
  background: ${theme.colors.white_pure};
  width: 100%;
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
const Container = styled.div`
  background: ${theme.colors.black90};
  @media (max-width: 991px) {
    display: none;
  }
`;
const ButtonContainer = styled.div`
  margin: 44px 66px 0;
  button {
    width: -webkit-fill-available;
    @media (max-width: 991px) {
      width: min-content;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0px 0px;
  }
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  margin: 40px auto 146px;
  gap: 24px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 40px 20px;
    gap: 20px;
  }
`;
const VerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  align-items: center;
  margin: 100px auto 146px;
  gap: 78px;
  & > div > div {
    display: flex;
    justify-content: center;
  }
  & > div > button,
  & > div > button:hover {
    color: ${theme.colors.primary_default} !important;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 40px 20px;
    min-height: calc(100vh - 145px);
  }
  .dark-theme & svg circle {
    fill: ${theme.colors.black110};
  }
`;
export const TeamManagementHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  & .main-header {
    display: flex;
    justify-content: center;
    @media (min-width: 992px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
      justify-content: flex-start;
    }
  }
`;
export const ErrorLabel = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.secondary_hover};
  ${({ theme }) => theme.text.authInputErrorMsg}
`;
const PreContainer = styled.pre`
  ${({ theme }) => theme.text.authText};
  background: transparent;
  color: ${({ theme }) => theme.colors.white_pure};
  padding: 0px;
  overflow: hidden;
  margin: 30px 0 27px 40px;
`;
const TextContainer = styled.div`
  position: absolute;
  width: 366px;
  top: 122px;
  left: 42px;
  background: transparent;
`;

export const verifyFormFields = {
  first_name,
  last_name,
  email,
  password,
  verify_password,
};
export const VerifyStyle = {
  DetailsContainer,
  ButtonContainer,
  Container,
  TeamManagementContainer,
  TeamManagementHeader,
  VerifyContainer,
  ErrorLabel,
  TextContainer,
  PreContainer,
};
