import instance from "src/axios/axios-user";
import { Key } from "src/components/Pipeline/PipeLine";
import { API_PATHS } from "src/utils/API_PATHS";

const pipelineApplicants = (body: {
  job_id: string;
  page: number;
  size: number;
  sort: string;
  status: Key;
}) => {
  const config = {
    method: "post",
    url: API_PATHS.pipelineApplicants,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};
const getPipelineOverview = (id: string) => {
  return instance.get(`${API_PATHS.pipelineOverview}/${id}`);
};
const updatePipelineOverview = (
  body: {
    candidate_id: number;
    status_id: string;
  },
  job_id: string
) => {
  const config = {
    method: "put",
    url: `${API_PATHS.pipelineOverview}/${job_id}`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};

const getCandidateOverview = (id: string) => {
  return instance.get(`${API_PATHS.candidateOverview}/${id}`);
};
const getCandidateExperience = (id: number) => {
  return instance.get(`${API_PATHS.candidateExperience}/${id}`);
};
const getCandidateFiles = (id: number) => {
  return instance.get(`${API_PATHS.candidateFiles}/${id}`);
};
const getCandidateQualifiers = (id: number) => {
  return instance.get(`${API_PATHS.candidateQualifiers}/${id}`);
};
const getCandidateComment = (id: string) => {
  return instance.get(`${API_PATHS.candidateComments}/${id}`);
};
const postCandidateComment = (body: {
  application_id: number;
  comment: string;
  user_id: string;
}) => {
  const config = {
    method: "post",
    url: API_PATHS.candidateComments,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(body),
  };
  return instance(config);
};
export const PIPELINE = {
  postCandidateComment,
  getCandidateComment,
  getCandidateQualifiers,
  getCandidateFiles,
  pipelineApplicants,
  getPipelineOverview,
  updatePipelineOverview,
  getCandidateOverview,
  getCandidateExperience,
};
