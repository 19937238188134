const TwitterIcon = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7895 2.15652C20.9706 2.51704 20.1045 2.75537 19.2183 2.86408C20.1533 2.29728 20.8539 1.4056 21.1903 0.354445C20.3117 0.885188 19.3499 1.25913 18.3467 1.46001C17.6762 0.722126 16.7833 0.230949 15.8081 0.0635168C14.8328 -0.103916 13.8304 0.0618662 12.958 0.534862C12.0856 1.00786 11.3926 1.76131 10.9876 2.67706C10.5827 3.59281 10.4887 4.61905 10.7204 5.59484C8.94396 5.50366 7.20626 5.03426 5.62022 4.21715C4.03418 3.40003 2.63527 2.25347 1.51437 0.851951C1.12122 1.54864 0.914634 2.33764 0.915158 3.14048C0.913764 3.88605 1.09407 4.62041 1.44002 5.27816C1.78597 5.93592 2.28683 6.49666 2.898 6.91046C2.18764 6.89085 1.49245 6.69742 0.871579 6.34662V6.4019C0.876903 7.44654 1.23764 8.45725 1.89276 9.26307C2.54788 10.0689 3.45718 10.6204 4.46684 10.8242C4.07818 10.9442 3.67466 11.0075 3.26842 11.0121C2.98722 11.0088 2.70671 10.9829 2.42953 10.9347C2.71705 11.8333 3.27346 12.6187 4.02133 13.1814C4.7692 13.7442 5.67134 14.0564 6.60221 14.0745C5.03032 15.3297 3.08962 16.0147 1.08947 16.0203C0.725301 16.0216 0.361417 15.9994 0 15.954C2.04214 17.292 4.42197 18.0023 6.85279 17.9993C8.53024 18.017 10.1944 17.6953 11.7479 17.053C13.3015 16.4108 14.7134 15.4608 15.9011 14.2586C17.0888 13.0564 18.0286 11.6261 18.6655 10.0513C19.3024 8.47641 19.6236 6.78855 19.6105 5.08628C19.6105 4.89833 19.6105 4.69933 19.6105 4.50032C20.4654 3.85336 21.2028 3.06024 21.7895 2.15652Z"
        fill="#58B4FA"
      />
    </svg>
  );
};

export default TwitterIcon;
