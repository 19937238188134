import { AdvertiseProps } from "./Advertise";

const standard: AdvertiseProps.AdvertiseType = {
  title: "Standard",
  price: { price: "19", isStriked: true },
  points: [
    {
      bold: "Hi ",
      normal: "sentence with 30 characters",
      advertType: "standard",
    },
    {
      bold: "2 Free ",
      normal: "Domain Name with 5 day",
      advertType: "standard",
    },
    {
      bold: "30 characters ",
      normal: "per bullet point",
      advertType: "standard",
    },
    { bold: "2 Free ", normal: "Domain Name", advertType: "standard" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "standard" },
  ],
};
const premium: AdvertiseProps.AdvertiseType = {
  title: "Premium",
  price: { price: "39", isStriked: false },
  points: [
    {
      bold: "Hi ",
      normal: "sentence with 30 characters",
      advertType: "premium",
    },
    {
      bold: "2 Free ",
      normal: "Domain Name with 5 day",
      advertType: "premium",
    },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
  ],
};
const boosted: AdvertiseProps.AdvertiseType = {
  title: "Boosted",
  price: {} as AdvertiseProps.price,
  points: [
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
  ],
};

const points = { standard, premium, boosted };
export const AdvertiseUtils = { points };
