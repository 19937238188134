import { useContext, useState } from "react";

import { FormFields } from "src/components/Basic";
import { BoostedCampaignContext } from "../..";
import { FormFieldCommon } from "src/components/Basic/FormFields";
import styled from "styled-components";
import { BoostedCampaignUtils } from "../../Utils";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";

export const CampaignInputs = () => {
  const dispatch = useAppDispatch();
  const { isTotalCampaignManuallyUpdated } = useAppSelector(
    (store) => store.job
  );

  const { currency, formik, bidValue } = useContext(BoostedCampaignContext);
  const {
    campaign_duration_from,
    campaign_duration_to,
    total_campaign_budget,
  } = BoostedCampaignUtils.formFields;
  const { setFieldValue, values } = formik;

  const fromStartDate = new Date();
  const toDate = new Date(fromStartDate);
  toDate.setDate(fromStartDate.getDate() + 1);
  const [toStartDate, setToStartDate] = useState(toDate);

  const bidVal = parseFloat(bidValue);
  const totalBudget = parseFloat(
    (parseFloat(values.total_campaign_budget) / 10).toFixed(2)
  );

  if (bidVal > totalBudget && !isTotalCampaignManuallyUpdated) {
    setFieldValue(total_campaign_budget.fieldName, (bidVal * 10).toFixed(2));
  }
  return (
    <FormContainer>
      <div>
        <FormFieldCommon.Label content={`Campaign Duration*`} />
        <CampaignDuration>
          <InputWrapper>
            <FormFields.DatePickerElement
              {...campaign_duration_from}
              minDate={fromStartDate}
              onChangeHandler={(date) => {
                const nextDate = new Date(date);
                nextDate.setDate(date.getDate() + 1);
                setToStartDate(nextDate);
                const toDate = values.campaign_duration_to;
                const isToDateLessThanToStartDate = toDate && toDate < nextDate;
                if (isToDateLessThanToStartDate)
                  setFieldValue(campaign_duration_to.fieldName, nextDate);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <FormFields.DatePickerElement
              {...campaign_duration_to}
              minDate={toStartDate}
            />
          </InputWrapper>
        </CampaignDuration>
      </div>
      <InputWrapper>
        <FormFields.InputIcon
          {...total_campaign_budget}
          icon={<div>{currency}</div>}
          onChangeHandler={() => {
            dispatch(actions.job.setTotalCampaignManuallyUpdated(true));
          }}
        />
      </InputWrapper>
    </FormContainer>
  );
};

const CampaignDuration = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 576px) {
    flex-direction: row;
    gap: 40px;
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 4px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 40px;
  }
`;
const InputWrapper = styled.div`
  flex-grow: 1;
`;
