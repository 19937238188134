import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

export const getJobDashboard = (id: number) => {
  const config = {
    method: "post",
    url: API_PATHS.jobDashboard,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ company_id: id }),
  };
  return instance(config);
};
