import { theme } from "src/components/Theme";
import styled from "styled-components";

const CheckoutContainer = styled.div<{ isDarkTheme: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  .checkout {
    display: flex;
    flex-direction: column;
    background-color: ${({ isDarkTheme }) =>
      isDarkTheme ? theme.colors.black110 : theme.colors.white_pure};
    padding: 24px 16px;
  }

  @media (min-width: 576px) {
    .checkout {
      padding: 24px 48px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    .checkout {
      padding: 52px 24px;
    }
    .checkout-left {
      background-color: ${({ isDarkTheme }) =>
        isDarkTheme ? "#34394E" : theme.colors.light_default};
    }
  }
  @media (min-width: 992px) {
    .checkout {
      padding: 52px 64px;
    }
    .checkout-left {
      padding-left: 108px;
    }
  }

  .btn-primary {
    width: fit-content;
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BillSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;

  @media (min-width: 992px) {
    padding-left: 28px;
    align-items: start;
    gap: 0;
  }
`;
const Bill = styled.div`
  padding-top: 39px;
  @media (min-width: 576px) {
    padding-top: 47px;
  }
  @media (min-width: 768px) {
    padding-left: 24px;
  }
  @media (min-width: 992px) {
    padding-top: 78px;
    padding-left: 65px;
  }
`;
const Underline = styled.div`
  margin: 10px 0 14px 0;
  height: 1px;
  background-color: ${theme.colors.black40};
`;
const PromotionButtonWrapper = styled.div`
  padding-bottom: 14px;
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 44px;
  justify-content: center;
  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`;
const StripeLogoWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  @media (min-width: 576px) {
    justify-content: flex-end;
  }
`;
const ModalContainer = styled.div`
  background: ${theme.colors.overlay};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 63px 0 0 0;
`;
const PaymentDetailsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    padding-bottom: 40px;
  }
`;

export const CheckoutStyles = {
  CheckoutContainer,
  TopContainer,
  BackContainer,
  BillSummary,
  Bill,
  Underline,
  PromotionButtonWrapper,
  Fields,
  ButtonWrapper,
  StripeLogoWrapper,
  ModalContainer,
  PaymentDetailsTitle,
};
