import { FormFieldsProps } from "src/components/Basic/FormFields";
export const idPrefix = "message-template";
const message: FormFieldsProps.RichTextarea = {
  fieldName: "message",
  idPrefix,
  isRequired: true,
  placeholder: "Message",
};
const subject: FormFieldsProps.Input = {
  fieldName: "subject",
  idPrefix,
  isRequired: true,
  placeholder: "Subject",
};
const template_name: FormFieldsProps.Input = {
  fieldName: "template_name",
  idPrefix,
  isRequired: true,
  placeholder: "Template Name",
  label: "Template Name",
};

export const messageTemplateField = {
  message,
  subject,
  template_name,
};
