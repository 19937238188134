import { Buttons, Typography } from "src/components/Basic";
import {
  RecentPositionDetailContainer,
  SummaryContainer,
  SkillContainer,
  Tag,
  Container,
  DetailContainer,
  Details,
  Value,
  Heading,
  RecentPositionDetail,
  ButtonContainer,
  MainContainer,
  Skills,
  Wrapper,
  // HeadingContainer,
  // IconContainer,
} from "./SummaryStyle";
import { IPipeLine } from "../../PipeLine";
import { useAppSelector } from "src/Redux";

const Summary: React.FC<IPipeLine.ISummary> = ({ setActiveTab }) => {
  const {
    recent_positions,
    key_skills,
    work_experience,
    languages,
    notice_period,
    desired_remuneration,
    current_remuneration,
    citizenship,
    own_transport,
    willing_to_relocate,
    bio,
    name,
  } = useAppSelector((state) => state.candidate.selectedCandidate);

  return (
    <SummaryContainer>
      <Typography
        content="SUMMARY"
        styleName="candidateNameEmailOpen"
        color="primary_default"
      />
      {/* {key_skills.length > 0 ? ( */}
      <MainContainer>
        <div className="">
          <RecentPositionDetailContainer>
            <RecentPositionDetail>
              <Heading>Most recent position</Heading>
              <div className="d-flex flex-column">
                <Value>{recent_positions.job_title}</Value>
                <Value className="summary-recent-position">
                  {recent_positions.company}
                </Value>
                <Value className="summary-recent-position">
                  {recent_positions.to_date}
                </Value>
              </div>
              <Typography
                content={`+ ${recent_positions.previous_positions}`}
                styleName="summaryTextDesc"
                color="black70"
              />
            </RecentPositionDetail>
            <ButtonContainer>
              <Buttons
                label="View More"
                variant="primary"
                handleClick={() => {
                  setActiveTab("Experience");
                }}
              />
            </ButtonContainer>
          </RecentPositionDetailContainer>
          <div style={{ marginTop: "40px" }}>
            <SkillContainer>
              <Heading>Key Skills</Heading>
              <Skills>
                {key_skills.map((skill, index) => (
                  <Tag key={index}>{skill}</Tag>
                ))}
              </Skills>
            </SkillContainer>
          </div>
          <div
            className="d-flex flex-column gap-2 work-experience"
            style={{ marginTop: "46px" }}
          >
            <Heading>Total work experience</Heading>
            <Value>{work_experience}</Value>
          </div>
          <div style={{ marginTop: "40px" }}>
            <SkillContainer>
              <Heading>Languages</Heading>
              <Skills>
                {languages.map((skill, index) => (
                  <Tag key={index}>{skill}</Tag>
                ))}
              </Skills>
            </SkillContainer>
          </div>
          <div>
            <div>
              <Wrapper>
                <Container>
                  <Heading>Notice Period</Heading>
                  <Value>{notice_period}</Value>
                </Container>
                <Container>
                  <Heading>Citizenship</Heading>
                  <Value>{citizenship}</Value>
                </Container>
              </Wrapper>
              <Wrapper>
                <Container>
                  <Heading>Own Transport</Heading>
                  <Value>{own_transport ? "Yes" : "No"}</Value>
                </Container>
                <Container>
                  <Heading>Willing to Relocate</Heading>
                  <Value>{willing_to_relocate ? "Yes" : "No"}</Value>
                </Container>
              </Wrapper>
              <Wrapper>
                <Container>
                  <Heading>Current Remuneration</Heading>
                  <Value>{current_remuneration}</Value>
                </Container>
                <Container>
                  <Heading>Desired Remuneration</Heading>
                  <Value>{desired_remuneration}</Value>
                </Container>
              </Wrapper>
            </div>
          </div>
        </div>
        <DetailContainer>
          <Typography
            content={`About ${name}`}
            styleName="candidateText"
            color="black80"
          />
          <Details dangerouslySetInnerHTML={{ __html: bio }} />
        </DetailContainer>
      </MainContainer>
      {/* ) : (
        <>
          <HeadingContainer className="no-data-msg">
            <Typography
              content="No Summary available."
              styleName="text1"
              color="black80"
            />
          </HeadingContainer>
          <IconContainer>
            <SummaryIcon />
          </IconContainer>
        </>
      )} */}
    </SummaryContainer>
  );
};

export default Summary;
