import React, { DragEvent } from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "../../Basic/Typography";
import {
  Container,
  CountContainer,
  IconContainer,
  InBoxContainer,
  PipelinePhaseContainer,
  PhaseContainerList,
  Wrapper,
} from "./PipeLinePhaseStyle";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { pipelinePhaseData } from "../Utils";
import { pipelinePhaseActions } from "src/Redux/slices/PipelinePhase";
import { Key } from "../PipeLine";
import { API_INSTANCES } from "src/API_INSTANCES";
import { useParams } from "react-router-dom";

const PipeLinePhase = ({
  setPhase,
}: {
  setPhase: React.Dispatch<React.SetStateAction<Key>>;
}) => {
  const { pipelinePhase, candidate } = useAppSelector((state) => state);
  const { date_created, job_title, pipeline_overview_count } = pipelinePhase;
  const dispatch = useAppDispatch();
  const {job_id} = useParams();

  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>, value: number) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    target.style.transform = "scale(1.08)";
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>, value: number) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (!target.contains(relatedTarget)) {
      target.style.transform = "scale(1)";
    }
  };

  const drop = (e: DragEvent<HTMLDivElement>, value: number, key: Key) => {
    e.preventDefault();
    const target = document.getElementsByClassName(
      `candidate-drag_${value}`
    )[0] as HTMLElement;
    target.style.transform = "scale(1)";
    var data = e.dataTransfer.getData("text");
    if (data.includes("_true")) {
      const body = {
        candidate_id: candidate.selectedCandidate.candidate_id,
        status_id: String(key),
      };
      API_INSTANCES.PIPELINE.updatePipelineOverview(body, job_id as string)
        .then((res) => {
          dispatch(actions.pipelinePhase.incrementPhase(res.data));
          dispatch(actions.candidate.setPhase(key));
        })
        .catch((err) => console.log(err));
    }
  };
  React.useEffect(() => {
    API_INSTANCES.PIPELINE.getPipelineOverview(job_id as string)
      .then((res) => dispatch(pipelinePhaseActions.incrementPhase(res.data)))
      .catch((err) => console.log(err));
  }, []);

  return (
    <PipelinePhaseContainer>
      <Container>
        <Wrapper>
          <Typography
            content={job_title}
            styleName="subtitle1"
            color="black90"
          />
          <Typography
            content={`Posted:${date_created}`}
            styleName="text_10pt"
            color="black50"
          />
        </Wrapper>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            aria-expanded="false"
            aria-haspopup="true"
            className="btn phase-dropdown "
            data-bs-toggle="dropdown"
            type="button"
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="xl"
              className="user-icon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: "0px" }}>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item>Advertising</Dropdown.Item>
            <Dropdown.Item>Activity Log</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
      <PhaseContainerList>
        {pipelinePhaseData.map((phase, index) => (
          <div className="d-flex align-items-center " key={index}>
            <InBoxContainer
              isOnBoarding={phase.isOnBoarding}
              className={clsx(
                phase.isOnBoarding ? "inbox-onboarding" : "inbox-regular",
                `candidate-drag_${index}`
              )}
              onDragOver={dragOver}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragLeave={(e) => dragLeave(e, index)}
              onDrop={(e) => drop(e, index, phase.key)}
              onClick={() => setPhase(phase.key)}
            >
              <Typography
                content={phase.label}
                styleName="inboxText"
                color="primary_default"
              />
              <CountContainer
                id={`candidate-drag_${index}`}
                isOnBoarding={phase.isOnBoarding}
              >
                {pipeline_overview_count[phase.key]}
              </CountContainer>
            </InBoxContainer>
            {phase.icon && <IconContainer>{phase.icon}</IconContainer>}
          </div>
        ))}
      </PhaseContainerList>
    </PipelinePhaseContainer>
  );
};

export default PipeLinePhase;
