export const HidePasswordIcon = () => (
  <svg
    width="25"
    height="17"
    viewBox="0 0 25 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="25" y1="0" x2="-8" y2="20" stroke="#A9B5D2" strokeWidth="3" />
    <path
      d="M24.8489 7.62266C22.4952 3.07613 17.8355 0 12.5 0C7.16447 0 2.50345 3.07828 0.151018 7.62309C0.0517306 7.81752 0 8.03234 0 8.25022C0 8.46809 0.0517306 8.68291 0.151018 8.87734C2.50475 13.4239 7.16447 16.5 12.5 16.5C17.8355 16.5 22.4965 13.4217 24.8489 8.87691C24.9482 8.68248 24.9999 8.46766 24.9999 8.24978C24.9999 8.03191 24.9482 7.81709 24.8489 7.62266ZM12.5 14.4375C11.2638 14.4375 10.0555 14.0746 9.02766 13.3947C7.99985 12.7148 7.19878 11.7485 6.72573 10.6179C6.25268 9.48724 6.12891 8.24314 6.37007 7.04288C6.61123 5.84262 7.20648 4.74011 8.08056 3.87478C8.95464 3.00944 10.0683 2.42014 11.2807 2.18139C12.493 1.94265 13.7497 2.06518 14.8917 2.5335C16.0338 3.00181 17.0099 3.79488 17.6967 4.81241C18.3834 5.82994 18.75 7.02623 18.75 8.25C18.7504 9.06266 18.589 9.86744 18.275 10.6183C17.9611 11.3692 17.5007 12.0515 16.9203 12.6261C16.3398 13.2007 15.6507 13.6565 14.8922 13.9673C14.1338 14.2781 13.3208 14.4379 12.5 14.4375ZM12.5 4.125C12.1281 4.13015 11.7586 4.18492 11.4015 4.28785C11.6958 4.68388 11.8371 5.17124 11.7996 5.66154C11.7621 6.15184 11.5484 6.61261 11.1972 6.96029C10.846 7.30798 10.3806 7.51955 9.88537 7.55664C9.39012 7.59373 8.89784 7.45388 8.4978 7.16246C8.27001 7.9933 8.31113 8.87394 8.61538 9.68043C8.91962 10.4869 9.47167 11.1787 10.1938 11.6583C10.916 12.1379 11.7719 12.3812 12.641 12.354C13.5102 12.3268 14.3489 12.0305 15.039 11.5067C15.7291 10.983 16.236 10.2581 16.4882 9.43421C16.7404 8.61032 16.7253 7.72887 16.445 6.91392C16.1647 6.09897 15.6333 5.39156 14.9256 4.89127C14.2179 4.39097 13.3696 4.12297 12.5 4.125Z"
      fill="#A9B5D2"
    />
  </svg>
);
