import { css } from "styled-components";
import { theme } from "../../../../components/Theme";

//#region Colors
const PLACEHOLDER_COLOR = theme.colors.black50;
const PLACEHOLDER_COLOR_DIM = "#CFD4DD";
const FILLLED_TEXT_COLOR = theme.colors.black80;
const FIELD_BORDER_COLOR = theme.colors.black10;
const FIELD_BG_COLOR = theme.colors.white_pure;
const ERROR_FIELD_BORDER_COLOR = theme.colors.secondary_hover;
const SELECT_OPTIONS_HOVER_BGCOLOR = theme.colors.grey_input_dropdown_hover;
const MULTISELECT_OPTIONS_HOVER_BGCOLOR =
  theme.colors.grey_input_dropdown_hover_picked;
const SELECT_OPTIONS_HOVER_COLOR = theme.colors.blue_grey_80;
const SPINNER_NORMAL = theme.colors.primary_default;
const SPINNER_ERROR = theme.colors.danger_default;
const SPINNER_WARNING = theme.colors.warning_default;
const SPINNER_SUCCESS = theme.colors.success_default;

export const FIELD_COLORS = {
  PLACEHOLDER_COLOR,
  PLACEHOLDER_COLOR_DIM,
  FILLLED_TEXT_COLOR,
  FIELD_BORDER_COLOR,
  FIELD_BG_COLOR,
  ERROR_FIELD_BORDER_COLOR,
  SELECT_OPTIONS_HOVER_BGCOLOR,
  MULTISELECT_OPTIONS_HOVER_BGCOLOR,
  SELECT_OPTIONS_HOVER_COLOR,
  SPINNER_NORMAL,
  SPINNER_ERROR,
  SPINNER_WARNING,
  SPINNER_SUCCESS,
};
//#endregion

//#region Box shadow
const DEFAULT_BOX_SHADOW = theme.Effects.hover_active;
const ERROR_BOX_SHADOW = theme.Effects.error;
export const FIELD_BOX_SHADOW = { DEFAULT_BOX_SHADOW, ERROR_BOX_SHADOW };
//#endregion

//#region Field Related Css
const TEXT = css`
  ${theme.text.inputGeneral};
  color: ${FIELD_COLORS.FILLLED_TEXT_COLOR};
`;
const PLACEHOLDER = css`
  ${({ theme }) => theme.text.inputGeneral};
  color: ${FIELD_COLORS.PLACEHOLDER_COLOR};
`;
const DROPDOWN_OPTIONS = css`
  ${theme.text.text_12pt};
  color: ${SELECT_OPTIONS_HOVER_COLOR};
`;
const DROPDOWN_OPTIONS_HOVER = css`
  ${theme.text.text_13pt};
  color: ${theme.colors.blue_grey_80};
`;

const INPUT_HOVER = css`
  border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
  box-shadow: ${({ theme }) => theme.Effects.hover_active},
    0 0 0px 1000px white inset;
  -webkit-box-shadow: ${({ theme }) => theme.Effects.hover_active},
    0 0 0px 1000px white inset;
`;
const INPUT_ERROR_HOVER = css<{ isDarkTheme: boolean }>`
  border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  box-shadow: 0 0 0px 1000px
      ${({ isDarkTheme }) => (isDarkTheme ? "#2a2e3f" : "white")} inset,
    ${({ theme }) => theme.Effects.error} !important;
  -webkit-box-shadow: 0 0 0px 1000px
      ${({ isDarkTheme }) => (isDarkTheme ? "#2a2e3f" : "white")} inset,
    ${({ theme }) => theme.Effects.error} !important;
`;
const HOVER = css`
  border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
  box-shadow: ${({ theme }) => theme.Effects.hover_active};
`;
const ERROR_HOVER = css`
  border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  box-shadow: ${({ theme }) => theme.Effects.error} !important;
`;
const CHECKBOX_RADIO_SWITCH_COMMON = css<{ showOptionsInRow: boolean }>`
  display: flex;
  flex-direction: ${({ showOptionsInRow }) =>
    showOptionsInRow ? "row" : "column"};
  gap: ${({ showOptionsInRow }) => (showOptionsInRow ? "15px" : "10px")};
  .form-control:disabled {
    background: transparent;
    border: 0px !important;
    cursor: not-allowed;
  }
`;
const CHECKBOX_RADIO_SWITCH_LABEL = css`
  ${({ theme }) => theme.text.inputGeneral};
  color: ${({ theme }) => theme.colors.blue_grey_80};
`;
const CHECKBOX_COMPONENT_LABEL = css`
  .component-label {
    color: ${({ theme }) => theme.colors.black80};
    ${({ theme }) => theme.text.inputLabel};
  }
`;
export const FIELD_CSS = {
  TEXT,
  PLACEHOLDER,
  DROPDOWN_OPTIONS,
  DROPDOWN_OPTIONS_HOVER,
  INPUT_HOVER,
  INPUT_ERROR_HOVER,
  HOVER,
  ERROR_HOVER,
  CHECKBOX_RADIO_SWITCH_COMMON,
  CHECKBOX_RADIO_SWITCH_LABEL,
  CHECKBOX_COMPONENT_LABEL,
};
//#endregion

//#region Fields
const INPUT_FORM_CONTROL = css`
  .form-control {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    ${FIELD_CSS.TEXT};
  }
  .form-control:hover {
    ${FIELD_CSS.INPUT_HOVER};
  }
  .form-control:active {
    ${FIELD_CSS.INPUT_HOVER};
  }
  .form-control:focus {
    ${FIELD_CSS.INPUT_HOVER};
  }
  input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }
  input:focus::placeholder {
    color: transparent !important;
  }
`;
const ERROR_INPUT_FORM_CONTROL = css`
  ${INPUT_FORM_CONTROL};
  .form-control {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }
  .form-control:hover {
    ${FIELD_CSS.INPUT_ERROR_HOVER};
  }
  .form-control:active {
    ${FIELD_CSS.INPUT_ERROR_HOVER};
  }
  .form-control:focus {
    ${FIELD_CSS.INPUT_ERROR_HOVER};
  }
`;
const FORM_CONTROL = css`
  .form-control {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    ${FIELD_CSS.TEXT};
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  .form-control:hover {
    ${FIELD_CSS.HOVER};
  }
  .form-control:active {
    ${FIELD_CSS.HOVER};
  }
  .form-control:focus {
    ${FIELD_CSS.HOVER};
  }
  input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }
  input:focus::placeholder {
    color: transparent !important;
  }
`;
const ERROR_FORM_CONTROL = css`
  ${FORM_CONTROL};
  .form-control {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }
  .form-control:hover {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .form-control:active {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .form-control:focus {
    ${FIELD_CSS.ERROR_HOVER};
  }
`;
const DISABLED_FORM_CONTROL = css`
  ${FORM_CONTROL};
  .form-control {
    cursor: not-allowed;
  }
`;

const INPUT_GROUP = css`
  input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }
  .input-group:hover {
    box-shadow: ${FIELD_BOX_SHADOW.DEFAULT_BOX_SHADOW} !important;
    border-radius: 5px;
  }
  .input-group:active {
    box-shadow: ${FIELD_BOX_SHADOW.DEFAULT_BOX_SHADOW} !important;
    border-radius: 5px;
  }
  .input-group:focus {
    box-shadow: ${FIELD_BOX_SHADOW.DEFAULT_BOX_SHADOW} !important;
    border-radius: 5px;
  }
`;
const ERROR_INPUT_GROUP = css`
  ${INPUT_GROUP};

  .input-group:hover {
    box-shadow: ${FIELD_BOX_SHADOW.ERROR_BOX_SHADOW} !important;
  }
  .input-group:active {
    box-shadow: ${FIELD_BOX_SHADOW.ERROR_BOX_SHADOW} !important;
  }
  .input-group:focus {
    box-shadow: ${FIELD_BOX_SHADOW.ERROR_BOX_SHADOW} !important;
  }
`;

const FORMSELECT_CONTROL = css`
  .formselect__control {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    ${FIELD_CSS.TEXT}
  }
  .formselect__control:hover {
    ${FIELD_CSS.HOVER};
  }
  .formselect__control:active {
    ${FIELD_CSS.HOVER};
  }
  .formselect__control:focus {
    ${FIELD_CSS.HOVER};
  }
  .formselect__control--is-focused {
    ${FIELD_CSS.HOVER};
  }
  .formselect__control--menu-is-open {
    ${FIELD_CSS.HOVER};
  }

  .formselect__single-value {
    ${FIELD_CSS.TEXT};
  }
  .formselect__input-container input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }

  .formselect__option {
    ${FIELD_CSS.DROPDOWN_OPTIONS}
  }
  .formselect__option:hover {
    ${FIELD_CSS.DROPDOWN_OPTIONS_HOVER}
    background: ${FIELD_COLORS.SELECT_OPTIONS_HOVER_BGCOLOR};
  }
`;
const ERROR_FORMSELECT_CONTROL = css`
  ${FORMSELECT_CONTROL};
  .formselect__control {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }

  .formselect__control:hover {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .formselect__control:active {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .formselect__control:focus {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .formselect__control--is-focused {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .formselect__control--menu-is-open {
    ${FIELD_CSS.ERROR_HOVER};
  }
`;

const CITYSELECT_CONTROL = css`
  .cityselect__control {
    border: 1px solid ${FIELD_COLORS.FIELD_BORDER_COLOR};
    ${FIELD_CSS.TEXT}
  }
  .cityselect__control:hover {
    ${FIELD_CSS.HOVER};
  }
  .cityselect__control:active {
    ${FIELD_CSS.HOVER};
  }
  .cityselect__control:focus {
    ${FIELD_CSS.HOVER};
  }
  .cityselect__control--is-focused {
    ${FIELD_CSS.HOVER};
  }
  .cityselect__control--menu-is-open {
    ${FIELD_CSS.HOVER};
  }

  .cityselect__single-value {
    ${FIELD_CSS.TEXT};
  }
  .cityselect__input-container input::placeholder {
    ${FIELD_CSS.PLACEHOLDER};
  }

  .cityselect__option {
    ${FIELD_CSS.DROPDOWN_OPTIONS}
  }
  .cityselect__option:hover {
    ${FIELD_CSS.DROPDOWN_OPTIONS_HOVER}
    background: ${FIELD_COLORS.SELECT_OPTIONS_HOVER_BGCOLOR};
  }
`;
const ERROR_CITYSELECT_CONTROL = css`
  ${CITYSELECT_CONTROL};
  .cityselect__control {
    border: 1px solid ${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important;
  }

  .cityselect__control:hover {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .cityselect__control:active {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .cityselect__control:focus {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .cityselect__control--is-focused {
    ${FIELD_CSS.ERROR_HOVER};
  }
  .cityselect__control--menu-is-open {
    ${FIELD_CSS.ERROR_HOVER};
  }
`;

export const FIELDS = {
  INPUT_FORM_CONTROL,
  ERROR_INPUT_FORM_CONTROL,
  FORM_CONTROL,
  ERROR_FORM_CONTROL,
  DISABLED_FORM_CONTROL,

  INPUT_GROUP,
  ERROR_INPUT_GROUP,

  FORMSELECT_CONTROL,
  ERROR_FORMSELECT_CONTROL,

  CITYSELECT_CONTROL,
  ERROR_CITYSELECT_CONTROL,
};
//#endregion
