import { Button } from "react-bootstrap";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const ReceiptsContainer = styled.div`
  width: 100%;
  padding: 20px 20px 29px;
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  @media (max-width: 991px) {
    padding: 20px 6px 29px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    div label {
      &:first-child {
        color: ${theme.colors.grey_subtitle1};
      }
      &:last-child {
        color: ${theme.colors.grey_subtitle2};
      }
    }
  }
`;
export const Table = styled.table`
  .table-header {
    background: ${theme.colors.white30};
    th {
      padding: 10px;
      ${theme.text.authInputErrorMsg};
      white-space: nowrap;
      color: ${theme.colors.black100};
    }
  }
  .table-body tr {
    &:nth-child(even) {
      background: ${theme.colors.white30};
    }
    &:nth-child(odd) {
      background: ${theme.colors.white20};
    }
    & td:first-child {
      @media (max-width: 991px) {
        width: 150px;
      }
    }
    & td:last-child {
      @media (max-width: 991px) {
        width: 35px;
      }
    }
  }
  .dark-theme & {
    border: none;
    .table-header {
      background: ${theme.colors.black120};
      th {
        color: ${theme.colors.light_default} !important;
        svg path {
          fill: ${theme.colors.light_default};
        }
      }
    }
    .table-body tr td {
      color: ${theme.colors.white10} !important;
    }
    .table-body tr td {
      border-top: none;
      @media (max-width: 991px) {
        border-bottom: 1px solid #484863;
      }
    }
    & > :not(:first-child) {
      @media (max-width: 991px) {
        border-top: none !important;
      }
    }
  }
`;

export const PaymentStatus = styled.div<{ isPaid: boolean }>`
  padding: 4px;
  width: fit-content;
  border-radius: 3px;
  white-space: nowrap;
  color: ${({ isPaid }) =>
    isPaid ? theme.colors.success500 : theme.colors.danger_default};
  padding: 4px;
  background: ${({ isPaid }) =>
    isPaid ? "rgba(56, 202, 179, 0.2)" : "rgba(243, 67, 67, 0.15)"};
  ${theme.text.paymentStatus};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  label:first-child {
    text-transform: capitalize;
  }
  .receipt-amount {
    color: ${theme.colors.blue_grey};
    width: 55px;
  }
  .dark-theme & .receipt-amount {
    color: ${theme.colors.success_default};
  }
  .dark-theme & label {
    &:first-child {
      color: ${theme.colors.white10} !important;
    }
    &:last-child {
      color: ${theme.colors.white40} !important;
    }
  }
`;

export const PaginationButton = styled(Button)`
  color: ${theme.colors.black_pure};
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:disabled {
    color: ${theme.colors.black50};
    border: none;
    cursor: not-allowed;
    pointer-events: visible;
  }
  &:active {
    border: none;
  }
`;
