import { Dispatch, SetStateAction } from "react";
import { API_CALLS } from "src/API_CALLS";
import {
 ICONS
} from "src/assets/custom_Icons";
import { FormFieldsProps } from "src/components/Basic/FormFields";

export const uploadPhoto = (setAction: Dispatch<SetStateAction<boolean>>) => {
  setAction(true)
  const input = document.createElement("input");
  input.type = "file";
  input.accept = ".jpg, .jpeg, .png";
  input.click();
  input.addEventListener("change", (event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    API_CALLS.COMPANY_SETTINGS.uploadCompanyGallery(file as File,setAction);
  });
};

export const idPrefix = "company-settings";
const display_name: FormFieldsProps.Input = {
  fieldName: "display_name",
  idPrefix,
  isRequired: true,
  label: "Company Display Name",
  placeholder: "Company Display Name (limit to 50 characters)",
};
const company_size: FormFieldsProps.Dropdown = {
  fieldName: "size",
  idPrefix,
  label: "Company Size",
  placeholder: "Select...",
  isRequired: false,
  options: [
    { label: "1 - 10", value: "1 - 10" },
    { label: "11 - 50", value: "11 - 50" },
    { label: "51 - 200", value: "51 - 200" },
    { label: "201 - 500", value: "201 - 500" },
    { label: "501 - 1000", value: "501 - 1000" },
    { label: "1001 - 5000", value: "1001 - 5000" },
    { label: "5001+", value: "5001+" },
  ],
};
const company_bio: FormFieldsProps.Textarea = {
  fieldName: "company_bio",
  idPrefix,
  label: "Company Bio",
  isRequired: false,
  placeholder: "Type here (limit to 100 characters)",
  rows: 5,
  showLabel: true,
};

const company_description: FormFieldsProps.RichTextarea = {
  fieldName: "company_description",
  idPrefix,
  isRequired: false,
  label: "Company Description",
  placeholder: "Type here (limit to 2000 characters)",
};
const website: FormFieldsProps.Input = {
  fieldName: "company_website",
  idPrefix,
  isRequired: true,
  label: "Website",
  inputType: "URL",
};
const linkdin: FormFieldsProps.InputWithIcon = {
  fieldName: "social_linkedin",
  idPrefix,
  isRequired: false,
  placeholder: "Enter URL",
  icon: <ICONS.LinkedinIcon />,
  label: "Social Accounts",
  inputType: "URL",
};
const youtube: FormFieldsProps.InputWithIcon = {
  fieldName: "social_youtube",
  idPrefix,
  isRequired: false,
  placeholder: "Enter URL",
  icon: <ICONS.YoutubeIcon />,
  inputType: "URL",
};
const twitter: FormFieldsProps.InputWithIcon = {
  fieldName: "social_twitter",
  idPrefix,
  isRequired: false,
  placeholder: "Enter URL",
  icon: <ICONS.TwitterIcon />,
  inputType: "URL",
};
const instagram: FormFieldsProps.InputWithIcon = {
  fieldName: "social_instagram",
  idPrefix,
  isRequired: false,
  placeholder: "Enter URL",
  icon: <ICONS.InstagramIcon />,
  inputType: "URL",
};
const facebook: FormFieldsProps.InputWithIcon = {
  fieldName: "social_facebook",
  idPrefix,
  isRequired: false,
  placeholder: "Enter URL",
  icon: <ICONS.FacebookIcon />,
  inputType: "URL",
};
const country: FormFieldsProps.CountrySelector = {
  fieldName: "country",
  idPrefix,
  isRequired: true,
  label: "Country",
  citySearchName: "city",
};
const city: FormFieldsProps.CitySearch = {
  fieldName: "city",
  countrySelectorName: "country",
  idPrefix,
  isRequired: true,
  label: "City",
};
export const CompanyProfileSettingsFields = {
  display_name,
  company_size,
  company_bio,
  company_description,
  country,
  city,
  website,
  linkdin,
  youtube,
  facebook,
  twitter,
  instagram,
};
