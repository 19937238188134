import { API_INSTANCES } from "src/API_INSTANCES";
import store, { StripeProps } from "src/Redux";
import { stripeActions } from "src/Redux/slices/Stripe";

const dispatch = store.dispatch;

const createPaymentIntent = async (values: StripeProps.APIValues) => {
  const response = await API_INSTANCES.STRIPE.createPaymentIntent(values);
  console.log("createPaymentIntent RESPONSE", response);
  return response.data;
};

const getStripeCard = () => {
  API_INSTANCES.STRIPE.getStripeCard()
    .then((res) => dispatch(stripeActions.setCardList(res.data)))
    .catch((error) => console.log(error));
};

const deleteStripeCard = (id: string) => {
  API_INSTANCES.STRIPE.deleteStripeCard(id)
    .then((res) => {
      // dispatch(stripeActions.setCardList(res.data));
      console.log(res.data);
    })
    .catch((error) => console.log(error));
};

export const STRIPE = { createPaymentIntent, getStripeCard, deleteStripeCard };
