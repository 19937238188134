import { useContext } from "react";

import { Typography } from "src/components/Basic";
import { CreateJobAdvertiseCommon } from "./Common";
import { AdvertiseStyles } from "./Styles";
import { AdvertiseUtils } from "./Utils";
import { ICONS } from "src/assets/custom_Icons";
import { AppContext } from "src/App";
import { AdvertiseContext } from ".";

export const StandardCard = () => {
  const { isMobile } = useContext(AppContext);
  const { handleCardClick, isStandardOpen, fillColor } =
    useContext(AdvertiseContext);
  const { currency } = useContext(AdvertiseContext);

  const { AdvertiseTitle, AdvertisePrice, Point, SelectButton } =
    CreateJobAdvertiseCommon;
  const { standard } = AdvertiseUtils.points;
  const standardPoints = standard.points.map((point, index) => (
    <Point key={`${point.advertType}_${index}`} {...point} />
  ));

  return (
    <AdvertiseStyles.Card
      className="advertise-card"
      onClick={() => handleCardClick("standard")}
      advertType="standard"
      isOpen={isStandardOpen}
    >
      <AdvertiseStyles.TitleBar>
        <AdvertiseStyles.TitleWrapper>
          <AdvertiseTitle title={standard.title} />
          <AdvertisePrice
            price={`${currency} ${standard.price.price}`}
            isStriked={standard.price.isStriked}
          />
          <AdvertiseStyles.AdvertsLeft>
            <Typography
              content="3 Free Adverts Left"
              styleName="jobPill"
              color="white_pure"
            />
          </AdvertiseStyles.AdvertsLeft>
        </AdvertiseStyles.TitleWrapper>
        {isMobile && (
          <>
            {isStandardOpen ? (
              <ICONS.TriangleArrowUp {...{ fillColor }} />
            ) : (
              <ICONS.TriangleArrowDown {...{ fillColor }} />
            )}
          </>
        )}
      </AdvertiseStyles.TitleBar>

      {(!isMobile || isStandardOpen) && (
        <>
          <AdvertiseStyles.PointsWrapper>
            {standardPoints}
          </AdvertiseStyles.PointsWrapper>
          <SelectButton advertType="standard" />
        </>
      )}
    </AdvertiseStyles.Card>
  );
};
