import { theme } from "src/components/Theme";
import styled from "styled-components";

export const EmailNotificationContainer = styled.div`
  border-radius: 5px;
  background: ${theme.colors.white_pure};
  width: 100%;
  padding: 20px 89px 33px 20px;
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  @media (max-width: 991px) {
    padding: 20px 12px 115px;
    box-shadow: none;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 991px) {
    margin-left: 4px;
    gap: 15px;
  }
  .dark-theme & label {
    &:first-child {
      color: ${theme.colors.grey_subtitle1};
    }
    &:last-child {
      color: ${theme.colors.grey_subtitle2};
    }
  }
`;

export const NotificationDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 43px 0px 40px 56px;
  gap: 36px;
  @media (max-width: 991px) {
    margin: 38px 0 24px;
    gap: 40px;
  }
`;

export const NotificationDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .notification-detail {
    max-width: 586px;
  }
  & .form-group {
    margin: 0;
    & label {
      margin: 0;
    }
    & input {
      width: auto;
    }
  }
  .dark-theme & {
    .notification-detail label {
      color: ${theme.colors.black50};
    }
    & > div:first-child label {
      color: ${theme.colors.dark_input_label};
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-left: 56px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
`;
