import instance from "src/axios/axios-user";
import { IEmailNotification } from "src/components/Settings/EmailNotification";
import { API_PATHS } from "src/utils/API_PATHS";

const getEmailNotificationSettings = () =>
  instance.get(API_PATHS.emailNotificationSettings);

const updateEmailNotificationSettings = (body: IEmailNotification) =>
  instance.put(API_PATHS.emailNotificationSettings, body);

export const EMAIL_NOTIFICATION = {
  getEmailNotificationSettings,
  updateEmailNotificationSettings,
};
