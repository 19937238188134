import { useState } from "react";
import { Formik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";

import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { Typography } from "src/components/Basic";
import Dropdown from "../Components/Dropdown";
import Input from "../Components/input";
import { ButtonWrapper, ErrorLabel } from "../Components/style";
import { registerSecondValidationSchema } from "../Components/Utils";
import {
  Circle,
  Container,
  ContentWrapper,
  InputContainer,
  InputFieldWrapper,
  Wrapper,
} from "./style";
import { RegisterProps } from "src/Redux/slices/Register/Register";
import { API_CALLS } from "src/API_CALLS";
import { RegisterUtils } from "./utils";

const SecondStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { stepNumber, FormValues, errorMessageFromAPI, isAPICallInProgress } =
    useAppSelector((store) => store.register);
  const { company_name, company_website, size } = FormValues;
  const { formFields } = RegisterUtils;

  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <Formik
      initialValues={
        { company_name, company_website, size } as RegisterProps.Step2Values
      }
      onSubmit={(values) => {
        dispatch(actions.register.setStep2Values(values));
        dispatch(actions.register.setAPICallInProgress(true));
        dispatch(actions.OTP.setEmail(FormValues.email));
        API_CALLS.AUTH.register(navigate);
      }}
      validationSchema={registerSecondValidationSchema}
    >
      {(formik) => {
        const onChangeHandler = () => {
          errorMessageFromAPI &&
            dispatch(actions.login.setErrorMessageFromAPI(""));
          dispatch(actions.register.setAPICallInProgress(false));
        };

        return (
          <>
            <InputFieldWrapper>
              <InputContainer>
                <Input {...formFields.company_name} {...{ onChangeHandler }} />
                <Input
                  {...formFields.company_website}
                  {...{ onChangeHandler }}
                />
                <Dropdown {...formFields.size} {...{ onChangeHandler }} />
              </InputContainer>
              {errorMessageFromAPI && (
                <ErrorLabel>{errorMessageFromAPI}</ErrorLabel>
              )}

              <Container step={stepNumber}>
                <Circle
                  onClick={() => {
                    dispatch(actions.register.setStep2Values(formik.values));
                    dispatch(actions.register.setStepNumber(1));
                  }}
                />
                <Circle
                  onClick={() => dispatch(actions.register.setStepNumber(2))}
                />
              </Container>

              <ButtonWrapper
                className="w-100"
                disabled={
                  submitClicked && (isAPICallInProgress || !formik.isValid)
                }
                type="submit"
                onClick={() => {
                  formik.submitForm();
                  setSubmitClicked(true);
                }}
              >
                <Typography
                  content="Register"
                  styleName="tableButton"
                  color="white_pure"
                />
              </ButtonWrapper>
            </InputFieldWrapper>
            <Wrapper>
              <ContentWrapper>
                <Typography
                  content="By registering you accept our"
                  styleName="createAccount"
                  color="black60"
                />
                <Typography
                  content=" terms and conditions"
                  styleName="createAccount"
                  color="black60"
                />
              </ContentWrapper>

              <div className="d-flex gap-2">
                <Typography
                  content="Have an acount?"
                  styleName="createAccount"
                  color="black60"
                />{" "}
                <NavLink to={"/auth/sign-in"}>
                  <Typography
                    content="Sign In"
                    styleName="createAccount"
                    color="black200"
                  />
                </NavLink>
              </div>
            </Wrapper>
          </>
        );
      }}
    </Formik>
  );
};

export default SecondStep;
