import styled from "styled-components";

import { FIELDS, FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles/common";

export const CityContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isFieldActive: boolean;
}>`
  .city-search {
    ${({ disabled }) => disabled && FIELDS.DISABLED_FORM_CONTROL};
    ${({ isFieldActive, Error }) => isFieldActive && !Error && FIELD_CSS.HOVER};
    border: 1px solid
      ${({ Error }) => {
        return Error
          ? `${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important`
          : FIELD_COLORS.FIELD_BORDER_COLOR;
      }};
    border-radius: 5px;
  }
  .city-search:hover {
    ${({ Error }) => (Error ? FIELD_CSS.ERROR_HOVER : FIELD_CSS.HOVER)};
  }
  .city-search:active {
    ${FIELD_CSS.HOVER};
  }
  .city-search:focus {
    ${FIELD_CSS.HOVER};
  }
  .city-search:focus::placeholder {
    color: transparent;
  }
  .city-search .cityselect__input-container {
    ${FIELD_CSS.TEXT};
    color: ${FIELD_COLORS.FILLLED_TEXT_COLOR};
  }

  .cityselect__input-container::after {
    visibility: visible !important;
  }
  ${({ disabled }) => disabled && FIELDS.DISABLED_FORM_CONTROL};

  .cityselect__dropdown-indicator {
    display: none;
  }
  .cityselect__indicator-separator {
    display: none;
  }

  .cityselect__control {
    border: 0px solid !important;
  }
  .cityselect__control:hover {
    box-shadow: none;
  }
  .cityselect__control:active {
    box-shadow: none;
  }
  .cityselect__control:focus {
    box-shadow: none;
  }
  .cityselect__control--is-focused {
    box-shadow: none;
  }
  .cityselect__control--menu-is-open {
    box-shadow: none;
  }

  .flagIcon {
    border: 0;
    background-color: ${({ theme }) => theme.colors.white_pure};
    cursor: grab;
    font-size: 40px;
  }
  .city-search-loading {
    background-color: ${({ theme }) => theme.colors.white_pure};
    padding-right: 8px;
    display: flex;
    align-items: center;
  }
`;
