import Slider from "@mui/material/Slider";

import { Typography } from "src/components/Basic";
import { useAppSelector } from "src/Redux";
import { useContext } from "react";
import { AppContext } from "src/App";
import { BoostedCampaignStyles } from "../../Styles";
import { RecommendedBid } from "./RecommendedBid";
import { BoostedCampaignContext } from "../..";
import { BoostedCampaignUtils } from "../../Utils";

export const BidSlider = () => {
  const { common, job } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { isTotalCampaignManuallyUpdated } = job;

  const { isTabletOrBig } = useContext(AppContext);
  const {
    currency,
    setBidEditable,
    bidValue,
    setBidValue,
    formik,
    bidMax,
    bidMin,
    step,
  } = useContext(BoostedCampaignContext);

  const bidPropsColor = isDarkTheme ? "black40" : "black_pure";
  const { campaign_input, total_campaign_budget } =
    BoostedCampaignUtils.formFields;

  return (
    <div style={{ position: "relative" }}>
      <BoostedCampaignStyles.SliderWrapper>
        <Slider
          value={parseFloat(bidValue)}
          step={step}
          valueLabelDisplay="on"
          onChange={(_e, value) => {
            const bidValue = parseFloat(value.toString()).toFixed(2);
            setBidValue(bidValue);
            setBidEditable(false);
            formik.setFieldTouched(campaign_input.fieldName);
            formik.setFieldValue(campaign_input.fieldName, bidValue);

            if (!isTotalCampaignManuallyUpdated) {
              const newMinBudget = (parseFloat(`${value}`) * 10).toFixed(2);
              formik.setFieldValue(
                total_campaign_budget.fieldName,
                newMinBudget
              );
            }
          }}
          min={bidMin}
          max={bidMax}
        />
      </BoostedCampaignStyles.SliderWrapper>
      <BoostedCampaignStyles.BidMinMaxContainer>
        <Typography
          content={`${currency}${bidMin}`}
          styleName="jobAdvertiseBidPrice"
          color={bidPropsColor}
        />
        <Typography
          content={`${currency}${bidMax}`}
          styleName="jobAdvertiseBidPrice"
          color={bidPropsColor}
        />
      </BoostedCampaignStyles.BidMinMaxContainer>

      {isTabletOrBig && <RecommendedBid />}
    </div>
  );
};
