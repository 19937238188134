import styled from "styled-components";

import { theme } from "../../Theme";
import { TypographyProps } from "./TypographyProps";

export const Typography = ({
  styleName,
  content,
  color = "black100",
  className,
  strikeThrough = false,
}: TypographyProps.Typography) => {
  return (
    <TextElement
      className={`typography ${className}`}
      styleName={styleName}
      color={color}
      strikeThrough={strikeThrough}
    >
      {content}
    </TextElement>
  );
};

export const TypographySpan = ({
  styleName,
  content,
  color = "black100",
  className,
  strikeThrough = false,
}: TypographyProps.Typography) => {
  return (
    <TextElementSpan
      className={`typography ${className}`}
      {...{ styleName, color, strikeThrough }}
    >
      {content}
    </TextElementSpan>
  );
};

export const TypographyLink = ({
  styleName,
  content,
  color = "black100",
  className,
  strikeThrough = false,
  onClick = () => {},
  disabled = false,
  underLineNeeded = true,
  showUnderLineOnHover = underLineNeeded,
}: TypographyProps.TypographyLink) => {
  return (
    <TextLinkElement
      className={`typography ${className}`}
      {...{
        onClick: disabled ? () => {} : onClick,
        strikeThrough,
        color,
        styleName,
        disabled,
        underLineNeeded,
        showUnderLineOnHover,
      }}
    >
      {content}
    </TextLinkElement>
  );
};

const TextElement = styled.label<TypographyProps.ElementProps>`
  ${({ styleName }) => theme.text[styleName]};
  text-decoration: ${({ strikeThrough }) => strikeThrough && "line-through"};
  color: ${({ color }) => theme.colors[color]};
  margin-bottom: 0;
`;
const TextElementSpan = styled.span<TypographyProps.ElementProps>`
  ${({ styleName }) => theme.text[styleName]};
  text-decoration: ${({ strikeThrough }) => strikeThrough && "line-through"};
  color: ${({ color }) => theme.colors[color]};
  margin-bottom: 0;
`;
const TextLinkElement = styled.a<TypographyProps.ElementProps>`
  ${({ styleName }) => theme.text[styleName]};
  color: ${({ color }) => theme.colors[color]} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "40%" : "100%")};
  text-decoration: ${({ underLineNeeded }) =>
    underLineNeeded ? "underline" : "none"} !important;
  margin-bottom: 0;
  &:hover {
    text-decoration: ${({ showUnderLineOnHover }) =>
      showUnderLineOnHover ? "underline" : "none"} !important;
  }
`;
