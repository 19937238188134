const basepath = process.env.REACT_APP_NOVA_API;

const login = basepath + "/login";
const logout = basepath + "/logout";
const register = basepath + "/nova-register";

const checkUserVerifiedAfterLogin = basepath + "/nova-register/is-verified";
const otpVerificationAfterRegister = basepath + "/nova-register/verify";
const otpVerificationAfterLogin = basepath + "/nova-register/authed-verify";
const jobOverview = basepath + "/jobs/overview";

const company = basepath + "/company";
const pipelineApplicants = basepath + "/pipeline-applicants";
const pipelineOverview = basepath + "/pipeline";
const candidateOverview = basepath + "/candidate-overview";
const candidateExperience = basepath + "/candidate-experience";
const candidateFiles = basepath + "/candidate-files";
const candidateQualifiers = basepath + "/candidate-qualifiers";
const candidateComments = basepath + "/pipeline-comments";
const jobDashboard = basepath + "/job-dashboard";

const getJob = basepath + "/jobs";
const createJob = basepath + "/jobs";
const getChartData = basepath + "/jobs/cpc";

const getCurrency = basepath + "/currency";
const getSkills = basepath + "/skills/";
const getIndustries = basepath + "/industries/";
const getCities = basepath + "/cities";
const getCityById = basepath + "/city";

const companyProfileSettings = basepath + "/settings/company-profile";
const companyProfileLogo = basepath + "/settings/company-profile/images-logo";
const companyProfileCover = basepath + "/settings/company-profile/images-cover";
const companyProfileUploadGallery =
  basepath + "/settings/company-profile/gallery-upload";
const companyProfileGallery = basepath + "/settings/company-profile/gallery";

const companyTeamOverview = basepath + "/settings/team/overview";
const companyTeamMemberActivate = basepath + "/settings/team/member-activate";
const companyTeamMemberDeactivate =
  basepath + "/settings/team/member-deactivate";
const companyTeamMemberVerify = basepath + "/settings/team/verify";
const addMemberCompany = basepath + "/settings/team/member_company";
const addTeamMember = basepath + "/settings/team/add_member";
const updateTeamMember = basepath + "/settings/team/member-update";
const deleteInvitedMember = basepath + "/settings/team/delete_temp_member";
const updateInvitedMember = basepath + "/settings/team/edit_temp_member";

const myProfile = basepath + "/settings/my-profile";
const myProfilePicture = basepath + "/settings/my-profile/picture";
const myProfileUpdatePassword =
  basepath + "/settings/my-profile/update-password";

const messageTemplateTag = basepath + "/settings/tags";
const messageTemplate = basepath + "/settings/templates";
const stripeCreatePaymentIntent = basepath + "/billing/create-payment-intent";
const stripeCreateSetupIntent = basepath + "/billing/create-setup-intent";
const getStripeCard = basepath + "/billing/list-cards";
const deleteStripeCard = basepath + "/billing/delete-card";

const receipts = basepath + "/billing/receipts";
const downloadReceipts = basepath + "/billing/download-receipt";

const emailNotificationSettings = basepath + "/settings/email";

export const API_PATHS = {
  login,
  logout,
  register,
  otpVerificationAfterRegister,
  otpVerificationAfterLogin,
  checkUserVerifiedAfterLogin,
  jobOverview,
  company,
  pipelineApplicants,
  pipelineOverview,
  candidateOverview,
  candidateExperience,
  candidateFiles,
  candidateQualifiers,
  candidateComments,
  jobDashboard,
  getJob,
  createJob,
  getChartData,
  getCurrency,
  getSkills,
  getIndustries,
  getCities,
  getCityById,
  companyProfileSettings,
  companyProfileLogo,
  companyProfileCover,
  companyProfileGallery,
  companyProfileUploadGallery,
  companyTeamOverview,
  companyTeamMemberDeactivate,
  companyTeamMemberActivate,
  companyTeamMemberVerify,
  addMemberCompany,
  addTeamMember,
  updateTeamMember,
  deleteInvitedMember,
  updateInvitedMember,
  myProfile,
  myProfilePicture,
  myProfileUpdatePassword,
  messageTemplateTag,
  messageTemplate,
  receipts,
  downloadReceipts,
  emailNotificationSettings,

  stripeCreatePaymentIntent,
  stripeCreateSetupIntent,
  getStripeCard,
  deleteStripeCard,
};
