import { NavigateFunction } from "react-router-dom";

import store from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";
import { LoginProps, actions } from "src/Redux/slices";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import { ROUTES } from "src/utils/ROUTES";

const dispatch = store.dispatch;

const register = (navigate: NavigateFunction) => {
  const { FormValues } = store.getState().register;
  const setError = actions.register.setErrorMessageFromAPI;
  API_INSTANCES.AUTH.register(FormValues)
    .then((response) => {
      console.log("REGISTER RESPONSE", response.data);
      dispatch(actions.OTP.setEmail(FormValues.email));
      dispatch(actions.OTP.setOTPInitiatedFrom("register"));
      navigate(ROUTES.OTP);
    })
    .catch((error) => {
      const response = error.response;
      console.log("REGISTER ERROR", response);
      if (!response) dispatch(setError("Error while registration"));
      let errorMessage = response.data?.detail;
      const isUserExists =
        response.status === 409 || errorMessage === "User Email Exists!";
      if (isUserExists) {
        errorMessage =
          "Seems like you're already registered. Please log in or reset your password.";
      }
      dispatch(setError(errorMessage));
    })
    .finally(() => {
      dispatch(actions.register.setAPICallInProgress(false));
    });
};

const checkUserVerifiedAfterLogin = (
  navigate: NavigateFunction,
  redirectURL: string
) => {
  const { formValues } = store.getState().login;

  API_INSTANCES.AUTH.checkUserVerifiedAfterLogin()
    .then((response) => {
      const result = response.data;
      const isUserNotVerified =
        result.status_code === 203 || result.detail === "Not Verified";
      if (isUserNotVerified) {
        dispatch(actions.OTP.setEmail(formValues.username));
        dispatch(actions.OTP.setOTPInitiatedFrom("login"));
        navigate(ROUTES.OTP);
      } else navigate(redirectURL || ROUTES.HOME);
      console.log("USER VERIFIED RESPONSE", result);
    })
    .catch((error) => {
      console.log("USER VERIFIED ERROR", error);
    });
};

const login = (navigate: NavigateFunction, redirectURL: string) => {
  const { formValues } = store.getState().login;
  API_INSTANCES.AUTH.login(formValues)
    .then((response) => {
      const result: LoginProps.LoginAPIResult = response.data;
      console.log("LOGIN RESPONSE", result);
      dispatch(actions.login.setAccessToken(result.access_token));
      dispatch(actions.login.setLoginState(true));
      dispatch(actions.login.setTokenType(result.token_type));
      dispatch(actions.login.setErrorMessageFromAPI(""));
      localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, result.access_token);

      checkUserVerifiedAfterLogin(navigate, redirectURL);
    })
    .catch((error) => {
      console.log("LOGIN ERROR", error);
      const errorCode = error?.response?.data?.detail;
      let errorMessage = "Invalid credentials.";
      if (errorCode === "LOGIN_BAD_CREDENTIALS") {
        errorMessage = "Bad credentials or the user is inactive.";
      }
      dispatch(actions.login.setErrorMessageFromAPI(errorMessage));
    })
    .finally(() => {
      dispatch(actions.register.setAPICallInProgress(false));
    });
};

const otpVerificationAfterRegister = (navigate: NavigateFunction) => {
  const { email, OTP } = store.getState().OTP;

  API_INSTANCES.AUTH.otpVerificationAfterRegister({ email, token: OTP })
    .then((response) => {
      const result = response.data;
      console.log("OTP_REGISTER RESPONSE", result);
      dispatch(actions.OTP.setErrorMessageFromAPI(""));
      dispatch(actions.OTP.setOTPVerificationSuccess(true));
      localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, result.access_token);
      navigate(ROUTES.SIGN_IN);
    })
    .catch((error) => {
      console.log("OTP_REGISTER ERROR", error);
      const errorDetail = error?.response?.data?.detail;
      let errorMessage = errorDetail[0].msg || "";
      dispatch(actions.OTP.setErrorMessageFromAPI(errorMessage));
    });
};

const otpVerificationAfterLogin = (navigate: NavigateFunction) => {
  const { OTP } = store.getState().OTP;
  API_INSTANCES.AUTH.otpVerificationAfterLogin(OTP)
    .then((response) => {
      const result = response.data;
      console.log("OTP LOGIN  RESPONSE", result);
      navigate(ROUTES.HOME);
    })
    .catch((error) => {
      console.log("OTP LOGIN ERROR", error);
      const errorDetail = error?.response?.data?.detail;
      let errorMessage = errorDetail[0].msg || "";
      dispatch(actions.OTP.setErrorMessageFromAPI(errorMessage));
    });
};

const logout = (navigate: NavigateFunction) => {
  console.log("LOGOUT");
  API_INSTANCES.AUTH.logout()
    .then((response) => {
      console.log("LOGOUT RESPONSE", response);
      dispatch(actions.login.setAccessToken(""));
      dispatch(actions.login.setLoginState(false));
      dispatch(actions.login.setTokenType(""));
      localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, "");
      navigate(ROUTES.SIGN_IN);
    })
    .catch((error) => console.log("LOGOUT ERROR", error));
};

export const AUTH = {
  register,
  login,
  otpVerificationAfterRegister,
  otpVerificationAfterLogin,
  logout,
};
