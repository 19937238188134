import { Formik } from "formik";
import styled from "styled-components";

import { DarkModeButton, FormFields } from "../Basic";
import { FormFieldsProps } from "../Basic/FormFields/FormFields";
import { theme } from "../Theme";
import { useAppSelector } from "src/Redux";

//#region PROPS
const inputProps: FormFieldsProps.Input = {
  fieldName: "input",
  idPrefix: "story",
  isRequired: true,
  label: "Input",
};
const emailProps: FormFieldsProps.Input = {
  fieldName: "email",
  idPrefix: "story",
  isRequired: true,
  label: "Email",
  inputType: "email",
};
const searchProps: FormFieldsProps.Search = {
  fieldName: "search",
  idPrefix: "story",
  isRequired: true,
  label: "Search",
};
const passwordProps: FormFieldsProps.Password = {
  fieldName: "password",
  idPrefix: "story",
  isRequired: true,
  label: "Password",
};
const textareaProps: FormFieldsProps.Textarea = {
  fieldName: "textarea",
  idPrefix: "story",
  isRequired: true,
  label: "Text area",
};
const spinnerProps: FormFieldsProps.Spinner = {
  fieldName: "spinner",
  idPrefix: "story",
  isRequired: true,
  label: "Spinner",
};

const countrySelectorProps: FormFieldsProps.CountrySelector = {
  fieldName: "countrySelector",
  idPrefix: "story",
  isRequired: true,
  label: "Country Selector",
  citySearchName: "citySearch",
};
const citySelectorProps: FormFieldsProps.CitySearch = {
  fieldName: "citySearch",
  idPrefix: "story",
  isRequired: true,
  label: "City search",
  countrySelectorName: "countrySelector",
};

const currencySelectorProps: FormFieldsProps.CurrencySelector = {
  fieldName: "currencySelector",
  idPrefix: "story",
  isRequired: true,
  label: "Currency Selector",
  currencyTypeFieldName: "currencySelector_currency",
};
const richTextAreaProps: FormFieldsProps.RichTextarea = {
  fieldName: "richTextArea",
  idPrefix: "story",
  isRequired: true,
  label: "Rich text area",
};

const dropdownOptions = [
  { label: "Audi", value: "Audi-1" },
  { label: "BMW", value: "BMW" },
  { label: "volkswagen", value: "volkswagen" },
  { label: "mitsubishi", value: "mitsubishi" },
  { label: "hyundai", value: "hyundai" },
  { label: "fiat", value: "fiat" },
  { label: "Aston Martin", value: "Aston Martin" },
];
const dropdownProps: FormFieldsProps.Dropdown = {
  fieldName: "dropdown",
  idPrefix: "story",
  isRequired: true,
  label: "Dropdown",
  options: dropdownOptions,
};
const dropdownWithCheckboxProps: FormFieldsProps.DropdownWithCheckbox = {
  fieldName: "dropdownwithCheckbox",
  idPrefix: "story",
  isRequired: true,
  label: "Dropdown with checkbox options",
  options: dropdownOptions,
};

const options: FormFieldsProps.option[] = [
  { label: "India", value: "India" },
  { label: "South africa", value: "South africa" },
  { label: "America", value: "America" },
];
const checkboxGroupProps: FormFieldsProps.CheckboxGroup = {
  fieldName: "checkboxGroup",
  idPrefix: "story",
  isRequired: true,
  label: "Checkbox Group",
  options,
  showOptionsInRow: true,
};
const radioGroupProps: FormFieldsProps.RadioGroup = {
  fieldName: "radioGroup",
  idPrefix: "story",
  isRequired: true,
  label: "Radio Group",
  options,
  showOptionsInRow: true,
};
const switchGroupProps: FormFieldsProps.SwitchGroup = {
  fieldName: "switchGroup",
  idPrefix: "story",
  isRequired: true,
  label: "Switch Group",
  options,
};

const datepickerProps: FormFieldsProps.Datepicker = {
  fieldName: "datepicker",
  idPrefix: "story",
  isRequired: true,
  label: "Date picker",
};

const inputWithIncrementButtonProps: FormFieldsProps.InputWithIncrementButton =
  {
    fieldName: "inputWithIncrementButton",
    idPrefix: "story",
    isRequired: false,
    label: "+ and - inputs",
    allowNegativeNumbers: true,
  };
//#endregion

export const FormInputs = () => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  return (
    <FormExampleContainer isDarkTheme={isDarkTheme}>
      <DarkModeButton />
      <Formik
        key={"formikStory"}
        initialValues={{}}
        onSubmit={(values) => console.log("SUBMITTED VALUES", values)}
      >
        {() => {
          return (
            <Container>
              <FormFields.Input {...inputProps} />
              <FormFields.Input {...emailProps} />
              <FormFields.Search {...searchProps} />
              <FormFields.Password {...passwordProps} />
              <FormFields.TextArea {...textareaProps} />
              <FormFields.Spinners {...spinnerProps} />

              <FormFields.CountrySelector {...countrySelectorProps} />
              <FormFields.CitySearch {...citySelectorProps} />

              <FormFields.CurrencySelector {...currencySelectorProps} />
              <FormFields.RichTextArea {...richTextAreaProps} />

              <FormFields.CheckboxGroup {...checkboxGroupProps} />
              <FormFields.RadioGroup {...radioGroupProps} />
              <FormFields.SwitchGroup {...switchGroupProps} />

              <FormFields.InputWithIncrementButton
                {...inputWithIncrementButtonProps}
              />
              <FormFields.DatePickerElement {...datepickerProps} />

              <FormFields.Dropdown {...dropdownProps} />
              <FormFields.DropdownWithCheckbox {...dropdownWithCheckboxProps} />
            </Container>
          );
        }}
      </Formik>
    </FormExampleContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const FormExampleContainer = styled.div<{ isDarkTheme: boolean }>`
  background: ${({ isDarkTheme }) =>
    isDarkTheme ? theme.colors.dark_form_background : theme.colors.white100};
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  @media (min-width: 768px) {
    margin: 0;
    justify-content: start;
    padding: 50px;
    max-width: 700px;
  }
`;
