import { createSlice } from "@reduxjs/toolkit";
import {
  Activity,
  Candidate,
  CandidateListData,
  Career,
  Comment,
  Education,
  Files,
  Qualifier,
} from "src/components/Pipeline/PipeLine";
import { activityData } from "src/components/Pipeline/Utils";

const initialState: {
  candidateListData: CandidateListData;
  selectedCandidate: Candidate;
  pageSize: number;
  careerHistory: Career[];
  educationHistory: Education[];
  files: Files[];
  comment: Comment[];
  activity: Activity[];
  qualifiers: Qualifier[];
} = {
  candidateListData: {
    applicants: [],
    results: {
      total_applicants: -1,
      total_pages: 0,
    },
    query: {
      job_id: -1,
      page: -1,
      size: -1,
      sort: "",
    },
  },
  selectedCandidate: {
    application_id: "",
    candidate_id: -1,
    name: "",
    current_job_title: "",
    candidate_rating: "",
    profile_picture:
      "",
    phone: "",
    email: "",
    location: "",
    application_date: "",
    section_experience: 0,
    section_files: 0,
    section_comments: 0,
    section_activity: 0,
    section_qualifiers: 0,
    recent_positions: {
      job_title: "",
      company: "",
      to_date: "",
      previous_positions: "",
    },
    key_skills: [
     
    ],
    work_experience: "",
    languages: [
      
    ],
    notice_period: "",
    citizenship: "",
    own_transport: true,
    willing_to_relocate: true,
    current_remuneration: "",
    desired_remuneration: "",
    bio: ``,
    pipeline_status: 0,
  },
  pageSize: 0,
  careerHistory: [],
  educationHistory: [],
  files: [],
  comment: [],
  activity: activityData,
  qualifiers: [],
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setIsEmailOpen: (state, action) => {
      state.candidateListData.applicants[action.payload].is_read = true;
    },
    setCandidatelist: (state, action) => {
      state.candidateListData = action.payload;
    },
    setSelectedCandidate: (state, action) => {
      state.selectedCandidate = {
        ...action.payload,
      };
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setPhase: (state, action) => {
      state.selectedCandidate.pipeline_status = action.payload;
    },
    setCareerHistory: (state, action) => {
      state.careerHistory = action.payload;
    },
    setEducationHistory: (state, action) => {
      state.educationHistory = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setQualifiers: (state, action) => {
      state.qualifiers = action.payload;
    },
    setComments: (state, action) => {
      state.comment = action.payload;
    },
    setAllEmpty: (state) => {
      state.files = [];
      state.educationHistory = [];
      state.careerHistory = [];
      state.qualifiers = [];
      state.comment = [];
    },
  },
});

export const candidateActions = candidateSlice.actions;
export const candidateReducer = candidateSlice.reducer;
