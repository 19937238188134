import { IIconProps } from "./customIcons";

const HomeIcon = ({ width, height }: IIconProps.Iprops) => {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      className="header-icon-svgs"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.833156 9.16842H1.66649V15.0018C1.66649 15.9209 2.41399 16.6684 3.33316 16.6684H13.3332C14.2523 16.6684 14.9998 15.9209 14.9998 15.0018V9.16842H15.8332C15.9979 9.16839 16.159 9.11949 16.296 9.02792C16.433 8.93635 16.5398 8.80622 16.6029 8.65397C16.6659 8.50172 16.6824 8.33419 16.6503 8.17256C16.6182 8.01093 16.5388 7.86246 16.4223 7.74592L8.92232 0.245921C8.845 0.168466 8.75316 0.107018 8.65207 0.0650926C8.55097 0.023167 8.4426 0.00158691 8.33316 0.00158691C8.22371 0.00158691 8.11534 0.023167 8.01425 0.0650926C7.91315 0.107018 7.82131 0.168466 7.74399 0.245921L0.243989 7.74592C0.127481 7.86246 0.0481425 8.01093 0.0160029 8.17256C-0.0161367 8.33419 0.000365792 8.50172 0.0634242 8.65397C0.126483 8.80622 0.233265 8.93635 0.370274 9.02792C0.507282 9.11949 0.668364 9.16839 0.833156 9.16842ZM6.66649 15.0018V10.8351H9.99982V15.0018H6.66649ZM8.33316 2.01342L13.3332 7.01342V10.8351L13.334 15.0018H11.6665V10.8351C11.6665 9.91592 10.919 9.16842 9.99982 9.16842H6.66649C5.74732 9.16842 4.99982 9.91592 4.99982 10.8351V15.0018H3.33316V7.01342L8.33316 2.01342Z" />
    </svg>
  );
};

export default HomeIcon;
