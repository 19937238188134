import { IIconProps } from "./customIcons";

const ActivityIcon: React.FC<IIconProps.Iprops> = ({ width }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.6668 50H75.0002L62.5002 87.5L37.5002 12.5L25.0002 50H8.3335"
        stroke="#4A4A69"
        strokeWidth={width || "3"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActivityIcon;
