import { CommonProps } from "./Common";
import { createSlice } from "@reduxjs/toolkit";

const initialState: CommonProps.State = {
  isDarkTheme: false,
  isThemeSet: false,
  selected_country: {} as CommonProps.countryValue,
  cities: [] as CommonProps.cities,
  skills: [] as CommonProps.skills,
  industries: [] as CommonProps.industries,
  industryMap: {},
  getCitiesAPIInProgress: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDarkTheme: (state, action: CommonProps.setDarkTheme) => {
      state.isDarkTheme = action.payload || false;
    },
    setThemeSet: (state, action: CommonProps.setThemeSet) => {
      state.isThemeSet = action.payload || false;
    },

    setSkills: (state, action: CommonProps.setSkills) => {
      state.skills = action.payload || false;
    },
    setIndustries: (state, action: CommonProps.setIndustries) => {
      state.industries = action.payload || false;
      const map = {} as CommonProps.industryMap;
      state.industries.forEach(({ id, name }) => {
        map[name] = id;
      });
      state.industryMap = map;
    },
    setSelectedCountry: (state, action: CommonProps.setSelectedCountry) => {
      state.selected_country = action.payload || false;
    },
    setCities: (state, action: CommonProps.setCities) => {
      state.cities = action.payload || false;
    },
    setGetCitiesAPIInProgress: (
      state,
      action: CommonProps.setGetCitiesAPIInProgress
    ) => {
      state.getCitiesAPIInProgress = action.payload || false;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;
