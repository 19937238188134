import { createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { Breakpoint, Grid } from "antd";

import "./App.css";
import { Pages, Auth, AuthLayout, theme } from "./components";
import { ROUTES } from "./utils/ROUTES";
import { Protected } from "./components";
import store from "./Redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

type context = {
  isMobile: boolean;
  isSmallTablet: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  screen: Partial<Record<Breakpoint, boolean>>;

  isSmallTabletOrBig: boolean;
  isTabletOrBig: boolean;
  isDesktopOrBig: boolean;
  isSmallTabletOrSmall: boolean;
  isTabletOrSmall: boolean;
};
export const AppContext = createContext({} as context);

function App() {
  document
    .querySelector("body")
    ?.classList.add("ltr", "main-body", "app", "sidebar-mini");

  const screen = Grid.useBreakpoint();
  const isMobile = !screen.sm;
  const isSmallTablet = screen.sm && !screen.md ? true : false;
  const isTablet = screen.md && !screen.lg ? true : false;
  const isDesktop = screen.lg && !screen.xl ? true : false;
  const isLargeDesktop = screen.xl ? true : false;

  const isSmallTabletOrBig = screen.sm ? true : false;
  const isTabletOrBig = screen.md ? true : false;
  const isDesktopOrBig = screen.lg ? true : false;

  const isSmallTabletOrSmall = !screen.md;
  const isTabletOrSmall = !screen.lg;

  return (
    <AppContext.Provider
      value={{
        isMobile,
        isSmallTablet,
        isTablet,
        isDesktop,
        isLargeDesktop,
        screen,
        isSmallTabletOrBig,
        isTabletOrBig,
        isDesktopOrBig,
        isSmallTabletOrSmall,
        isTabletOrSmall,
      }}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PageBackground>
            <Router>
              <Routes>
                {/* Story pages */}
                <Route path="/button" element={<Pages.Button />} />
                <Route path="/table" element={<Pages.Tables />} />
                <Route path="/form-input" element={<Pages.FormInputs />} />
                <Route path="/form-Example" element={<Pages.FormExample />} />

                {/* Auth pages */}
                <Route element={<AuthLayout />}>
                  <Route path={ROUTES.SIGN_IN} element={<Auth.SignIn />} />
                  <Route path={ROUTES.REGISTER} element={<Auth.Register />} />
                  <Route path={ROUTES.FORGOT} element={<Auth.Forgot />} />
                  <Route path={ROUTES.RESET} element={<Auth.Reset />} />
                  <Route path={ROUTES.OTP} element={<Auth.OTP />} />
                </Route>

                {/* Protected paths */}
                <Route
                  path="/programatic"
                  element={<Protected component={<Pages.Programatic />} />}
                />
                <Route
                  path={ROUTES.SETTINGS}
                  element={<Protected component={<Pages.Settings />} />}
                />
                <Route
                  path={ROUTES.CREATE_JOB}
                  element={<Protected component={<Pages.CreateJob />} />}
                />
                <Route
                  path={ROUTES.PIPELINE}
                  element={<Protected component={<Pages.Pipeline />} />}
                />
                <Route
                  path={ROUTES.JOBS}
                  element={<Protected component={<Pages.Job />} />}
                />
                <Route
                  path={ROUTES.HOME}
                  element={<Protected component={<Pages.Dashboard />} />}
                />
                <Route element={<AuthLayout />}>
                  <Route path={ROUTES.SIGN_IN} element={<Auth.SignIn />} />
                  <Route path={ROUTES.REGISTER} element={<Auth.Register />} />
                  <Route path={ROUTES.FORGOT} element={<Auth.Forgot />} />
                  <Route path={ROUTES.RESET} element={<Auth.Reset />} />
                  <Route path={ROUTES.OTP} element={<Auth.OTP />} />
                </Route>
                <Route
                  path={ROUTES.TEAM_MANAGEMENT}
                  element={<Pages.TeamManagement />}
                />
              </Routes>
            </Router>
          </PageBackground>
        </ThemeProvider>
      </Provider>
      <ToastContainer />
    </AppContext.Provider>
  );
}

const PageBackground = styled.div`
  background: ${theme.colors.white100};
  min-height: 100vh;
  position: relative;
  .dark-theme & {
    background: ${theme.colors.black120};
  }
`;

export default App;
