import { useState } from "react";
import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELDS } from "../FormFieldsStyles/common";

export const SearchField = ({
  fieldName,
  idPrefix,
  onChangeHandler = () => {},
  value,
}: FormFieldsProps.SearchField) => {
  return (
    <SearchContainer disabled={false} Error={false}>
      <Form.Control
        id={`${idPrefix}_${fieldName}`}
        name={fieldName}
        type="text"
        className="form-control"
        placeholder={fieldName}
        onChange={(event) => onChangeHandler(event?.target?.value)}
        value={value}
      />
    </SearchContainer>
  );
};

export const Search = (props: FormFieldsProps.Input) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    needLabelPlaceholder = false,
    showLabel,
  } = props;
  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta }: FieldProps) => {
        const { onChange, onBlur, value } = field;
        const { touched, error } = meta;

        const hasError = !isFocused && isRequired && touched && !value;
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <SearchContainer disabled={disabled} Error={hasError}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <Form.Control
              id={`${idPrefix}_${fieldName}`}
              name={fieldName}
              type="text"
              className="form-control"
              placeholder={fieldName}
              onChange={onChange}
              value={value}
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                setFocused(false);
                onBlur(e);
              }}
            />
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </SearchContainer>
        );
      }}
    </Field>
  );
};

const SearchContainer = styled.div<{ disabled: boolean; Error: boolean }>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL;
  }};
`;
