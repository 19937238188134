//import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { colors } from "./components/Theme/Colors";

import "./index.scss";

const rootElement = document.getElementById("root");
const root = rootElement && ReactDOM.createRoot(rootElement);
const rootColor = document.documentElement;
Object.entries(colors).forEach(([colorName, colorValue]) => {
  rootColor.style.setProperty(`--${colorName}`, colorValue);
});
root && root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
