import { useEffect } from "react";
import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import {
  File,
  FileContainer,
  FilesListContainer,
  FilesMainContainer,
  HeadingContainer,
  Icon,
  IconContainer,
} from "./FilesStyle";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";

const Files = () => {
  const dispatch = useAppDispatch();
  const { files, selectedCandidate } = useAppSelector(
    (state) => state.candidate
  );
  useEffect(() => {
    if (files.length === 0) {
      API_INSTANCES.PIPELINE.getCandidateFiles(
        selectedCandidate.candidate_id
      ).then((res) => {
        dispatch(actions.candidate.setFiles(res.data.candidate_files[0].files));
      });
    }
  }, []);

  const downloadFile = (url: string, filename: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <FilesMainContainer>
      <HeadingContainer>
        <Typography
          content="FILES"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      <FilesListContainer>
        {files?.length > 0 ? (
          files?.map((file, index) => (
            <FileContainer key={index}>
              <File>
                <ICONS.PdfIcon />
                <Typography
                  content={file.name}
                  styleName="fileNameText"
                  color="black80"
                />
              </File>
              <Icon onClick={() => downloadFile(file.path, file.name)}>
                <ICONS.FileDownloadIcon />
              </Icon>
            </FileContainer>
          ))
        ) : (
          <>
            <HeadingContainer className="no-data-msg">
              <Typography
                content="No files added."
                styleName="text1"
                color="black80"
              />
            </HeadingContainer>
            <IconContainer>
              <ICONS.PaperClip />
            </IconContainer>
          </>
        )}
      </FilesListContainer>
    </FilesMainContainer>
  );
};

export default Files;
