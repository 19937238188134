import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import {
  AccordianContainer,
  ExperienceContainer,
  HeadingContainer,
  IconContainer,
  ExperienceNotAddedContainer,
} from "./ExperienceStyle";
import CareerAccordian from "./Accordion/CareerAccordian";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";
import { useEffect } from "react";
import EducationAccordion from "./Accordion/EducationAccordion";

const Experience = () => {
  const { careerHistory, educationHistory, selectedCandidate } = useAppSelector(
    (state) => state.candidate
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (careerHistory.length === 0 && educationHistory.length === 0)
      API_INSTANCES.PIPELINE.getCandidateExperience(
        selectedCandidate.candidate_id
      )
        .then((res) => {
          dispatch(
            actions.candidate.setCareerHistory(res.data.candidate_work[0].work)
          );
          dispatch(
            actions.candidate.setEducationHistory(
              res.data.candidate_education[0].education
            )
          );
        })
        .catch((error) => console.log(error));
  }, []);

  return (
    <ExperienceContainer>
      <HeadingContainer className="heading">
        <Typography
          content="Experience"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      {careerHistory?.length <= 0 && educationHistory?.length <= 0 ? (
        <>
          <ExperienceNotAddedContainer className="no-data-msg">
            <Typography
              content="No Experience added."
              styleName="text1"
              color="black80"
            />
          </ExperienceNotAddedContainer>
          <IconContainer>
            <ICONS.BriefCaseIconWithVerticalLine />
          </IconContainer>
        </>
      ) : (
        <>
          <AccordianContainer>
            <div className="heading">
              <Typography
                content="Career History"
                styleName="subtitle3"
                color="black80"
              />
            </div>
            {careerHistory?.length > 0 ? (
              <CareerAccordian accordianData={careerHistory} />
            ) : (
              <HeadingContainer className="no-data-msg">
                <Typography
                  content="No Career History added."
                  styleName="text1"
                  color="black80"
                />
              </HeadingContainer>
            )}
          </AccordianContainer>
          <AccordianContainer>
            <div className="mt-3 heading">
              <Typography
                content="Education History"
                styleName="subtitle3"
                color="black80"
              />
            </div>
            {educationHistory?.length > 0 ? (
              <EducationAccordion accordianData={educationHistory} />
            ) : (
              <HeadingContainer className="no-data-msg">
                <Typography
                  content="No Education History added."
                  styleName="text1"
                  color="black80"
                />
              </HeadingContainer>
            )}
          </AccordianContainer>
        </>
      )}
    </ExperienceContainer>
  );
};

export default Experience;
