import { FormFieldsProps } from "src/components/Basic";

export const idPrefix = "email-notifications";

const daily_applications: FormFieldsProps.Switch = {
  fieldName: "daily_applications",
  idPrefix,
  isRequired: false,
  showLabel: false,
};
const weekly_applications: FormFieldsProps.Switch = {
  fieldName: "weekly_applications",
  idPrefix,
  isRequired: false,
  showLabel: false,
};
const monthly_account: FormFieldsProps.Switch = {
  fieldName: "monthly_account",
  idPrefix,
  isRequired: false,
  showLabel: false,
};
const weekly_account: FormFieldsProps.Switch = {
  fieldName: "weekly_account",
  idPrefix,
  isRequired: false,
  showLabel: false,
};

export const emailNotificationFields = {
  daily_applications,
  weekly_applications,
  monthly_account,
  weekly_account,
};
