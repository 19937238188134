import { NavigateFunction } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { CandidateProfile } from "src/components/Settings/CandidateProfile/CandidateProfile";
import { API_CALLS } from ".";
const dispatch = store.dispatch;

const getCandidateDetail = () => {
  API_INSTANCES.CANDIDATE_SETTINGS.getCandidateDetail()
    .then((response) =>
      dispatch(actions.settings.setCandidateDetails(response.data))
    )
    .catch((error) => console.log(error));
};

const updateCandidateDetail = (
  value: CandidateProfile.CandidateDetails,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  API_INSTANCES.CANDIDATE_SETTINGS.updateCandidateDetail(value)
    .then((response) => {
      dispatch(actions.settings.setCandidateDetails(response.data));
      setAction(false);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
    });
};

const getCandidateProfilePhoto = () => {
  API_INSTANCES.CANDIDATE_SETTINGS.getCandidateProfilePhoto()
    .then((result) =>
      dispatch(
        actions.settings.setCandidateProfilePhoto(result.data.profile_picture)
      )
    )
    .catch((error) => console.log(error));
};

const updateCandidateProfilePhoto = (values: File) => {
  API_INSTANCES.CANDIDATE_SETTINGS.updateCandidateProfilePhoto(values)
    .then((result) =>
      dispatch(
        actions.settings.setCandidateProfilePhoto(result.data.profile_picture)
      )
    )
    .catch((error) => console.log(error));
};

const updateCandidatePassword = (
  values: CandidateProfile.Password,
  navigate: NavigateFunction,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  API_INSTANCES.CANDIDATE_SETTINGS.updateCandidatePassword(values)
    .then(() => {
      setAction(false);
      API_CALLS.AUTH.logout(navigate);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
    });
};

export const CANDIDATE_SETTINGS = {
  getCandidateDetail,
  updateCandidateDetail,
  getCandidateProfilePhoto,
  updateCandidateProfilePhoto,
  updateCandidatePassword,
};
