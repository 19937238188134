import React from "react";
import { ISettings } from "../Settings";
import { Buttons, CustomModal, Typography } from "src/components/Basic";
import { Button, Modal } from "react-bootstrap";
import { ButtonContainer, Container, ModalHeader } from "./ModalStyle";

const DeactivateModal: React.FC<ISettings.IConfirmationModal> = ({
  isModalOpen,
  setIsModalOpen,
  heading,
  content,
  handleclick,
}) => {
  return (
    <CustomModal isModalOpen={isModalOpen}>
      <ModalHeader>
        <Modal.Title>
          <Typography
            content={heading}
            styleName="modalHeading"
            color="black80"
          />
        </Modal.Title>
        <Button
          variant=""
          className="btn btn-close"
          style={{ opacity: "1" }}
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          x
        </Button>
      </ModalHeader>

      <Modal.Body className="px-2">
        <Container>
          <Typography
            content={content}
            color="blue_grey_80"
            styleName="inputGeneral"
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ButtonContainer>
          <Buttons
            variant="light"
            handleClick={() => {
              setIsModalOpen(false);
            }}
            label="Cancel"
          />
        </ButtonContainer>
        <ButtonContainer>
          <Buttons
            variant="success"
            handleClick={handleclick}
            label="Confirm"
          />
        </ButtonContainer>
      </Modal.Footer>
    </CustomModal>
  );
};

export default DeactivateModal;
