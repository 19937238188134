import styled from "styled-components";
import { theme } from "src/components/Theme";

export const ActivityContainer = styled.div`
  margin: 20px 24px 0px 38px;
  @media (max-width: 991px) {
    margin: 16px 12px 0px 16px;
  }
`;
export const DateContainer = styled.div`
  white-space: nowrap;
  background: ${theme.colors.grey20};
  border-radius: 3px;
  padding: 3px 6px 4px 5px;
  @media (max-width: 991px) {
    margin: auto 0 auto auto;
  }
  .dark-theme & {
    @media (min-width: 992px) {
      background: ${theme.colors.black110};
      color: ${theme.colors.black70};
    }
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  & > div {
    ${theme.text.activityTextDesc}
    color: ${theme.colors.black70}
  }
  label.word_deleted {
    background: rgba(243, 67, 67, 0.15);
    color: ${theme.colors.danger_default};
    padding: 4px 6px 3px 5px;
    border-radius: 3px;
    ${theme.text.summaryDateText};
  }
  label.word_files,
  label.word_folders,
  label.word_login {
    background: rgba(56, 202, 179, 0.2);
    border-radius: 3px;
    color: ${theme.colors.success_default};
    padding: 4px 6px 3px 5px;
    ${theme.text.summaryDateText}
  }
  .dark-theme & > div {
    color: ${theme.colors.black10};
    @media (min-width: 992px) {
      color: ${theme.colors.black50};
    }
  }
`;
export const Container = styled.div`
  padding: 15px 20px 13px 24px;
  background: ${theme.colors.light_default};
  border: 1px solid ${theme.colors.primary_default};
  box-shadow: ${theme.Effects.activityShadow};
  border-radius: 2px;
  .dark-theme & {
    background: ${theme.colors.black120};
    border: none;
    border-radius: 6px;
    @media (min-width: 992px) {
      border: 1px solid ${theme.colors.primary_default};
      border-radius: 2px;
    }
    .comment-name label {
      color: ${theme.colors.black10};
    }
  }
`;
export const ActivityTimelineContainer = styled.div`
  margin-top: 24px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;
export const TimelineContainer = styled.div`
  &:before {
    left: 10px;
    background: ${theme.colors.primary10};
    @media (max-width: 991px) {
      display: none;
    }
  }
  & .timeline-wrapper .timeline-badge {
    left: 0px;
    width: 20px;
    height: 20px;
    background: ${theme.colors.white_pure};
    border: 3px solid ${theme.colors.primary_default};
    border-radius: 21px;
    top: calc(50% - 13px);
    @media (max-width: 991px) {
      display: none;
    }
  }
  & .timeline-wrapper.timeline-inverted .timeline-panel {
    margin: 0px;
    width: 100%;
  }
  & .timeline-wrapper.timeline-inverted {
    padding-left: 60px;
  }
  .dark-theme & {
    &::before {
      background: rgba(30, 150, 242, 0.2);
    }
    .timeline-wrapper .timeline-badge {
      background: ${theme.colors.black210};
      border: 3px solid ${theme.colors.primary_default};
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  svg {
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 991px) {
    margin: 55px auto 80px;
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.light_default};
  }
`;
export const HeadingContainer = styled.div`
  @media (max-width: 991px) {
    margin-left: 8px;
  }
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
`;
