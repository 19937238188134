import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { Button } from "react-bootstrap";
import { IIconButton } from "./IconButton";
import styled from "styled-components";

export const IconButton: React.FC<IIconButton.IProps> = ({
  icon,
  position,
  label,
  isDisable = false,
  handleClick,
}) => {
  return (
    <ButtonContainer isDisable={isDisable}>
      <Button
        variant=""
        className={clsx(
          "btn  btn-primary  d-flex gap-2 align-items-center ",
          isDisable && "disabled"
        )}
        style={{ fontWeight: "600" }}
        onClick={handleClick}
        disabled={isDisable}
      >
        <span>
          {position === "left" && <FontAwesomeIcon icon={icon} size="sm" />}
        </span>
        <span>{label}</span>
        <span>
          {position === "right" && <FontAwesomeIcon icon={icon} size="sm" />}
        </span>
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<{ isDisable: boolean }>`
  cursor: ${({ isDisable }) => (isDisable ? "not-allowed" : "pointer")};
  padding: 0;
`;
