import { useState, useContext, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";

import { Buttons, Typography } from "src/components/Basic";
import {
  BillingInformationContainer,
  HeadingContainer,
  Wrapper,
} from "./style";
import CardList from "./CardList";
import AddNewCardForm from "./AddNewCardForm";
import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { API_INSTANCES } from "src/API_INSTANCES";
import { API_CALLS } from "src/API_CALLS";

const BillingInformation = () => {
  const [isAddCardFormOpen, setIsAddCardFormOpen] = useState(false);
  const [addNewCardFormContent, setAddNewCardFormContent] = useState(<></>);
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const { isTabletOrBig } = useContext(AppContext);
  const {cardList} = useAppSelector((state) => state.stripe);
  const stripePromise = loadStripe(
    "pk_test_51MqFDQBkdQCWOfucT5xlcKY93VJKt1zDAQftJPd8Wquwb9eN2CDOuhOhkfytmN1gfv5Z7Wd1uZQqlDabYJ2DbMau00ZZNIF5oP"
  );
  const options: StripeElementsOptions = {
    // Fully customizable with appearance API.
    clientSecret: "",
    appearance: {
      theme: isDarkTheme ? "night" : "stripe",
      variables: {
        colorText: isDarkTheme
          ? theme.colors.dark_input_text
          : theme.colors.black80,
        fontFamily: "Poppins, sans-serif",
        borderRadius: "5px",
        colorBackground: isDarkTheme
          ? theme.colors.dark_input_background
          : theme.colors.white_pure,
        colorTextPlaceholder: isDarkTheme
          ? theme.colors.dark_input_placeholder
          : theme.colors.black50,
        colorDanger: theme.colors.red100,
      },
      rules: {
        ".Input": {
          color: isDarkTheme
            ? theme.colors.dark_input_text
            : theme.colors.black80,
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0em",
          boxShadow: "none",
          borderColor: isDarkTheme
            ? theme.colors.dark_input_border
            : theme.colors.black10,
        },
        ".Input:hover": {
          boxShadow: theme.Effects.hover_active,
        },
        ".Input:focus": {
          boxShadow: theme.Effects.hover_active,
          borderColor: isDarkTheme
            ? theme.colors.dark_input_border
            : theme.colors.black10,
        },
        ".Input:active": {
          boxShadow: theme.Effects.hover_active,
        },
        ".Input--invalid": {
          boxShadow: "none",
        },
        ".Input--invalid:hover": {
          borderColor: theme.colors.red100,
          boxShadow: theme.Effects.error,
        },
        ".Input--invalid:focus": {
          borderColor: theme.colors.red100,
          boxShadow: theme.Effects.error,
        },
        ".Input--invalid:active": {
          borderColor: theme.colors.red100,
          boxShadow: theme.Effects.error,
        },
        ".Error": {
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.01em",
          fontSize: `${isTabletOrBig ? 13 : 12}px`,
        },
        ".Label": {
          color: isDarkTheme
            ? theme.colors.dark_input_label
            : theme.colors.black80,
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "21px",
          letterSpacing: "0em",
        },
      },
    },
  };

  const createSetupIntent = () => {
    API_INSTANCES.STRIPE.createSetupIntent()
      .then((res) => {
        setAddNewCardFormContent(
          <Elements
            stripe={stripePromise}
            options={{ ...options, clientSecret: res.data.client_secret }}
          >
            <AddNewCardForm />
          </Elements>
        );
        return;
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    API_CALLS.STRIPE.getStripeCard();
  }, []);

  return (
    <BillingInformationContainer>
      <HeadingContainer>
        <Typography
          content="BILLING INFORMATION"
          styleName="candidateNameEmailOpen"
        />
        <Typography content="Add or delete cards." styleName="text1" />
      </HeadingContainer>
      {cardList.length > 0 && <CardList />}
      {isAddCardFormOpen ? (
        addNewCardFormContent
      ) : (
        <Wrapper>
          <Buttons
            handleClick={() => {
              createSetupIntent();
              setIsAddCardFormOpen(true);
            }}
            label="Add New Card"
            variant="primary"
          />
        </Wrapper>
      )}
    </BillingInformationContainer>
  );
};

export default BillingInformation;
