const PdfIcon = () => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H15C16.6569 24 18 22.6569 18 21V8L14 4L10 0H3Z"
        fill="#C7E5FC"
      />
      <path
        d="M18 8L12 8C10.8954 8 10 7.10457 10 6L10 -6.99382e-07L14 4L18 8Z"
        fill="#8ECAF8"
      />
      <path
        d="M5.913 13.403C5.913 13.7203 5.8365 14.0178 5.6835 14.2955C5.53617 14.5732 5.301 14.797 4.978 14.967C4.66067 15.137 4.25833 15.222 3.771 15.222H2.7765V17.5H1.5865V11.567H3.771C4.23 11.567 4.621 11.6463 4.944 11.805C5.267 11.9637 5.50783 12.1818 5.6665 12.4595C5.83083 12.7372 5.913 13.0517 5.913 13.403ZM3.72 14.2615C4.04867 14.2615 4.29233 14.1878 4.451 14.0405C4.60967 13.8875 4.689 13.675 4.689 13.403C4.689 12.825 4.366 12.536 3.72 12.536H2.7765V14.2615H3.72ZM8.83189 11.567C9.45522 11.567 10.0021 11.6888 10.4724 11.9325C10.9484 12.1762 11.3139 12.5247 11.5689 12.978C11.8296 13.4257 11.9599 13.947 11.9599 14.542C11.9599 15.137 11.8296 15.6583 11.5689 16.106C11.3139 16.548 10.9484 16.8908 10.4724 17.1345C10.0021 17.3782 9.45522 17.5 8.83189 17.5H6.75789V11.567H8.83189ZM8.78939 16.4885C9.41272 16.4885 9.89439 16.3185 10.2344 15.9785C10.5744 15.6385 10.7444 15.1597 10.7444 14.542C10.7444 13.9243 10.5744 13.4427 10.2344 13.097C9.89439 12.7457 9.41272 12.57 8.78939 12.57H7.94789V16.4885H8.78939ZM16.5142 11.567V12.5275H14.0407V14.049H15.9362V14.9925H14.0407V17.5H12.8507V11.567H16.5142Z"
        fill="#1E96F2"
      />
    </svg>
  );
};

export default PdfIcon;
