import { useState } from "react";
import { Field, FieldProps, FormikValues } from "formik";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  getHTMLContentFromEditorState,
  getInitialContentState_WithHTML,
  getPlainTextFromEditorState,
  getRichTextAreaHasError,
} from "./richTextEditor.helpers";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { RichTextAreaContainer } from "./richTextAreaStyles";

type RichTextAreaHasErrorProps = {
  formik: FormikValues;
  editorState: EditorState;
  fieldName: string;
  textCount?: number;
  textLimit?: number;
  description?: string;
};

export const RichTextArea = (props: FormFieldsProps.RichTextarea) => {
  const {
    fieldName,
    idPrefix,
    isRequired,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    minHeight = 300,
    customValidation,
    showLabel = true,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [editorStateInitiated, setEditorStateInitiated] = useState(false);
  const editorStateContent = getInitialContentState_WithHTML("");
  const [editorState, setEditorState] = useState(editorStateContent);

  return (
    <Field id={`${idPrefix}_${fieldName}`} name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const { touched, value, error } = meta;
        const { setFieldValue, setFieldTouched } = form;

        const plainText = getPlainTextFromEditorState(editorState);
        const textCount = plainText.length;
        if (!editorStateInitiated && value) {
          const newEditorStateContent = getInitialContentState_WithHTML(
            value || ""
          );
          setEditorState(newEditorStateContent);
          setEditorStateInitiated(true);
        }

        //Error validation
        const richTextAreaHasErrorProps: RichTextAreaHasErrorProps = {
          formik: form,
          editorState,
          fieldName,
          textCount,
        };
        const errorConditions = isRequired && !isFocused && touched;
        let hasError =
          errorConditions &&
          ((error ? true : false) ||
            getRichTextAreaHasError(richTextAreaHasErrorProps));

        let errorLabel = error || `${label || fieldName} is required`;

        if (customValidation?.validation && !hasError) {
          hasError = errorConditions && customValidation.validation(plainText);
          errorLabel = customValidation.validationMessage;
        }
        const onEditorStateChange = (EditorstateFromProps: EditorState) => {
          setEditorState(EditorstateFromProps);
        };
        const showErrorLabel = hasError && errorLabel;

        return (
          <RichTextAreaContainer
            containerWidth="100%"
            value={value}
            focus={isFocused}
            hasError={hasError}
            minHeight={minHeight}
          >
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <Editor
              onChange={() => {
                setFieldValue(
                  fieldName,
                  textCount > 0
                    ? getHTMLContentFromEditorState(editorState)
                    : ""
                );
              }}
              placeholder={
                getHTMLContentFromEditorState(editorState).startsWith("<p")
                  ? placeholder
                  : ""
              }
              wrapperClassName={`description-editor ${
                isFocused && "description-editor--focus"
              }`}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onFocus={(event) => {
                event.preventDefault();
                setFocused(true);
              }}
              stripPastedStyles={true}
              onBlur={(event) => {
                event.preventDefault();
                setFieldTouched(fieldName, true);
                setFieldValue(
                  fieldName,
                  textCount > 0
                    ? getHTMLContentFromEditorState(editorState)
                    : ""
                );
                setFocused(false);
              }}
              toolbar={{
                options: ["inline", "list", "embedded"],
                inline: {
                  inDropdown: false,
                  options: ["bold", "italic", "underline"],
                  bold: {
                    icon: "/static/images/RichTextAreaToolbarIcons/bold.svg",
                    className: undefined,
                  },
                  italic: {
                    icon: "/static/images/RichTextAreaToolbarIcons/italic.svg",
                    className: undefined,
                  },
                  underline: {
                    icon: "/static/images/RichTextAreaToolbarIcons/underline.svg",
                    className: undefined,
                  },
                },
                list: {
                  inDropdown: false,
                  options: ["unordered", "ordered"],
                  unordered: {
                    icon: "/static/images/RichTextAreaToolbarIcons/unordered.svg",
                    className: undefined,
                  },
                  ordered: {
                    icon: "/static/images/RichTextAreaToolbarIcons/ordered.svg",
                    className: undefined,
                  },
                },
                textAlign: {
                  inDropdown: false,
                  options: ["left", "center", "right", "justify"],
                  left: {
                    icon: "/static/images/RichTextAreaToolbarIcons/left.svg",
                    className: undefined,
                  },
                  center: {
                    icon: "/static/images/RichTextAreaToolbarIcons/center.svg",
                    className: undefined,
                  },
                  right: {
                    icon: "/static/images/RichTextAreaToolbarIcons/right.svg",
                    className: undefined,
                  },
                  justify: {
                    icon: "/static/images/RichTextAreaToolbarIcons/justify.svg",
                    className: undefined,
                  },
                },
                embedded: {
                  icon: "/static/images/RichTextAreaToolbarIcons/embedded.svg",
                },
              }}
            />
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </RichTextAreaContainer>
        );
      }}
    </Field>
  );
};
