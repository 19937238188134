import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ResendLinkWrapper = styled.div`
  padding-top: 16px;
  display: flex;
`;
const Title = styled.div`
  width: 250px;
  @media (min-width: 768px) {
    width: auto;
  }
`;
const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
`;
const ErrorMessageWrapper = styled.div`
  padding: 10px 38px;
  border: 1px solid ${({ theme }) => theme.colors.danger_default};
  background: ${({ theme }) => theme.colors.danger_default}20;
`;
const Line1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;
`;

export const OTPStyles = {
  Container,
  ResendLinkWrapper,
  Subtitle,
  ErrorMessageWrapper,
  Line1,
  Title,
};
