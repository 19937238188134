import { useContext, useEffect, useRef } from "react";

import { CheckoutStyles } from "../Styles";
import { Bill } from "./Bill";
import { Heading } from "./Heading";
import { CheckoutSummaryContext } from ".";

export const Modal = () => {
  const { setShowModal } = useContext(CheckoutSummaryContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        setShowModal((prev: boolean) => !prev);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <CheckoutStyles.ModalContainer>
      <div
        style={{ background: "white", padding: "24px" }}
        ref={ref}
        className="page-container"
      >
        <Heading />
        <Bill />
      </div>
    </CheckoutStyles.ModalContainer>
  );
};
