import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";
import { JobProps } from "src/Redux/slices";

const create = (values: JobProps.CreateNovaJobAPI) =>
  instance.post(API_PATHS.createJob, JSON.stringify(values));

const get = (id: string | number) => instance.get(`${API_PATHS.getJob}/${id}`);

const getJobOverview = (status: number) =>
  instance.get(`${API_PATHS.jobOverview}/${status}`);

const getChartData = (values: JobProps.getChartDataAPI) =>
  instance.post(`${API_PATHS.getChartData}`, JSON.stringify(values));

export const JOB = { getJobOverview, create, get, getChartData };
