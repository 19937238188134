import { IIconProps } from "./customIcons";

const Up = ({ fill }: IIconProps.Iprops) => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_222_917)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.71942 4.98577C1.51002 5.19517 1.17052 5.19517 0.96111 4.98577C0.75171 4.77637 0.75171 4.43686 0.96111 4.22746L4.33597 0.852597C4.54518 0.64339 4.88437 0.64339 5.09358 0.852597L8.46844 4.22746C8.67784 4.43686 8.67784 4.77637 8.46844 4.98577C8.25903 5.19517 7.91952 5.19517 7.71012 4.98577L4.71477 1.99042L1.71942 4.98577Z"
        fill={fill || "#8792A2"}
      />
    </g>
    <defs>
      <clipPath id="clip0_222_917">
        <rect
          width="8.57143"
          height="6"
          fill="white"
          transform="matrix(-1 0 0 -1 9 6)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Down = ({ fill }: IIconProps.Iprops) => (
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_301_364)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28058 1.01419C7.48998 0.804789 7.82948 0.804789 8.03889 1.01419C8.24829 1.2236 8.24829 1.5631 8.03889 1.7725L4.66403 5.14737C4.45482 5.35657 4.11563 5.35657 3.90642 5.14737L0.531564 1.7725C0.322161 1.5631 0.322161 1.2236 0.531564 1.01419C0.740966 0.804789 1.08048 0.804789 1.28988 1.01419L4.28523 4.00954L7.28058 1.01419Z"
        fill={fill || "#8792A2"}
      />
    </g>
    <defs>
      <clipPath id="clip0_301_364">
        <rect width="8.57143" height="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Arrow = { Up, Down };
