import { MultiSelect } from "react-multi-select-component";
import { Field, FieldProps } from "formik";

import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { DropdownWithCheckboxContainer } from "./dropdownWithCheckboxStyle";
import { useState } from "react";

export const DropdownWithCheckbox = (
  props: FormFieldsProps.DropdownWithCheckbox
) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    allowSearch = false,
    allowSelectAll = false,
    options,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  const [isFocused, setFocused] = useState(false);

  return (
    <Field id={`${idPrefix}_${fieldName}`} name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const { setFieldValue, setFieldTouched } = form;
        const { touched, value, error } = meta;
        const hasError =
          !isFocused && isRequired && touched && (!value || value.length === 0);
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <DropdownWithCheckboxContainer Error={hasError} disabled={disabled}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <MultiSelect
              value={value || []}
              onChange={(selectedItems: any[]) =>
                setFieldValue(fieldName, selectedItems)
              }
              labelledBy="Select"
              options={options}
              disableSearch={!allowSearch}
              disabled={disabled}
              hasSelectAll={allowSelectAll}
              onMenuToggle={(isOpen: any) => {
                if (isOpen) setFocused(true);
                if (!isOpen) {
                  setFieldTouched(fieldName, true);
                  setFocused(false);
                }
              }}
              className="multi-select"
            />
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </DropdownWithCheckboxContainer>
        );
      }}
    </Field>
  );
};
