import "./createJob.css";
import { TypographyLink } from "src/components/Basic";
import { JobProps, actions, useAppDispatch, useAppSelector } from "src/Redux";
import { CreateJobStyles } from "./Styles";
import { ICONS } from "src/assets/custom_Icons";
import { theme } from "src/components/Theme";
import { useContext } from "react";
import { AppContext } from "src/App";

export const BackButton = () => {
  const dispatch = useAppDispatch();
  const { job, common } = useAppSelector((store) => store);
  const { stepNumber, isBoostedSelected } = job;
  const { isDarkTheme } = common;
  const { setBoostedSelected, setStepNumber } = actions.job;

  const { isTabletOrBig } = useContext(AppContext);

  const backClickHandler = () => {
    if (stepNumber === 2 && isBoostedSelected)
      dispatch(setBoostedSelected(false));
    else dispatch(setStepNumber((stepNumber - 1) as JobProps.stepNumber));
  };

  return stepNumber > 1 ? (
    <CreateJobStyles.BackButton onClick={backClickHandler}>
      <ICONS.BackArrowIcon
        fill={isDarkTheme ? theme.colors.black40 : theme.colors.black100}
      />
      {isTabletOrBig && (
        <TypographyLink
          styleName="jobCheckoutDropdown"
          color={isDarkTheme ? "black40" : "black100"}
          content="back"
          underLineNeeded={false}
        />
      )}
    </CreateJobStyles.BackButton>
  ) : (
    <></>
  );
};
