import { useContext } from "react";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import { CheckoutStyles } from "../Styles";
import { AppContext } from "src/App";
import { CheckoutSummaryContext } from ".";

export const Heading = () => {
  const { isTabletOrBig } = useContext(AppContext);
  const { showModal, setShowModal } = useContext(CheckoutSummaryContext);

  return (
    <CheckoutStyles.TopContainer>
      {!isTabletOrBig && (
        <div onClick={() => setShowModal((prev: boolean) => !prev)}>
          <Typography
            styleName="jobCheckoutDropdown"
            content={showModal ? "Close" : "Details"}
            color="text_grey"
          />
          <span style={{ paddingLeft: "8px" }}>
            {showModal ? <ICONS.Arrow.Up /> : <ICONS.Arrow.Down />}
          </span>
        </div>
      )}
    </CheckoutStyles.TopContainer>
  );
};
