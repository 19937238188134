import { createContext, useContext, useEffect, useState } from "react";
import { Formik } from "formik";

import { JobProps, actions, useAppDispatch, useAppSelector } from "src/Redux";
import { Buttons, Typography } from "src/components/Basic";
import {
  FormFieldCommon,
  formFieldsHelpers,
  FormikHelpers,
} from "src/components/Basic/FormFields";
import { API_CALLS } from "src/API_CALLS";
import { CreateJobStyles } from "../../Styles";
import { BoostedCampaignUtils } from "./Utils";
import { BoostedCampaignStyles } from "./Styles";
import { BoostedCampaignContent } from "./BoostedCampaignContent";
import { BoostedCampaignProps } from "./BoostedCampaign";
import { CreateJobContext } from "../..";

export const getBidType = (bidValue: string, chartData: JobProps.chartData) => {
  const value = parseFloat(bidValue);
  let bidType: JobProps.BidType = "other";

  //recommended
  const { recommended_range, low_range } = chartData;
  const recommendedStart = parseFloat(recommended_range?.start_value);
  const recommendedEnd = parseFloat(recommended_range?.end_value);
  if (value >= recommendedStart && value <= recommendedEnd)
    bidType = "recommended";

  //low
  const lowStart = parseFloat(low_range?.start_value);
  const lowEnd = parseFloat(low_range?.end_value);
  if (value >= lowStart && value <= lowEnd) bidType = "low";

  return bidType;
};

export const BoostedCampaignContext = createContext(
  {} as BoostedCampaignProps.Context
);

export const BoostedCampaign = () => {
  const dispatch = useAppDispatch();
  const { common, job } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { CreateNovaJobForm, boostedCampaignForm } = job;
  const {
    campaign_duration_from,
    campaign_duration_to,
    campaign_input,
    total_campaign_budget,
  } = boostedCampaignForm;
  let { chartData } = job;

  const { setBoostedCampaignFormValid } = useContext(CreateJobContext);

  const [bidValue, setBidValue] = useState(campaign_input);
  const [isBidEditable, setBidEditable] = useState(false);
  const [isAdvertiseClicked, setAdvertiseClicked] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  //#region DATA
  const isChartDataExists = chartData?.axis_pointer_value ? true : false;
  if (!isChartDataExists) chartData = BoostedCampaignUtils.chartData;
  const currency = CreateNovaJobForm.base_currency?.value;
  const { bid_start, bid_end, bid_stepper } = chartData;
  const bidMin = parseFloat(parseFloat(bid_start || "0").toFixed(2));
  const bidMax = Math.ceil(parseFloat(bid_end || "0"));
  const step = parseFloat(bid_stepper || "0");
  const bidType = getBidType(bidValue, chartData);
  const { formFields, idPrefix, validationSchema } = BoostedCampaignUtils;
  const initialValues: BoostedCampaignProps.Form = {
    campaign_duration_from: new Date(parseInt(campaign_duration_from)),
    campaign_duration_to: new Date(parseInt(campaign_duration_to)),
    campaign_input,
    total_campaign_budget,
  };
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (isChartDataExists) return;

    const { job_title, city } = CreateNovaJobForm;
    const data: JobProps.getChartDataAPI = {
      job_title,
      city_id: parseInt(city?.value),
    };
    API_CALLS.JOBS.getChartData(data);
  }, [isChartDataExists, CreateNovaJobForm]);

  useEffect(() => {
    if (!isChartDataExists) return;
    const newBidValue = chartData?.axis_pointer_value;
    setBidValue(newBidValue);
  }, [isChartDataExists, chartData]);
  //#endregion

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const isFormTouched = Object.keys(formik.touched).length > 0;
        if (isFormTouched) {
          setBoostedCampaignFormValid(formik.isValid);
          const _ = formik.values;

          if (formik.isValid !== isValidForm) {
            setIsValidForm(formik.isValid);
            formik.isValid &&
              dispatch(
                actions.job.setBoostedCampaignForm({
                  campaign_duration_from: `${_.campaign_duration_from?.getTime()}`,
                  campaign_duration_to: `${_.campaign_duration_to?.getTime()}`,
                  campaign_input: _.campaign_input,
                  total_campaign_budget: _.total_campaign_budget,
                })
              );
          }
        }
        const onSubmitHandler = () => {
          const {
            campaign_duration_from,
            campaign_duration_to,
            campaign_input,
            total_campaign_budget,
          } = formik.values;
          const format = formFieldsHelpers.getDateInCorrectFormat;
          const APIData: JobProps.createBoostedCampaignAPIData = {
            campaign_duration_from: format(campaign_duration_from),
            campaign_duration_to: format(campaign_duration_to),
            campaign_input,
            total_campaign_budget,
          };
          dispatch(actions.job.setCreateBoostedCampaignAPIData(APIData));
          dispatch(actions.job.setAdvertType("boosted"));
          dispatch(actions.job.setStepNumber(3));
        };

        return (
          <BoostedCampaignContext.Provider
            value={{
              currency,
              chartData,
              isBidEditable,
              setBidEditable,
              bidValue,
              setBidValue,
              formik,
              isChartDataExists,
              bidMin,
              bidMax,
              bidType,
              step,
            }}
          >
            <BoostedCampaignStyles.Container
              bidType={bidType}
              className="bid-container"
            >
              <BoostedCampaignStyles.LabelWithInfo>
                <Typography
                  content="Set up your campaign"
                  styleName="subtitle3"
                  color={isDarkTheme ? "black10" : "black_pure"}
                />
                <FormFieldCommon.Info
                  helpText={{ info: "Setup the campaign", title: "campaign" }}
                />
              </BoostedCampaignStyles.LabelWithInfo>
              <BoostedCampaignContent />
            </BoostedCampaignStyles.Container>
            <CreateJobStyles.AdvertiseButtonWrapper>
              <Buttons
                label="Advertise"
                variant="primary"
                isDisable={isAdvertiseClicked && !formik.isValid}
                handleClick={() => {
                  setAdvertiseClicked(true);
                  FormikHelpers.formikSubmitHandler({
                    formFields,
                    formik,
                    onSubmitHandler,
                    idPrefix,
                  });
                }}
              />
            </CreateJobStyles.AdvertiseButtonWrapper>
          </BoostedCampaignContext.Provider>
        );
      }}
    </Formik>
  );
};
