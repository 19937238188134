const GreaterThanIcon = () => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.862168 0.520998C0.578911 0.805945 0.419921 1.1914 0.419921 1.59319C0.419921 1.99497 0.578912 2.38043 0.862168 2.66537L6.24592 8.12516L0.862169 13.5089C0.578912 13.7939 0.419922 14.1793 0.419922 14.5811C0.419922 14.9829 0.578912 15.3683 0.862169 15.6533C1.00355 15.7958 1.17176 15.909 1.35708 15.9862C1.54241 16.0634 1.74119 16.1031 1.94196 16.1031C2.14273 16.1031 2.34151 16.0634 2.52684 15.9862C2.71217 15.909 2.88037 15.7958 3.02175 15.6533L9.47009 9.20496C9.61263 9.06357 9.72577 8.89537 9.80298 8.71004C9.88019 8.52471 9.91995 8.32593 9.91995 8.12516C9.91994 7.9244 9.88019 7.72561 9.80298 7.54029C9.72577 7.35496 9.61263 7.18675 9.47009 7.04537L3.02175 0.520998C2.88037 0.378452 2.71216 0.265311 2.52684 0.188101C2.34151 0.11089 2.14273 0.0711363 1.94196 0.0711363C1.74119 0.0711363 1.54241 0.11089 1.35708 0.188101C1.17175 0.265311 1.00355 0.378452 0.862168 0.520998Z"
        fill="#E2E2E2"
      />
    </svg>
  );
};

export default GreaterThanIcon;
