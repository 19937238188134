import { IIconProps } from "./customIcons";

export const StripeLogo = ({ fill }: IIconProps.Iprops) => {
  return (
    <svg
      width="30"
      height="12"
      viewBox="0 0 30 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_67_41532)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0397 6.2001C30.0397 4.14938 29.005 2.53125 27.0273 2.53125C25.0414 2.53125 23.8398 4.14938 23.8398 6.18408C23.8398 8.59524 25.2583 9.81282 27.2943 9.81282C28.2873 9.81282 29.0383 9.59652 29.6057 9.29214V7.69002C29.0383 7.96242 28.3875 8.1306 27.5614 8.1306C26.752 8.1306 26.0343 7.85826 25.9426 6.91302H30.023C30.023 6.80886 30.0397 6.39234 30.0397 6.2001ZM25.9175 5.43907C25.9175 4.53388 26.4933 4.15739 27.019 4.15739C27.528 4.15739 28.0704 4.53388 28.0704 5.43907H25.9175Z"
          fill={fill || "#8792A2"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6188 2.53125C19.801 2.53125 19.2753 2.89973 18.9833 3.15607L18.8748 2.65942H17.0391V11.9997L19.1251 11.5751L19.1335 9.30816C19.4339 9.51642 19.8761 9.81282 20.6104 9.81282C22.1041 9.81282 23.4642 8.65932 23.4642 6.12C23.4558 3.79691 22.079 2.53125 20.6188 2.53125ZM20.1181 8.0505C19.6258 8.0505 19.3338 7.88226 19.1335 7.674L19.1251 4.7021C19.3421 4.4698 19.6425 4.30959 20.1181 4.30959C20.8774 4.30959 21.4031 5.12666 21.4031 6.17604C21.4031 7.24944 20.8858 8.0505 20.1181 8.0505Z"
          fill={fill || "#8792A2"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1689 2.05871L16.2634 1.62614V0L14.1689 0.424559V2.05871Z"
          fill={fill || "#8792A2"}
        />
        <path
          d="M16.2634 2.66699H14.1689V9.67619H16.2634V2.66699Z"
          fill={fill || "#8792A2"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9242 3.26006L11.7907 2.66727H9.98828V9.67652H12.0743V4.92624C12.5667 4.30943 13.4011 4.42158 13.6598 4.5097V2.66727C13.3927 2.57115 12.4165 2.39492 11.9242 3.26006Z"
          fill={fill || "#8792A2"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7524 0.929199L5.71635 1.34575L5.70801 7.76216C5.70801 8.94776 6.63421 9.82088 7.86921 9.82088C8.5534 9.82088 9.05408 9.70076 9.32946 9.55658V7.9304C9.06246 8.03456 7.74402 8.40302 7.74402 7.21748V4.37373H9.32946V2.66749H7.74402L7.7524 0.929199Z"
          fill={fill || "#8792A2"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.11111 4.70211C2.11111 4.3897 2.37813 4.26954 2.82037 4.26954C3.45454 4.26954 4.2556 4.45378 4.88977 4.78221V2.89973C4.19719 2.63539 3.51296 2.53125 2.82037 2.53125C1.12648 2.53125 0 3.38037 0 4.79824C0 7.00914 3.17084 6.6567 3.17084 7.60992C3.17084 7.97844 2.83707 8.09856 2.36979 8.09856C1.67721 8.09856 0.792713 7.82622 0.0917875 7.45776V9.36426C0.867806 9.68466 1.65218 9.82086 2.36979 9.82086C4.1054 9.82086 5.29864 8.99574 5.29864 7.56186C5.29029 5.17473 2.11111 5.59929 2.11111 4.70211Z"
          fill={fill || "#8792A2"}
        />
      </g>
      <defs>
        <clipPath id="clip0_67_41532">
          <rect width="30" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
