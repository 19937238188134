import { FormFieldsProps } from "src/components/Basic/FormFields";

export const idPrefix = "my-profile";

const first_name: FormFieldsProps.Input = {
  placeholder: "First Name",
  label: "First Name",
  fieldName: "first_name",
  idPrefix,
  isRequired: true,
};
const last_name: FormFieldsProps.Input = {
  fieldName: "last_name",
  idPrefix,
  isRequired: true,
  placeholder: "Last Name",
  label: "Last Name",
};
const email: FormFieldsProps.Input = {
  placeholder: "Enter your email",
  label: "Email",
  fieldName: "email",
  idPrefix,
  isRequired: true,
  inputType: "email",
  disabled: true,
};
const current_password: FormFieldsProps.Password = {
  placeholder: "Current Password",
  label: "Current Password",
  fieldName: "current_password",
  idPrefix,
  isRequired: true,
};
const new_password: FormFieldsProps.Password = {
  placeholder: "Confirm New Password",
  label: "Confirm New Password",
  fieldName: "new_password",
  idPrefix,
  isRequired: true,
};

export const myProfileDetailFormFields = {
  first_name,
  last_name,
  email,
};
export const myProfilePasswordFormFields = {
  current_password,
  new_password,
};
