import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { API_INSTANCES } from "src/API_INSTANCES";
import store from "src/Redux";
import { settingsActions } from "src/Redux/slices/Settings";
import { IEmailNotification } from "src/components/Settings/EmailNotification";

const dispatch = store.dispatch;

const getEmailNotificationSettings = () => {
  API_INSTANCES.EMAIL_NOTIFICATION.getEmailNotificationSettings()
    .then((res) => dispatch(settingsActions.setEmailNotification(res.data)))
    .catch((error) => console.log(error));
};
const updateEmailNotificationSettings = (
  body: IEmailNotification,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  toast.promise(
    API_INSTANCES.EMAIL_NOTIFICATION.updateEmailNotificationSettings(body),
    {
      pending: "Promise is pending",
      success: {
        render({ data }) {
          dispatch(settingsActions.setEmailNotification(data?.data));
          setAction(false);
          return "Promise resolved 👌";
        },
      },
      error: {
        render({data}:any) {
          setAction(false);
          return  `${data.message}🤯`;
        },
      },
    }
  );
  // API_INSTANCES.EMAIL_NOTIFICATION.updateEmailNotificationSettings(body)
  //   .then((res) => {
  //     dispatch(settingsActions.setEmailNotification(res.data));
  //     setAction(false);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setAction(false);
  //   });
};

export const EMAIL_NOTIFICATION = {
  getEmailNotificationSettings,
  updateEmailNotificationSettings,
};
