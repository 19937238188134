import { useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";

import { Buttons, FormFields, Typography } from "src/components/Basic";
import {
  EmailNotificationContainer,
  HeadingContainer,
  NotificationDetailContainer,
  NotificationDetail,
  ButtonContainer,
} from "./style";
import { emailNotificationFields, idPrefix } from "./utils";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";
import { formikSubmitHandler } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";

export type IEmailNotification = {
  daily_applications: boolean;
  monthly_account: boolean;
  weekly_account: boolean;
  weekly_applications: boolean;
};

const EmailNotification = () => {
  const { emailNotification } = useAppSelector((state) => state.settings);
  const [isUpdateInfoButtonLoading, setIsUpdateInfoButtonLoading] =
    useState(false);

  useEffect(() => {
    API_CALLS.EMAIL_NOTIFICATION.getEmailNotificationSettings();
  }, []);

  const onSubmitHandler = (value: FormikValues) => {
    API_CALLS.EMAIL_NOTIFICATION.updateEmailNotificationSettings({
      ...value,
    } as IEmailNotification,setIsUpdateInfoButtonLoading);
  };

  return (
    <EmailNotificationContainer>
      <HeadingContainer>
        <Typography
          styleName="candidateNameEmailOpen"
          content="EMAIL NOTIFICATIONS"
        />
        <Typography
          styleName="text1"
          content="Turn your notifications on or off."
        />
      </HeadingContainer>
      <Formik
        enableReinitialize
        initialValues={{
          ...emailNotification,
        }}
        onSubmit={() => {}}
      >
        {(formik) => (
          <>
            <NotificationDetailContainer>
              <NotificationDetail>
                <div className="d-flex justify-content-between">
                  <Typography
                    styleName="subtitle3"
                    content="Daily Applications Summary"
                  />
                  <FormFields.Switch
                    {...emailNotificationFields.daily_applications}
                  />
                </div>
                <div className="notification-detail">
                  <Typography
                    styleName="text_12pt"
                    content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                           Choose Template. This is where we talk talk talk. Choose Template. This is where we talk."
                    color="black70"
                  />
                </div>
              </NotificationDetail>
              <NotificationDetail>
                <div className="d-flex justify-content-between">
                  <Typography
                    styleName="subtitle3"
                    content="Weekly Applications Summary"
                  />
                  <FormFields.Switch
                    {...emailNotificationFields.weekly_applications}
                  />
                </div>
                <div className="notification-detail">
                  <Typography
                    styleName="text_12pt"
                    content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk. This is where we talk talk talk."
                    color="black70"
                  />
                </div>
              </NotificationDetail>
              <NotificationDetail>
                <div className="d-flex justify-content-between">
                  <Typography styleName="subtitle3" content="Weekly Account" />
                  <FormFields.Switch
                    {...emailNotificationFields.weekly_account}
                  />
                </div>
                <div className="notification-detail">
                  <Typography
                    styleName="text_12pt"
                    content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk."
                    color="black70"
                  />
                </div>
              </NotificationDetail>
              <NotificationDetail>
                <div className="d-flex justify-content-between">
                  <Typography styleName="subtitle3" content="Monthly Account" />
                  <FormFields.Switch
                    {...emailNotificationFields.monthly_account}
                  />
                </div>
                <div className="notification-detail">
                  <Typography
                    styleName="text_12pt"
                    content="Choose Template. Choose Template. Choose Template. Choose template and add information.
                  Choose Template. This is where we talk talk talk. Choose Template. This is where we talk talk talk. Talk talk talk talk talk."
                    color="black70"
                  />
                </div>
              </NotificationDetail>
            </NotificationDetailContainer>
            <ButtonContainer>
              <Buttons
                handleClick={() => {
                  formikSubmitHandler({
                    formFields: emailNotificationFields,
                    formik,
                    idPrefix,
                    onSubmitHandler,
                  });
                }}
                label="Update Info"
                variant="primary"
                isDisable={isUpdateInfoButtonLoading}
              />
            </ButtonContainer>
          </>
        )}
      </Formik>
    </EmailNotificationContainer>
  );
};

export default EmailNotification;
