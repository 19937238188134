import { Dispatch, SetStateAction } from "react";
import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { PayLoadCompanyProfileDetail } from "src/components/Settings/CompanyProfileSettings/CompanyProfileSettings";
const dispatch = store.dispatch;

const getCompanyDetails = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyDetails()
    .then((res) => dispatch(actions.settings.setCompanyProfileDetail(res.data)))
    .catch((error) => console.log(error));
};

const updateCompanyDetails = (
  values: PayLoadCompanyProfileDetail,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  API_INSTANCES.COMPANY_SETTINGS.updateCompanyDetails(values)
    .then((res) => {
      dispatch(actions.settings.setCompanyProfileDetail(res.data));
      setAction(false);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
    });
};

const getCompanyLogo = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyLogo()
    .then((result) => {
      dispatch(actions.settings.setCompanyImage(result.data.company_image));
    })
    .catch((error) => console.log(error));
};

const uploadCompanyLogo = (values: File) => {
  API_INSTANCES.COMPANY_SETTINGS.uploadCompanyLogo(values)
    .then((result) =>
      dispatch(actions.settings.setCompanyImage(result.data.company_image))
    )
    .catch((error) => console.log(error));
};

const getCompanyCover = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyCover()
    .then((result) =>
      dispatch(actions.settings.setCoverImage(result.data.cover_image))
    )
    .catch((error) => console.log(error));
};

const uploadCompanyCover = (values: File) => {
  API_INSTANCES.COMPANY_SETTINGS.uploadCompanyCover(values)
    .then((result) =>
      dispatch(actions.settings.setCoverImage(result.data.cover_image))
    )
    .catch((error) => console.log(error));
};

const getCompanyGallery = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyGallery()
    .then((result) =>
      dispatch(actions.settings.setCompanyGallery(result.data.images))
    )
    .catch((error) => console.log(error));
};

const uploadCompanyGallery = (
  values: File,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  API_INSTANCES.COMPANY_SETTINGS.uploadCompanyGallery(values)
    .then((result) => {
      dispatch(actions.settings.setCompanyGallery(result.data.images));
      setAction(false);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
    });
};

const deleteCompanyGallery = (
  id: number,
  setAction: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  API_INSTANCES.COMPANY_SETTINGS.deleteCompanyGallery(id)
    .then((result) => {
      dispatch(actions.settings.setCompanyGallery(result.data.images));
      setAction(false);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
    });
};
export const COMPANY_SETTINGS = {
  getCompanyDetails,
  updateCompanyDetails,
  uploadCompanyLogo,
  getCompanyLogo,
  uploadCompanyCover,
  getCompanyCover,
  uploadCompanyGallery,
  getCompanyGallery,
  deleteCompanyGallery,
};
