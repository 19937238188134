import styled from "styled-components";

import { FIELDS, FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles/common";

export const DatepickerContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isFocused: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL;
  }};
  .reactdate-pic {
    ${({ disabled }) => disabled && FIELDS.DISABLED_FORM_CONTROL};
    border: 1px solid
      ${({ Error }) => {
        return Error
          ? `${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important`
          : FIELD_COLORS.FIELD_BORDER_COLOR;
      }};
    border-radius: 5px;
    background: ${FIELD_COLORS.FIELD_BG_COLOR};
  }
  .reactdate-pic:hover {
    ${({ Error }) => (Error ? FIELD_CSS.ERROR_HOVER : FIELD_CSS.HOVER)};
  }
  .reactdate-pic:active {
    ${FIELD_CSS.HOVER};
  }
  .reactdate-pic:focus {
    ${FIELD_CSS.HOVER};
  }
  .reactdate-pic:focus::placeholder {
    color: transparent;
  }

  .form-control {
    border-radius: 0px 5px 5px 0;
    border: 0px solid !important;
  }
  .form-control:hover {
    border: 0px solid !important;
  }
  .form-control:focus {
    border: 0px solid !important;
  }
  .form-control:active {
    border: 0px solid !important;
  }

  .reactdate-pic input:hover {
    box-shadow: none !important;
    border: 0px solid;
  }
  .reactdate-pic input:focus {
    box-shadow: none !important;
    border: 0px solid;
  }
  .reactdate-pic input:active {
    box-shadow: none !important;
    border: 0px solid;
  }

  .input-field-icon {
    border: 0px solid;
    border-radius: 4px 0 0 4px;
  }

  .react-datepicker-wrapper {
    width: calc(100% - 46px) !important;
  }
  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.bigSquare_and_radio};
    border: 0px solid;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primary_default};
  }
  .react-datepicker__current-month {
    color: white;
  }
  .react-datepicker__day-name {
    color: white;
    ${({ theme }) => theme.text.date_picker_day};
  }
  .react-datepicker__navigation-icon::before {
    border-color: white;
    border: 0;
  }
  .react-datepicker__day {
    ${({ theme }) => theme.text.text_11pt};
    color: ${({ theme }) => theme.colors.black90};
    height: 27px;
    width: 27px;
    padding-top: 4px;
  }
  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.primary_default};
  }

  .date-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 12px;
  }
  .date-picker-header-button {
    background-color: transparent;
    border: 0px;
  }
  .date-picker-icon {
    color: white;
  }
  .date-picker-header-title {
    ${({ theme }) => theme.text.date_picker_title};
  }
`;
