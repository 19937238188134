import { useEffect } from "react";
import { ReceiptsContainer } from "./ReceiptsStyle";
import { Typography } from "src/components/Basic";
import ReceiptsTable from "./ReceiptsTable";
import { API_CALLS } from "src/API_CALLS";

const Receipts = () => {
  useEffect(() => {
    API_CALLS.RECEIPTS.getReceipts();
  }, []);
  return (
    <ReceiptsContainer>
      <div className="d-flex gap-3 flex-column mb-3">
        <Typography
          content="Receipts"
          color="black80"
          styleName="tableButton"
        />
        <Typography
          content="Have a look at your receipts below."
          color="black80"
          styleName="text1"
        />
      </div>
      <ReceiptsTable />
    </ReceiptsContainer>
  );
};

export default Receipts;
