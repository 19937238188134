import { createSlice } from "@reduxjs/toolkit";
import { IDashboard } from "src/components/Dashboard/Dashboard";

const initialState: IDashboard.Dashboard = {
  user_name: "",
  active_jobs: {
    active_jobs: 0,
    last_week: 0,
  },
  unread_candidates: {
    unread: 0,
    last_week: 0,
  },
  views: {
    views: 0,
    last_week: 0,
  },
  applications: {
    applications: 0,
    last_week: 0,
  },
  candidate_applications: {
    daily_average: 0,
    this_month: 0,
    growth: {
      series: [
        {
          name: "",
          data: [],
        },
      ],
      xaxis: {
        type: "",
        categories: [],
      },
      yaxis: {
        min: 0,
        max: 0,
      },
    },
  },
  hiring_progress: {
    inbox: {
      count: 0,
      percentage: 0,
    },
    screening: {
      count: 0,
      percentage: 0,
    },
    shortlist: {
      count: 0,
      percentage: 0,
    },
    interview: {
      count: 0,
      percentage: 0,
    },
    offer_made: {
      count: 0,
      percentage: 0,
    },
    onboarding: {
      count: 0,
      percentage: 0,
    },
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData: (_state, action) => {
      return { ...action.payload };
    },
  },
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
