import { Field, FieldProps } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FormFieldsProps } from "../../FormFields";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { InputGroup } from "react-bootstrap";
import { FieldIcons } from "../../Icons";
import { DatepickerContainer } from "./datePickerStyles";
import { useState } from "react";
import { DatePickerIcons } from "../../Icons/DatePicker";

export const getDateInCorrectFormat = (date: Date | null) =>
  date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : "";

const DatePickerElement = (props: FormFieldsProps.Datepicker) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder,
    showLabel = true,
    minDate = null,
    onChangeHandler,
  } = props;

  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form, field }: FieldProps) => {
        const { touched, value, error } = meta;
        const { setFieldValue, setFieldTouched } = form;
        const { onBlur } = field;

        const errorConditions = isRequired && !isFocused && touched;
        const hasError = errorConditions && ((error ? true : false) || !value);
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <DatepickerContainer
            disabled={disabled}
            Error={hasError}
            isFocused={isFocused}
          >
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <InputGroup className="input-group reactdate-pic ">
              <InputGroup.Text className="input-field-icon">
                <FieldIcons.CalendarIcon />
              </InputGroup.Text>
              <DatePicker
                id={`${idPrefix}_${fieldName}`}
                className="form-control"
                selected={value}
                onChange={(date: Date) => {
                  setFieldValue(fieldName, date);
                  onChangeHandler && onChangeHandler(date);
                }}
                dateFormat={"dd/MM/yyyy"}
                placeholderText={placeholder}
                renderCustomHeader={(props) => {
                  const month = props.date.toLocaleString("default", {
                    month: "long",
                  });
                  const year = props.date.getFullYear();
                  return (
                    <div className="date-picker-header">
                      <div>
                        <button
                          onClick={props.decreaseYear}
                          className="date-picker-header-button"
                        >
                          <DatePickerIcons.PrevYear />
                        </button>
                        <button
                          onClick={props.decreaseMonth}
                          className="date-picker-header-button"
                        >
                          <DatePickerIcons.PrevMonth />
                        </button>
                      </div>

                      <div className="date-picker-header-title">
                        {`${month.slice(0, 3)} ${year}`}
                      </div>

                      <div>
                        <button
                          onClick={props.increaseMonth}
                          className="date-picker-header-button"
                        >
                          <DatePickerIcons.NextMonth />
                        </button>
                        <button
                          onClick={props.increaseYear}
                          className="date-picker-header-button"
                        >
                          <DatePickerIcons.NextYear />
                        </button>
                      </div>
                    </div>
                  );
                }}
                onFocus={() => {
                  setFocused(true);
                  setFieldTouched(fieldName, true);
                }}
                onBlur={(e) => {
                  setFocused(false);
                  onBlur(e);
                }}
                minDate={minDate}
              />
            </InputGroup>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </DatepickerContainer>
        );
      }}
    </Field>
  );
};

export { DatePickerElement };
