import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Buttons, Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import {
  AddCommentContainer,
  ButtonContainer,
  CommentsContainer,
  Container,
  HeadingContainer,
  IconContainer,
  TimelineContainer,
  Wrapper,
} from "./CommentsStyle";
import { FormFieldsProps } from "src/components/Basic/FormFields/FormFields";
import { Formik } from "formik";
import { FormFields } from "src/components/Basic/FormFields/FormikFields";
import clsx from "clsx";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_INSTANCES } from "src/API_INSTANCES";
import * as Yup from "yup";
const Comments = () => {
  const { comment, selectedCandidate } = useAppSelector(
    (state) => state.candidate
  );
  const dispatch = useAppDispatch();
  const [isAddCommentOpen, setIsAddCommentOpen] =
    React.useState<boolean>(false);
  const richTextAreaProps: FormFieldsProps.RichTextarea = {
    fieldName: "comment",
    idPrefix: "comment",
    isRequired: true,
    placeholder: "Text Area",
  };
  React.useEffect(() => {
    if (comment.length === 0) {
      API_INSTANCES.PIPELINE.getCandidateComment(
        selectedCandidate.application_id
      )
        .then((res) =>
          dispatch(
            actions.candidate.setComments(res.data.comment_items[0].comments)
          )
        )
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <CommentsContainer>
      <HeadingContainer>
        <Typography
          content="COMMENTS"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      <ButtonContainer>
        <Buttons
          isDisable={isAddCommentOpen}
          handleClick={() => {
            setIsAddCommentOpen(true);
          }}
          label="Add comment"
          variant="success"
        />
        <div
          className={clsx(isAddCommentOpen && "opacity-50")}
          onClick={() => {
            setIsAddCommentOpen(true);
          }}
        >
          <ICONS.PlusCircleIcon />
        </div>
      </ButtonContainer>
      {isAddCommentOpen && (
        <AddCommentContainer isAddCommentOpen={isAddCommentOpen}>
          <Formik
            initialValues={{ comment: "" }}
            validationSchema={Yup.object({ comment: Yup.string().required() })}
            onSubmit={(value) => {
              API_INSTANCES.PIPELINE.postCandidateComment({
                application_id: Number(selectedCandidate.application_id),
                comment: value.comment,
                user_id: "78e8ca0c-8294-4c9a-bc0a-c7dbf758dc80",
              })
                .then((res) =>
                  dispatch(
                    actions.candidate.setComments(
                      res.data.comment_items[0].comments
                    )
                  )
                )
                .catch((err) => console.log(err));
              setIsAddCommentOpen(false);
            }}
          >
            {(formik) => {
              return (
                <>
                  <FormFields.RichTextArea {...richTextAreaProps} />
                  <div className="mt-3 d-flex gap-2 justify-content-end">
                    <Buttons
                      handleClick={() => {
                        setIsAddCommentOpen(false);
                      }}
                      label="Cancel"
                      variant="light"
                    />
                    <Buttons
                      handleClick={formik.submitForm}
                      label="Add"
                      variant="primary"
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        </AddCommentContainer>
      )}
      {comment?.length > 0 ? (
        <TimelineContainer className="vtimeline">
          {comment?.map((comment, index) => (
            <div
              className="timeline-wrapper timeline-inverted timeline-wrapper-primary"
              key={index}
            >
              <div className="timeline-badge ">
                <img
                  className="timeline-image"
                  alt=""
                  src={`data:image/png;base64,${comment.user_profile_picture}`}
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Wrapper className="timeline-title">
                    <img
                      className="timeline-image"
                      alt=""
                      src={`data:image/png;base64,${comment.user_profile_picture}`}
                    />
                    <div>
                      {comment.user_first_name + " " + comment.user_last_name}
                    </div>
                  </Wrapper>
                </div>
                <div className="timeline-body">
                  <div dangerouslySetInnerHTML={{ __html: comment.comment }} />
                </div>
                <div className="timeline-footer text-muted d-flex align-items-center justify-content-end ">
                  <div className="d-flex gap-3 align-items-center">
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="ms-auto">{comment.date_created}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </TimelineContainer>
      ) : (
        <Container>
          <HeadingContainer className="no-data-msg">
            <Typography
              content="No  comments added.."
              styleName="text1"
              color="black80"
            />
          </HeadingContainer>
          <IconContainer>
            <ICONS.MessageIcon />
          </IconContainer>
        </Container>
      )}
    </CommentsContainer>
  );
};

export default Comments;
