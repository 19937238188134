const MailIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 2.33325H2.33329C1.69163 2.33325 1.16663 2.85825 1.16663 3.49992V10.4999C1.16663 11.1416 1.69163 11.6666 2.33329 11.6666H11.6666C12.3083 11.6666 12.8333 11.1416 12.8333 10.4999V3.49992C12.8333 2.85825 12.3083 2.33325 11.6666 2.33325Z"
        stroke="#1E96F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8333 3.5L6.99996 7.58333L1.16663 3.5"
        stroke="#1E96F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
