import React, { useMemo } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableState,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import {
  Buttons,
  FormFields,
  IconButton,
  TableButton,
  Typography,
} from "src/components/Basic";
import { CustomTable, TableProps } from "src/components/Basic/Table/Table";
import style from "../../Basic/Table/Table.module.css";
import {
  ButtonContainer,
  PaginationButton,
  TableFilterHeader,
  PageContainer,
  Table,
  ModalButtonContainer,
  ModalHeader,
  ModalContainer,
  MainPageContainer,
} from "./JobStyle";
import {
  DropDownOption,
  customColumnCell,
  pageJumperOptions,
  tableColumns,
  tableMobileColumns,
} from "./utils";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Grid } from "antd";
import { colors } from "../../Theme/Colors";
import { API_INSTANCES } from "src/API_INSTANCES";
import {CustomModal} from "../../Basic/CustomModal";
import { ROUTES } from "src/utils/ROUTES";
import { useNavigate } from "react-router";
import { customRowCell } from "./CustomColumnCell";

const Job = () => {
  const [filterType, setFilterType] =
    React.useState<keyof typeof DropDownOption>("active");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { jobs } = useAppSelector((state) => state.activeJob);
  const dispatch = useAppDispatch();
  const updateRowData = useMemo(() => {
    API_INSTANCES.JOB.getJobOverview(DropDownOption[filterType]).then((res) =>
      dispatch(actions.activeJob.getJobs(res.data.jobs))
    );
  }, [filterType]);
  const screen = Grid.useBreakpoint();
  const navigate = useNavigate();
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    pageCount,
    pageSize,
    setGlobalFilter,
    state,
  }: CustomTable.TableOptions<TableProps.Data> = useTable(
    {
      columns: screen.lg ? tableColumns : tableMobileColumns,
      data: jobs,
      initialState: { pageSize: pageJumperOptions[3].value } as Partial<
        TableState<Column>
      >,
    },
    useGlobalFilter,
    usePagination
  );
  const onChangeHandler = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  const { globalFilter } = state;
  const [value] = React.useState(globalFilter);

  React.useEffect(() => {
    setGlobalFilter(value || undefined);
  }, [updateRowData]);

  return (
    <MainPageContainer>
      <Typography content="JOBS" styleName="heading5" color="primary_default" />
      <PageContainer>
        <div>
          <div className="d-flex justify-content-between">
            <ButtonContainer>
              <TableButton
                label="Active Jobs"
                isActive={filterType === "active"}
                handleClick={() => setFilterType("active")}
              ></TableButton>
              <TableButton
                label="Drafts"
                isActive={filterType === "drafts"}
                handleClick={() => setFilterType("drafts")}
              ></TableButton>
              <TableButton
                label="Archived"
                isActive={filterType === "archived"}
                handleClick={() => setFilterType("archived")}
              ></TableButton>
            </ButtonContainer>
            <TableFilterHeader>
              <FormFields.SearchField
                fieldName="search"
                idPrefix="table_search_input_field"
                onChangeHandler={onChangeHandler}
                value={value}
                isRequired={false}
              />
              {screen.lg ? (
                <FormFields.DropdownField
                  fieldName="tablePageNumber"
                  isRequired={false}
                  defaultValue={pageJumperOptions[3]}
                  idPrefix="table_page_jumper_filed"
                  onChangeHandler={(selectedElement) => {
                    setPageSize(selectedElement?.value);
                  }}
                  value={pageSize}
                  options={pageJumperOptions}
                />
              ) : (
                <Dropdown
                  onSelect={(value) =>
                    setFilterType(value as keyof typeof DropDownOption)
                  }
                >
                  <Dropdown.Toggle
                    variant=""
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn "
                    data-bs-toggle="dropdown"
                    type="button"
                    style={{
                      background: colors.black90,
                      color: colors.white_pure,
                      textTransform: "capitalize",
                    }}
                  >
                    {filterType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ margin: "0px" }}>
                    <Dropdown.Item
                      className="dropdown-item"
                      href="#"
                      eventKey={"active"}
                    >
                      Active Jobs
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item"
                      href="#"
                      eventKey={"drafts"}
                    >
                      Drafts
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item"
                      href="#"
                      eventKey={"archived"}
                    >
                      Archived
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </TableFilterHeader>
          </div>
          <Table {...getTableProps()} className="table  mb-0 ">
            <thead>
              {headerGroups.map((headerGroup: HeaderGroup) => (
                <tr
                  className={clsx(style.table_header, "table-header")}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: ColumnInstance) => (
                    <th {...column.getHeaderProps()}>
                      {customColumnCell(column)}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className={clsx(style.table_body, "table-body")}
              {...getTableBodyProps()}
            >
              {page.map((row: Row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: Cell) => (
                      <td {...cell.getCellProps()}>
                        {customRowCell({
                          cell,
                          filterType,
                          setIsModalOpen,
                          navigate,
                        })}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-center  mt-4">
          <IconButton
            handleClick={() => navigate(ROUTES.CREATE_JOB)}
            icon={faPlus}
            position="right"
            label="Create Job"
          />
        </div>
        <div className="d-flex justify-content-end mt-4 mx-4">
          <PaginationButton
            className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
          >
            {" |< "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canPreviousPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {" < "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canNextPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {" > "}
          </PaginationButton>
          <PaginationButton
            className={clsx(canNextPage ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              gotoPage(pageCount && pageCount - 1);
            }}
            disabled={!canNextPage}
          >
            {" >| "}
          </PaginationButton>
        </div>
        <CustomModal isModalOpen={isModalOpen}>
          <ModalHeader>
            <Modal.Title>Delete Job</Modal.Title>
            <Button
              variant=""
              className="btn btn-close"
              style={{ opacity: "1" }}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              x
            </Button>
          </ModalHeader>

          <Modal.Body className="px-2">
            <ModalContainer>
              <Typography
                content="Are you sure you want to delete this job ?"
                color="blue_grey_80"
                styleName="inputGeneral"
              />
            </ModalContainer>
          </Modal.Body>
          <Modal.Footer>
            <ModalButtonContainer>
              <Buttons
                variant="secondary"
                handleClick={() => {
                  setIsModalOpen(false);
                }}
                label="Cancel"
              />
            </ModalButtonContainer>
            <ModalButtonContainer>
              <Buttons
                variant="success"
                handleClick={() => {
                  setIsModalOpen(false);
                }}
                label="Confirm"
              />
            </ModalButtonContainer>
          </Modal.Footer>
        </CustomModal>
      </PageContainer>
    </MainPageContainer>
  );
};

export default Job;
