import styled from "styled-components";
import { theme } from "src/components/Theme";

export const CandidateProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 24px 32px 20px;
  @media (max-width: 991px) {
    margin: 16px 0px 0px 16px;
    gap: 10px;
    flex-direction: column;
  }
  .candidate-detail {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      margin-top: 7px;
      gap: 8px;
    }
  }
`;
export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  svg {
    cursor: pointer;
    margin-right: 10px;
  }
  @media (max-width: 991px) {
    margin-top: 56px;
  }
`;
export const Arrow = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .dark-theme & label {
    color: ${theme.colors.grey30};
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 110px;
  label:last-child {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .Onboarding-dropdown {
    font-weight: 600;
  }
  .dropdown-menu.show {
    top: 10px !important;
    left: -17px !important;
  }
  .dark-theme & > label {
    color: ${theme.colors.black20};
  }
`;
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 12px 0px;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 24px;
`;

export const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  box-shadow: ${theme.Effects.profileShadow};
  border-radius: 5px;
  img {
    object-fit: fill;
  }
  &::after {
    top: 11px;
    right: -8px;
    width: 18px;
    height: 18px;
    border: 3px solid #ffffff;
    background: #24d5b8;
  }
  @media (max-width: 991px) {
    width: 120px;
    height: 120px;
  }
`;

export const ImgContainer = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  opacity: 0;
  cursor: move;
`;
