import { faAlignJustify, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { openCloseSidebar } from "../../../utils/theme";

const SidebarToggle = () => {
  return (
    <div
      className="app-sidebar__toggle"
      data-bs-toggle="sidebar"
      onClick={openCloseSidebar}
    >
      <Link className="open-toggle" to="#">
        <FontAwesomeIcon icon={faAlignJustify} className="header-icon-svgs" />
      </Link>
      <Link className="close-toggle" to="#">
        <FontAwesomeIcon icon={faClose} className="header-icon-svgs" />
      </Link>
    </div>
  );
};
export default SidebarToggle;
