import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";

import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { FieldIcons } from "../../Icons";
import { PasswordContainer } from "./passwordStyles";
import { REGEX } from "../../REGEX";

export const getPasswordError = (value: string) => {
  const prefix = "Password must contain at least";
  if (value.length < 8) return prefix + " 8 characters";
  if (!REGEX.LOWERCASE.test(value)) return prefix + " one lowercase letter";
  if (!REGEX.UPPERCASE.test(value)) return prefix + " one uppercase letter";
  if (!REGEX.NUMBER.test(value)) return prefix + " one number";
  if (!REGEX.SYMBOL.test(value)) return prefix + " one symbol";
  return "";
  //return "Password must contain minimum 8 characters and at least one number, uppercase letter, and symbol.";
};
export const Password = (props: FormFieldsProps.Password) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  const [type, setType] = useState<"text" | "password">("password");
  const [isFocused, setFocused] = useState(false);

  const onEyeClick = () => setType(type === "text" ? "password" : "text");
  const showPassword = type === "text" ? true : false;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta }: FieldProps) => {
        const { onChange, onBlur, value } = field;
        const { touched, error } = meta;

        const isValidPassword = REGEX.PASSWORD.test(value);

        const hasError =
          !isFocused && isRequired && touched && (!value || !isValidPassword);
        let errorLabel = error || `${label || fieldName} is required`;
        if (value && !isValidPassword) {
          errorLabel = getPasswordError(value);
        }
        const showErrorLabel = hasError && errorLabel;

        return (
          <PasswordContainer disabled={disabled} Error={hasError}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <InputGroup>
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                name={fieldName}
                type={type}
                className={`form-control`}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={(e) => {
                  onBlur(e);
                  setFocused(false);
                }}
              />
              <InputGroup.Text
                className={`eyeIcon ${hasError && "input-field-icon-error"}`}
                id={`eyeIcon `}
                onClick={onEyeClick}
              >
                {showPassword ? (
                  <FieldIcons.HidePasswordIcon />
                ) : (
                  <FieldIcons.ShowPasswordIcon />
                )}
              </InputGroup.Text>
            </InputGroup>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </PasswordContainer>
        );
      }}
    </Field>
  );
};
