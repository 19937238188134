import styled from "styled-components";
import { LayoutProps } from "./Layout";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";
import { darkmode } from "src/utils/theme";

export const Layout = ({ children }: LayoutProps.Children) => {
  const dispatch = useAppDispatch();
  const { isThemeSet } = useAppSelector((store) => store.common);
  const setThemePreference = () => {
    const theme = localStorage.getItem(LOCAL_STORAGE_KEY.THEME);
    const isDarkMode = theme === "true";
    isDarkMode && darkmode();
    dispatch(actions.common.setDarkTheme(isDarkMode));
    dispatch(actions.common.setThemeSet(true));
  };
  if (!isThemeSet) setThemePreference();

  return (
    <LayoutContainer>
      <Header />
      <Sidebar />
      {children}
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  .main-header {
    margin-bottom: 0;
  }
`;
