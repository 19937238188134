const HOME = "/";
const REGISTER = "/auth/register";
const SIGN_IN = "/auth/sign-in";
const FORGOT = "/auth/forgot";
const RESET = "/auth/reset";
const OTP = "/auth/otp";
const JOBS = "/jobs";
const CREATE_JOB = "/create-job";
const SETTINGS = "/settings";
const PIPELINE = "/pipeline/:job_id";
const TEAM_MANAGEMENT = "/settings/team/verify";

export const ROUTES = {
  HOME,
  REGISTER,
  SIGN_IN,
  FORGOT,
  RESET,
  OTP,
  JOBS,
  CREATE_JOB,
  SETTINGS,
  PIPELINE,
  TEAM_MANAGEMENT,
};
