import styled from "styled-components";
import { Form, InputGroup } from "react-bootstrap";

import {
  FIELD_COLORS,
  FIELD_CSS,
} from "src/components/Basic/FormFields/FormFieldsStyles";
import { useState } from "react";
import { useAppSelector } from "src/Redux";
import { TypographyLink } from "src/components/Basic";

type PromotionCodeInputProps = {
  label?: string;
  placeholder?: string;
  value?: any;
  onChangeHandler: (value: string) => void;
  onApplyClick: () => void;
};

export const PromotionCodeInput = (props: PromotionCodeInputProps) => {
  const {
    label,
    placeholder = label,
    value = "",
    onChangeHandler,
    onApplyClick,
  } = props;

  const { isDarkTheme } = useAppSelector((store) => store.common);
  const [isFocused, setFocused] = useState(false);

  return (
    <Container
      Error={false}
      isDarkTheme={isDarkTheme}
      isFieldActive={isFocused}
    >
      <InputGroup className="promotion-code">
        <Form.Control
          id="promo-code"
          name="promo-code"
          className={`form-control`}
          placeholder={placeholder}
          onChange={(e) => onChangeHandler(e.target.value)}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={(e) => {
            const isEnterPressed = e.code === "Enter" || e.key === "Enter";
            if (isEnterPressed) onApplyClick();
          }}
          autoFocus={true}
        />
        <InputGroup.Text
          className={`apply-button`}
          id={`apply-button`}
          onClick={onApplyClick}
        >
          <TypographyLink
            content="Apply"
            styleName="inputGeneral"
            color="primary_default"
            underLineNeeded={false}
            showUnderLineOnHover={true}
          />
        </InputGroup.Text>
      </InputGroup>
    </Container>
  );
};

type ContainerProps = {
  Error: boolean;
  isDarkTheme: boolean;
  isFieldActive: boolean;
};
const Container = styled.div<ContainerProps>`
  .promotion-code {
    ${({ isFieldActive, Error }) => isFieldActive && !Error && FIELD_CSS.HOVER};
    border: 1px solid
      ${({ Error }) => {
        return Error
          ? `${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important`
          : FIELD_COLORS.FIELD_BORDER_COLOR;
      }};
    border-radius: 5px;
    ${FIELD_CSS.TEXT};
  }
  .promotion-code:hover {
    ${({ Error }) => (Error ? FIELD_CSS.ERROR_HOVER : FIELD_CSS.HOVER)};
  }
  .promotion-code:active {
    ${FIELD_CSS.HOVER};
  }
  .promotion-code:focus {
    ${FIELD_CSS.HOVER};
  }
  .promotion-code:focus::placeholder {
    color: transparent;
  }

  .promotion-code input:hover {
    box-shadow: none !important;
  }
  .promotion-code input:focus {
    box-shadow: none !important;
  }
  .promotion-code input:active {
    box-shadow: none !important;
  }

  .form-control {
    border: 0px !important;
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    color: ${FIELD_COLORS.FILLLED_TEXT_COLOR} !important;
  }

  .apply-button {
    border: 0;
    background: #fff;
  }
`;
