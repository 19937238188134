const YoutubeIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9936 6.80557C28.0564 5.01876 27.6583 3.24532 26.8361 1.64991C26.2782 0.995281 25.504 0.553507 24.6484 0.401571C21.1093 0.086396 17.5555 -0.0427839 14.0024 0.0145848C10.4622 -0.0453878 6.92121 0.0796236 3.39453 0.389088C2.69729 0.513568 2.05204 0.834549 1.53752 1.31286C0.392791 2.34899 0.265598 4.12163 0.138406 5.61965C-0.0461352 8.31303 -0.0461352 11.0155 0.138406 13.7089C0.175203 14.552 0.303112 15.389 0.519983 16.2056C0.673345 16.8361 0.983628 17.4194 1.42305 17.9033C1.94106 18.407 2.60134 18.7462 3.31822 18.8771C6.06038 19.2093 8.82338 19.3469 11.5857 19.289C16.0375 19.3514 19.9423 19.289 24.5594 18.9395C25.2938 18.8167 25.9727 18.477 26.5054 17.9658C26.8615 17.6161 27.1275 17.1882 27.2813 16.7174C27.7362 15.3475 27.9596 13.9138 27.9427 12.4731C27.9936 11.774 27.9936 7.55458 27.9936 6.80557ZM11.1278 13.2221V5.49481L18.6576 9.37716C16.5462 10.5256 13.7607 11.8239 11.1278 13.2221Z"
        fill="#58B4FA"
      />
    </svg>
  );
};

export default YoutubeIcon;
