import { FormFieldsProps } from "../../Basic/FormFields/FormFields";

const firstName: FormFieldsProps.Input = {
  fieldName: "firstName",
  idPrefix: "create-job",
  isRequired: true,
  label: "First name",
};
const middleName: FormFieldsProps.Input = {
  fieldName: "middleName",
  idPrefix: "create-job",
  isRequired: true,
  label: "Middle name",
};
const lastName: FormFieldsProps.Input = {
  fieldName: "lastName",
  idPrefix: "create-job",
  isRequired: true,
  label: "Last name",
};
const city: FormFieldsProps.CitySearch = {
  fieldName: "city",
  idPrefix: "create-job",
  isRequired: true,
  label: "City",
  countrySelectorName: "country",
};
const country: FormFieldsProps.CountrySelector = {
  fieldName: "country",
  idPrefix: "create-job",
  isRequired: true,
  label: "Country",
  citySearchName: "city",
};
const bio: FormFieldsProps.Textarea = {
  fieldName: "bio",
  idPrefix: "create-job",
  isRequired: true,
  label: "Bio",
};
const movies: FormFieldsProps.Dropdown = {
  fieldName: "movie",
  idPrefix: "create-job",
  isRequired: true,
  label: "Movies",
  options: [
    { label: "Interstellar", value: "Interstellar" },
    { label: "Inception", value: "Inception" },
    { label: "Following", value: "Following" },
    { label: "Dunkirk", value: "Dunkirk" },
  ],
};
const skills: FormFieldsProps.Dropdown = {
  fieldName: "skills",
  idPrefix: "create-job",
  isRequired: true,
  options: [
    { label: "React", value: "React" },
    { label: "Typescript", value: "Typescript" },
    { label: "Javascript", value: "Javascript" },
    { label: "Attention to detail", value: "Attention to detail" },
  ],
  label: "Skills",
  allowMultiple: true,
};
const hobby: FormFieldsProps.Dropdown = {
  fieldName: "hobby",
  idPrefix: "create-job",
  isRequired: true,
  label: "Hobby",
  options: [
    { label: "Anime", value: "Anime" },
    { label: "Bikes", value: "Bikes" },
  ],
};
const dateOfBirth: FormFieldsProps.Datepicker = {
  fieldName: "dateofbirth",
  idPrefix: "create-job",
  isRequired: true,
  label: "Date of birth",
  placeholder: "dd/mm/yyyy",
};
const age: FormFieldsProps.InputWithIncrementButton = {
  fieldName: "age",
  idPrefix: "create-job",
  isRequired: true,
  label: "Age",
  allowNegativeNumbers: false,
};
const email: FormFieldsProps.Input = {
  fieldName: "email",
  idPrefix: "create-job",
  isRequired: true,
  needLabelPlaceholder: true,
  inputType: "email",
};
const favouriteColor: FormFieldsProps.RadioGroup = {
  fieldName: "faviouriteColor",
  idPrefix: "create-job",
  isRequired: true,
  showEveryThingInRow: false,
  label: "What is your favourite color",
  options: [
    { label: "Purple", value: "Purple" },
    { label: "Blue", value: "Blue" },
    { label: "Red", value: "Red" },
    { label: "Black", value: "Black" },
  ],
};
const driverLicense: FormFieldsProps.Switch = {
  fieldName: "driverLicense",
  idPrefix: "create-job",
  isRequired: false,
  label: "Driver's License",
};
const willingToRelocate: FormFieldsProps.CheckboxGroup = {
  fieldName: "willingToRelocate",
  idPrefix: "create-job",
  isRequired: true,
  label: "Willing to relocate",
  showEveryThingInRow: true,
  showOptionsInRow: true,
  options: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
};
const driverLicense1: FormFieldsProps.Switch = {
  fieldName: "driverLicense1",
  idPrefix: "create-job",
  isRequired: false,
  label: "Driver's License",
};
const canYouPaint: FormFieldsProps.Switch = {
  fieldName: "canYouPaint",
  idPrefix: "create-job",
  isRequired: false,
  label: "Can You paint",
};
const description: FormFieldsProps.RichTextarea = {
  fieldName: "description",
  idPrefix: "create-job",
  isRequired: true,
  label: "Description",
};
const favouriteColor1: FormFieldsProps.RadioGroup = {
  fieldName: "faviouriteColor1",
  idPrefix: "create-job",
  isRequired: true,
  showEveryThingInRow: false,
  label: "What is your favourite color",
  options: [
    { label: "Purple", value: "Purple" },
    { label: "Blue", value: "Blue" },
    { label: "Red", value: "Red" },
    { label: "Black", value: "Black" },
  ],
};
const whatCanYouDoForCompany: FormFieldsProps.CheckboxGroup = {
  fieldName: "whatCanYouDoForCompany",
  idPrefix: "create-job",
  isRequired: true,
  options: [
    { label: "Paint", value: "Paint" },
    { label: "Phone calls", value: "Phone calls" },
    { label: "Programming", value: "Programming" },
    { label: "Sales", value: "Sales" },
  ],
  label: "What can you do for company",
};
const favouriteColor2: FormFieldsProps.RadioGroup = {
  fieldName: "faviouriteColor2",
  idPrefix: "create-job",
  isRequired: true,
  showEveryThingInRow: false,
  label: "What is your favourite color",
  options: [
    { label: "Purple", value: "Purple" },
    { label: "Blue", value: "Blue" },
    { label: "Red", value: "Red" },
    { label: "Black", value: "Black" },
  ],
};

export const FormExampleFields = {
  firstName,
  middleName,
  lastName,
  city,
  country,
  bio,
  movies,
  skills,
  hobby,
  dateOfBirth,
  age,
  email,
  favouriteColor,
  favouriteColor1,
  favouriteColor2,
  driverLicense,
  driverLicense1,
  canYouPaint,
  whatCanYouDoForCompany,
  description,
  willingToRelocate,
} as const;
