import { AUTH } from "./AUTH";
import { COMMON } from "./COMMON";
import { STRIPE } from "./STRIPE";
import { PIPELINE } from "./PIPELINE";
import { JOB } from "./JOB";
import { COMPANY_SETTINGS } from "./COMPANY_SETTINGS";
import { TEAM_OVERVIEW } from "./TEAM_OVERVIEW";
import { CANDIDATE_SETTINGS } from "./CANDIDATE_SETTINGS";
import { MESSAGE_TEMPLATE } from "./MESSAGE_TEMPLATE";
import { RECEIPTS } from "./RECEIPTS";
import { EMAIL_NOTIFICATION } from "./EMAIL_NOTIFICATION";

export const API_INSTANCES = {
  AUTH,
  COMMON,
  JOB,
  COMPANY_SETTINGS,
  PIPELINE,
  TEAM_OVERVIEW,
  CANDIDATE_SETTINGS,
  MESSAGE_TEMPLATE,
  STRIPE,
  RECEIPTS,
  EMAIL_NOTIFICATION,
};
