import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "../../Basic/Typography";
import { PipelineKey, pipelinePhaseData } from "../Utils";
import {
  CandidateContainer,
  CandidateListContainer,
  JobTitle,
  JobTitleContainer,
  Photo,
  MainContainer,
  HeadingContainer,
  DropDownTitle,
} from "./CandidateListStyle";
import { IPipeLine, Key } from "../PipeLine";
import Pagination from "./Pagination";
import { useAppDispatch, useAppSelector } from "src/Redux";
import { candidateActions } from "src/Redux/slices/Candidate";
import { API_INSTANCES } from "src/API_INSTANCES";
import { useParams } from "react-router-dom";

export const CandidateList: React.FC<IPipeLine.ICandidateList> = ({
  setStep,
  setActiveTab,
  phase,
  setPhase,
}) => {
  const [activePage, setActivePage] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [latest_unread, setlatest_unread] = React.useState("Latest");
  const { job_id } = useParams();

  const { candidateListData, selectedCandidate, pageSize } = useAppSelector(
    (state) => state.candidate
  );
  const dispatch = useAppDispatch();

  const totalPage = candidateListData.results.total_pages;

  const PageDataHandler = () => {
    const body = {
      job_id: job_id as string,
      page: activePage,
      size: pageSize,
      sort: latest_unread === "Latest" ? "date_created" : "is_read",
      status: phase,
    };
    API_INSTANCES.PIPELINE.pipelineApplicants(body)
      .then((response) => {
        dispatch(candidateActions.setCandidatelist(response.data));
        API_INSTANCES.PIPELINE.getCandidateOverview(
          response.data.applicants[0].application_id
        )
          .then((res) =>
            dispatch(
              candidateActions.setSelectedCandidate({
                ...res.data,
                application_id: response.data.applicants[0].application_id,
                candidate_id: response.data.applicants[0].candidate_id,
                candidate_rating: response.data.applicants[0].candidate_rating,
              })
            )
          )
          .catch((err) => console.log(err));
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    PageDataHandler();
  }, [activePage, pageSize, latest_unread, phase]);

  return (
    <MainContainer>
      <HeadingContainer>
        <div className="d-flex align-items-center text-nowrap">
          <Typography
            content="CANDIDATE LIST"
            styleName="subtitle2"
            color="black85"
          />
        </div>
        <div className="d-flex  gap-2">
          <Dropdown
            onSelect={(value) => {
              dispatch(candidateActions.setPageSize(value));
              setActivePage(1);
            }}
          >
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-light show-dropdown btn-label"
              data-bs-toggle="dropdown"
              type="button"
            >
              Show
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              <Dropdown.Item eventKey={25}>25</Dropdown.Item>
              <Dropdown.Item eventKey={50}>50</Dropdown.Item>
              <Dropdown.Item eventKey={10}>10</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            onSelect={(value) => {
              setlatest_unread(value as string);
              setActivePage(1);
            }}
          >
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-light latest-dropdown btn-label d-flex align-items-center"
              data-bs-toggle="dropdown"
              type="button"
            >
              <DropDownTitle>{latest_unread}</DropDownTitle>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              <Dropdown.Item eventKey={"Latest"}>Latest</Dropdown.Item>
              <Dropdown.Item eventKey={"Unread First"}>
                Unread First
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            className="onbording-dropdown"
            onSelect={(value) => setPhase(value as Key)}
          >
            <Dropdown.Toggle
              variant=""
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-primary btn-label"
              data-bs-toggle="dropdown"
              type="button"
            >
              {PipelineKey[phase]}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: "0px" }}>
              {pipelinePhaseData.map((option, index) => (
                <Dropdown.Item eventKey={option.key} key={index}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </HeadingContainer>

      <CandidateListContainer>
        <div className="candidate-list">
          {candidateListData.applicants?.map((candidate, index) => (
            <CandidateContainer
              key={index}
              isEmailOpen={candidate.is_read}
              isSelected={
                candidate.application_id === selectedCandidate.application_id
              }
              className={clsx(
                candidate.is_read ? "email-open" : "email-not-open"
              )}
              onClick={() => {
                setStep(1);
                dispatch(candidateActions.setIsEmailOpen(index));
                API_INSTANCES.PIPELINE.getCandidateOverview(
                  candidate.application_id
                ).then((res) =>
                  dispatch(
                    candidateActions.setSelectedCandidate({
                      ...res.data,
                      application_id: candidate.application_id,
                      candidate_id: candidate.candidate_id,
                      candidate_rating: candidate.candidate_rating,
                    })
                  )
                );
                dispatch(candidateActions.setAllEmpty());
                setActiveTab("Summary");
              }}
            >
              <div className="d-flex w-100 gap-3">
                <Photo src={`data:image/png;base64,${candidate.photo}`} />
                <div className="w-100 candidate-info">
                  <Typography
                    content={candidate.name}
                    styleName={
                      candidate.is_read
                        ? "candidateName"
                        : "candidateNameEmailOpen"
                    }
                    color="black80"
                  />
                  <JobTitleContainer
                    className={clsx(
                      candidate.is_read ? "open-envelop" : "envelop"
                    )}
                  >
                    <JobTitle>
                      <ICONS.BagIcon />
                      <Typography
                        content={candidate.job_title}
                        styleName="text_10pt"
                        color="black70"
                      />
                    </JobTitle>
                    {candidate.is_read ? (
                      <ICONS.OpenEnvelope />
                    ) : (
                      <ICONS.Envelope />
                    )}
                  </JobTitleContainer>
                </div>
              </div>
            </CandidateContainer>
          ))}
        </div>
        <Pagination
          activePage={activePage}
          setActivePage={setActivePage}
          totalPage={totalPage}
          page={page}
          setPage={setPage}
        />
      </CandidateListContainer>
    </MainContainer>
  );
};

