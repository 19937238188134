import { IIconProps } from "./customIcons";

const ViewIcon = ({ width, height }: IIconProps.Iprops) => {
  return (
    <svg
      className="view-icon"
      width={width || "22"}
      height={height || "19"}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22059 5.49267L5.2206 5.49269L5.22635 5.48824C6.95214 4.15085 8.87216 3.5 11 3.5C13.1278 3.5 15.0479 4.15085 16.7737 5.48824C18.4266 6.76919 19.6479 8.43216 20.4366 10.5037C19.6641 12.5749 18.4444 14.2493 16.7833 15.5043L16.7833 15.5043L16.7737 15.5118C15.0479 16.8491 13.1278 17.5 11 17.5C8.87216 17.5 6.95214 16.8491 5.22635 15.5118C3.57435 14.2316 2.35352 12.5698 1.56482 10.4999C2.3549 8.42444 3.57621 6.74795 5.22059 5.49267Z"
        fill="#4A4A69"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="11" cy="10.5" r="2.5" stroke="white" strokeWidth="2" />
      <path
        d="M1 1.5L21 17.5"
        stroke="#EDEDED"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ViewIcon;
