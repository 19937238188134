import { Card } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../Theme";
import { Typography } from "../Basic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import { option } from "./utils";
import { useAppSelector } from "src/Redux";
const CandidateApplicationChart = () => {
  const { daily_average, growth, this_month } = useAppSelector(
    (state) => state.dashboard.candidate_applications
  );
  return (
    <ChartCard>
      <Card.Header className="card-title pt-4 px-4">
        <Typography
          content="Candidate Applications"
          styleName="chartTitle"
          color="black80"
        />
      </Card.Header>
      <Card.Body>
        <div className="chart-features">
          <div className="d-flex flex-column">
            <Typography
              content="Total Sales"
              styleName="otpTextLink"
              color="black80"
            />
            <Typography
              content={String(daily_average)}
              styleName="heading6"
              color="black80"
            />
          </div>
          <div className="d-flex gap-3 align-items-center">
            <p className="tx-11 text-muted mb-0">Today</p>
            <FontAwesomeIcon
              icon={faCaretUp}
              style={{
                color: theme.colors.success_default,
              }}
            />
            <span className="badge bg-success text-white tx-11">
              {this_month}
            </span>
          </div>
        </div>

        <div style={{ minWidth: "930px" }}>
          <ReactApexChart
            options={{
              ...option,
              yaxis: { ...option.yaxis, ...growth.yaxis },
              xaxis: { ...option.xaxis, ...growth.xaxis },
            }}
            series={growth.series}
            type="line"
            height={270}
          />
        </div>
      </Card.Body>
    </ChartCard>
  );
};

export default CandidateApplicationChart;

const ChartCard = styled(Card)`
  .card-title:before {
    background: ${theme.colors.success_default};
    height: 17px;
    top: 24px;
  }
  .chart-features {
    padding: 17px 84px 0px 84px;
  }
  .card-body {
    overflow: auto;
  }
  .dark-theme & {
    .card-title label {
      color: ${theme.colors.black10};
    }
    .chart-features div label {
      &:first-child {
        color: ${theme.colors.black20};
      }
      &:last-child {
        color: ${theme.colors.black10};
      }
    }
  }
`;
