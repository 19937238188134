import styled, { css } from "styled-components";

import { FIELDS, FIELD_COLORS } from "../../FormFieldsStyles/common";
import { theme } from "src/components/Theme";

const Container = styled.div<{
  disabled: boolean;
  Error: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${({ Error }) => (Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL)};
`;
const InputGroupWrapper = styled.div`
  .input-group {
    flex-wrap: unset;
  }
  .currencyselect__menu {
    margin-left: -10px;
    min-width: 300px;
    border-radius: 6px;
  }
  .currencyselect__control {
    min-height: 0;
    height: 28px;
    min-width: 28px;
    margin-top: -1px;
    color: ${FIELD_COLORS.FILLLED_TEXT_COLOR};
    box-shadow: none !important;
  }
  .currencyselect__value-container {
    padding: 2px 6px;
    justify-content: center;
  }
  .currencyselect__indicators {
    display: none;
  }
  .currencyselect__input-container {
    padding: 0;
    margin: 0;
  }
  ${FIELDS.FORMSELECT_CONTROL};
  .currencyselect__single-value {
    text-overflow: unset;
  }
`;
const InputHover = css`
  border: 0 !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
`;
const DarkInputHover = css`
  box-shadow: 0 0 0px 1000px ${theme.colors.dark_input_background} inset !important;
  -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.dark_input_background} inset !important;
`;
const InputContainer = styled.div`
  width: calc(100% - 30px);

  .currency-input-field {
    border: 0 !important;
    border-radius: 0;
    min-height: 0;
    height: 28px;
  }
  .currency-input-field:hover {
    ${InputHover}
  }
  .currency-input-field:active {
    ${InputHover}
  }
  .currency-input-field:focus {
    ${InputHover}
  }
  .dark-theme & {
    .currency-input-field:hover {
      ${DarkInputHover}
    }
    .currency-input-field:active {
      ${DarkInputHover}
    }
    .currency-input-field:focus {
      ${DarkInputHover}
    }
  }
`;

export const CurrencySelectorStyles = {
  Container,
  InputGroupWrapper,
  InputContainer,
};
