import React from "react";
import { ISettings } from "../Settings";
import {
  Buttons,
  CustomModal,
  FormFields,
  Typography,
} from "src/components/Basic";
import { Button, Modal } from "react-bootstrap";
import { Formik, FormikValues } from "formik";
import { addUserFields, idPrefix } from "./Utils";
import { ButtonContainer, ModalFormContainer, ModalHeader } from "./ModalStyle";
import { formikSubmitHandler } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { API_CALLS } from "src/API_CALLS";

const AddUserModal: React.FC<ISettings.IModal> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const onSubmitHandler = (value: FormikValues) => {
    const newUser = {
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      user_role: value.user_role === "Admin" ? "1" : "0",
      base_url: window.location.origin,
    };
    API_CALLS.TEAM_OVERVIEW.addTeamMember(newUser);
    setIsModalOpen(false);
  };
  return (
    <CustomModal isModalOpen={isModalOpen}>
      <ModalHeader>
        <Modal.Title>
          <Typography
            content="Add User"
            styleName="modalHeading"
            color="black80"
          />
        </Modal.Title>
        <Button
          variant=""
          className="btn btn-close"
          style={{ opacity: "1" }}
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          x
        </Button>
      </ModalHeader>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          user_role: "",
        }}
        onSubmit={() => {}}
      >
        {(formik) => (
          <>
            <Modal.Body>
              <ModalFormContainer>
                <FormFields.Input {...addUserFields.first_name} />
                <FormFields.Input {...addUserFields.last_name} />
                <FormFields.Input {...addUserFields.email} />
                <FormFields.Dropdown {...addUserFields.user_role} />
              </ModalFormContainer>
            </Modal.Body>
            <Modal.Footer>
              <ButtonContainer>
                <Buttons
                  variant="light"
                  handleClick={() => {
                    setIsModalOpen(false);
                  }}
                  label="Close"
                />
              </ButtonContainer>
              <ButtonContainer>
                <Buttons
                  variant="success"
                  handleClick={() => {
                    formikSubmitHandler({
                      formFields: addUserFields,
                      formik,
                      onSubmitHandler,
                      idPrefix,
                    });
                  }}
                  label="Invite"
                />
              </ButtonContainer>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};

export default AddUserModal;
