import { Navbar } from "react-bootstrap";
import DesktopLogo from "./DesktopLogo";
import SidebarToggle from "./SidebarToggle";
import LeftHeaderSection from "./LeftHeaderSection";
import RightHeaderSection from "./RightHeaderSection";

const Header = () => {
  return (
    <Navbar className="main-header side-header sticky nav-item">
      <DesktopLogo />
      <div className="main-container container-fluid">
        <SidebarToggle />
        <LeftHeaderSection />
        <RightHeaderSection />
      </div>
    </Navbar>
  );
};

export default Header;
