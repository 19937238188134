import { API_INSTANCES } from "src/API_INSTANCES";
import store from "src/Redux";
import { messageTemplateActions } from "src/Redux/slices/MessageTemplate";

const dispatch = store.dispatch;
const getTag = () => {
  return API_INSTANCES.MESSAGE_TEMPLATE.getTag()
    .then((res) => dispatch(messageTemplateActions.setTags(res.data.tags)))
    .catch((error) => console.log(error));
};

const getMessageTemplate = () => {
  return API_INSTANCES.MESSAGE_TEMPLATE.getMessageTemplate()
    .then((res) =>
      dispatch(messageTemplateActions.setTemplates(res.data.templates))
    )
    .catch((error) => console.log(error));
};

const createMessageTemplate = (body: {
  template_name: string;
  subject: string;
  message: string;
}) => {
  return API_INSTANCES.MESSAGE_TEMPLATE.createMessageTemplate(body)
    .then((res) =>
      dispatch(messageTemplateActions.setTemplates(res.data.templates))
    )
    .catch((error) => console.log(error));
};
const updateMessageTemplate = (body: {
  template_name: string;
  subject: string;
  message: string;
  id: number;
}) => {
  return API_INSTANCES.MESSAGE_TEMPLATE.updateMessageTemplate(body)
    .then((res) =>
      dispatch(messageTemplateActions.setTemplates(res.data.templates))
    )
    .catch((error) => console.log(error));
};

const deleteMessageTemplate = (id: number) => {
  return API_INSTANCES.MESSAGE_TEMPLATE.deleteMessageTemplate(id)
    .then((res) =>
      dispatch(messageTemplateActions.setTemplates(res.data.templates))
    )
    .catch((error) => console.log(error));
};

export const MESSAGE_TEMPLATE = {
  getTag,
  getMessageTemplate,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
};
