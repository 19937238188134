const PhoneIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 13.28V11.28C14.6748 10.953 14.5625 10.6345 14.3511 10.385C14.1396 10.1355 13.8439 9.97234 13.52 9.92665C12.88 9.84224 12.2516 9.68568 11.6467 9.45998C11.4081 9.37029 11.1488 9.35088 10.8995 9.40405C10.6501 9.45722 10.4213 9.58074 10.24 9.75998L9.39337 10.6067C7.72434 9.65761 6.34241 8.27568 5.39337 6.60665L6.24004 5.75998C6.41928 5.57872 6.5428 5.34988 6.59597 5.10057C6.64915 4.85126 6.62973 4.59193 6.54004 4.35332C6.31434 3.74847 6.15779 3.12003 6.07337 2.47998C6.02809 2.15961 5.86786 1.86664 5.62254 1.65567C5.37723 1.4447 5.06357 1.33013 4.74004 1.33332H2.74004C2.55493 1.33349 2.37189 1.37221 2.20256 1.447C2.03324 1.52179 1.88134 1.63102 1.75655 1.76773C1.63175 1.90444 1.53679 2.06564 1.4777 2.24107C1.41862 2.41649 1.39671 2.60229 1.41337 2.78665C1.6322 4.84731 2.33336 6.82745 3.46004 8.56665C4.48359 10.1774 5.84926 11.5431 7.46004 12.5667C9.19137 13.6894 11.1619 14.3904 13.2134 14.6133C13.3983 14.63 13.5847 14.6079 13.7605 14.5485C13.9364 14.489 14.0979 14.3934 14.2348 14.2679C14.3716 14.1424 14.4807 13.9897 14.555 13.8195C14.6294 13.6494 14.6675 13.4657 14.6667 13.28Z"
        stroke="#1E96F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
