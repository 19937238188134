import { useContext } from "react";

import { Typography } from "src/components/Basic";
import { CreateJobAdvertiseCommon } from "./Common";
import { AdvertiseStyles } from "./Styles";
import { AdvertiseUtils } from "./Utils";
import { theme } from "src/components/Theme";
import { ICONS } from "src/assets/custom_Icons";
import { AppContext } from "src/App";
import { AdvertiseContext } from ".";

export const BoostedCard = () => {
  const { isMobile } = useContext(AppContext);
  const { handleCardClick, isBoostedOpen } = useContext(AdvertiseContext);
  const { BoostedTitle, Point, SelectButton } = CreateJobAdvertiseCommon;
  const { boosted } = AdvertiseUtils.points;
  const { primary_default, white_pure } = theme.colors;

  const boostedPoints = boosted.points.map((point, index) => (
    <Point key={`${point.advertType}_${index}`} {...point} />
  ));
  return (
    <AdvertiseStyles.Card
      className="advertise-card"
      onClick={() => handleCardClick("boosted")}
      advertType="boosted"
      isOpen={isBoostedOpen}
    >
      <AdvertiseStyles.TitleBar>
        <BoostedTitle title={boosted.title} isOpen={isBoostedOpen} />
        {isMobile && (
          <>
            {isBoostedOpen ? (
              <ICONS.TriangleArrowUp fillColor={primary_default} />
            ) : (
              <ICONS.TriangleArrowDown fillColor={white_pure} />
            )}
          </>
        )}
      </AdvertiseStyles.TitleBar>

      {(!isMobile || isBoostedOpen) && (
        <>
          <AdvertiseStyles.SetCampaignBudget>
            <Typography
              content="Set your own campaign budgets"
              styleName="jobPill"
              color="white_pure"
            />
          </AdvertiseStyles.SetCampaignBudget>
          <AdvertiseStyles.PointsWrapper>
            {boostedPoints}
          </AdvertiseStyles.PointsWrapper>
          <SelectButton advertType="boosted" />
        </>
      )}
    </AdvertiseStyles.Card>
  );
};
