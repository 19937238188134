import { Dispatch, SetStateAction } from "react";
import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { IOverview } from "src/components/Settings/Overview/Overview";
import { ITeamManagement } from "src/components/pages/TeamManagement/TeamManagement";
const dispatch = store.dispatch;
const getTeamOverview = () => {
  API_INSTANCES.TEAM_OVERVIEW.getTeamOverview()
    .then((res) =>
      dispatch(
        actions.settings.setUserData([...res.data.team, ...res.data.invited])
      )
    )
    .catch((error) => console.log(error));
};

const getUnverifiedMemberDetails = (
  email: string,
  token: string,
  setIsValidLink: Dispatch<SetStateAction<boolean>>
) => {
  API_INSTANCES.TEAM_OVERVIEW.getUnverifiedMemberDetails(email, token)
    .then((res) => {
      setIsValidLink(true);
      dispatch(actions.settings.setUnverifiedMemberDetails(res.data));
    })
    .catch((error) => {
      setIsValidLink(false);
      console.log(error);
    });
};

const activeDeactiveUser = (isActive: boolean, id: string) => {
  API_INSTANCES.TEAM_OVERVIEW.activeDeactiveUser(isActive, id)
    .then((res) =>
      dispatch(
        actions.settings.setUserData([...res.data.team, ...res.data.invited])
      )
    )
    .catch((error) => console.log(error));
};

const addMemberCompany = (
  value: ITeamManagement.CompanyMember,
  setIsVerify: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<string>>
) => {
  setError("");
  API_INSTANCES.TEAM_OVERVIEW.addMemberCompany(value)
    .then(() => setIsVerify(true))
    .catch((error) => {
      console.log(error);
      setError(error.response.data.detail);
    });
};

const addTeamMember = (value: IOverview.TeamMember) => {
  API_INSTANCES.TEAM_OVERVIEW.addTeamMember(value)
    .then(() => getTeamOverview())
    .catch((error) => console.log(error));
};

const updateTeamMember = (
  value: IOverview.UpdateTeamMember,
  setAction: Dispatch<SetStateAction<boolean>>,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  setAction(true);
  API_INSTANCES.TEAM_OVERVIEW.updateTeamMember(value)
    .then((res) => {
      dispatch(
        actions.settings.setUserData([...res.data.team, ...res.data.invited])
      );
      setAction(false);
      setIsModalOpen(false);
    })
    .catch((error) => {
      console.log(error);
      setAction(false);
      setIsModalOpen(false);
    });
};

const deleteInvitedMember = (id: string) => {
  API_INSTANCES.TEAM_OVERVIEW.deleteInvitedMember(id)
    .then((res) =>
      dispatch(
        actions.settings.setUserData([...res.data.team, ...res.data.invited])
      )
    )
    .catch((error) => console.log(error));
};
const updateInvitedMember = (
  id: string,
  first_name: string,
  last_name: string
) => {
  API_INSTANCES.TEAM_OVERVIEW.updateInvitedMember(id, first_name, last_name)
    .then((res) =>
      dispatch(
        actions.settings.setUserData([...res.data.team, ...res.data.invited])
      )
    )
    .catch((error) => console.log(error));
};
export const TEAM_OVERVIEW = {
  getTeamOverview,
  activeDeactiveUser,
  getUnverifiedMemberDetails,
  addMemberCompany,
  addTeamMember,
  updateTeamMember,
  deleteInvitedMember,
  updateInvitedMember,
};
