import { theme } from "src/components/Theme";
import styled from "styled-components";
export const CompanyProfileSettingsContainer = styled.div`
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 20px 61px 57px 20px;
  height: 100% !important;
  @media (max-width: 991px) {
    padding: 20px 12px 20px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 20px 20px 57px 20px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 633px;
  @media (max-width: 991px) {
    width: 100%;
  }
  .dark-theme & label {
    &:first-child {
      color: ${theme.colors.grey_subtitle1};
    }
    &:last-child {
      color: ${theme.colors.grey_subtitle2};
    }
  }
`;
export const DetailsContainer = styled.div`
  padding: 40px 0 0 56px;
  @media (max-width: 991px) {
    padding: 40px 0 0 0;
  }
`;
export const ImgContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 200px;
  background: rgba(30, 150, 242, 0.2);
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(21, 21, 21, 0.02) 0px 1px 4px,
    rgba(21, 21, 21, 0.08) 0px 8px 12px;
  @media (max-width: 991px) {
    height: 150px;
  }
`;
export const EditContainer = styled.div<{ right: string }>`
  position: absolute;
  top: 10px;
  right: ${({ right }) => right};
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: ${theme.colors.primary_default};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 991px) {
    top: 8px;
    right: 8px;
  }
`;
export const GalleryEditContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: ${theme.colors.primary_default};
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: visibility 0.5s ease-in-out;
  svg path {
    stroke: ${theme.colors.white_pure};
  }
`;
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 36px;
  padding-right: 238px;
  @media (max-width: 991px) {
    padding: 0;
    margin-top: 40px;
    gap: 20px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    padding-right: 100px;
  }
`;

export const SocialAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-top: 12px;
    margin-bottom: 10px;
  }
`;
export const CommonContainer = styled.div`
  margin-top: 16px;
  @media (max-width: 991px) {
    margin-top: 0px;
  }
`;
export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 80px;
  @media (max-width: 991px) {
    margin-bottom: 40px;
    gap: 20px;
  }
  .dark-theme & > label {
    color: ${theme.colors.dark_input_label};
  }
`;
export const Container = styled.div`
  background: ${theme.colors.white_pure};
  border: 1px solid ${theme.colors.black10};
  border-radius: 10px;
  width: 100%;
  min-height: 70px;
  padding: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  & button {
    white-space: nowrap;
  }
  .gallery-container {
    box-shadow: rgba(21, 21, 21, 0.02) 0px 1px 4px,
      rgba(21, 21, 21, 0.08) 0px 8px 12px;
    position: relative;
    &:hover div {
      visibility: visible;
    }
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    border-color: ${theme.colors.black400};
  }
`;
export const ProfileContainer = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
  }
  .dark-theme & > div > label {
    color: ${theme.colors.dark_input_label};
  }
`;
