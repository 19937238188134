import { JobProps } from "./Job";
import { createSlice } from "@reduxjs/toolkit";

export const JobPropsSteps = [1, 2, 3] as const;

const startDate = new Date();
const endDate = new Date(startDate);
endDate.setDate(startDate.getDate() + 30);

const initialState: JobProps.State = {
  stepNumber: 1,
  CreateNovaJobForm: {
    show_company_gallery: false,
    add_external_apply_URL: false,
    add_qualifying_question: false,
    external_apply: "",
    advertised_type: "0",
  } as JobProps.CreateNovaJobForm,
  CreateNovaAPIData: {} as JobProps.CreateNovaJobAPI,
  CreateNovaJobAPIInProgress: false,
  isJobCreated: false,
  CreateNovaJobError: "",
  questionComponents: {} as JobProps.questionComponents,

  advertType: "",
  isBoostedSelected: false,
  chartData: {
    axis_pointer_value: "",
    bid_end: "0",
    bid_start: "0",
    bid_stepper: "0.15",
    recommended_range: { end_value: "0", start_value: "0" },
    low_range: { end_value: "0", start_value: "0" },
  } as JobProps.chartData,
  getChartDataAPIInProgress: false,
  chartDataError: "",
  boostedCampaignForm: {
    campaign_duration_from: `${startDate.getTime()}`,
    campaign_duration_to: `${endDate.getTime()}`,
    campaign_input: "0",
    total_campaign_budget: "0",
  },
  createBoostedCampaignAPIData: {
    campaign_duration_from: "",
    campaign_duration_to: "",
    campaign_input: "",
    total_campaign_budget: "",
  },
  isTotalCampaignManuallyUpdated: false,
  showCampaignLoadingAnimation: false,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setStepNumber: (state, action: JobProps.setStepNumber) => {
      state.stepNumber = action.payload;
    },
    setCreateNovaJobForm: (state, action: JobProps.setCreateNovaJobForm) => {
      state.CreateNovaJobForm = action.payload;
    },
    setCreateNovaJobAPIData: (
      state,
      action: JobProps.setCreateNovaJobAPIData
    ) => {
      state.CreateNovaAPIData = action.payload;
    },
    setCreateNovaJobAPIInProgress: (
      state,
      action: JobProps.setCreateNovaJobAPIInProgress
    ) => {
      state.CreateNovaJobAPIInProgress = action.payload;
    },
    setJobCreated: (state, action: JobProps.setJobCreated) => {
      state.isJobCreated = action.payload;
    },
    setCreateNovaJobError: (state, action: JobProps.setCreateNovaJobError) => {
      state.CreateNovaJobError = action.payload;
    },
    setQuestionComponents: (state, action: JobProps.setQuestionComponents) => {
      state.questionComponents = action.payload;
    },

    setAdvertType: (state, action: JobProps.setAdvertType) => {
      state.advertType = action.payload;
    },
    setBoostedSelected: (state, action: JobProps.setBoostedSelected) => {
      state.isBoostedSelected = action.payload;
    },
    setChartData: (state, action: JobProps.setChartData) => {
      state.chartData = action.payload;
    },
    setBoostedCampaignForm: (
      state,
      action: JobProps.setBoostedCampaignForm
    ) => {
      state.boostedCampaignForm = action.payload;
    },
    setCreateBoostedCampaignAPIData: (
      state,
      action: JobProps.setCreateBoostedCampaignAPIData
    ) => {
      state.createBoostedCampaignAPIData = action.payload;
    },
    setChartDataAPIInProgress: (
      state,
      action: JobProps.setChartDataAPIInProgress
    ) => {
      state.getChartDataAPIInProgress = action.payload;
    },
    setChartDataError: (state, action: JobProps.setChartDataError) => {
      state.chartDataError = action.payload;
    },
    setTotalCampaignManuallyUpdated: (
      state,
      action: JobProps.setTotalCampaignManuallyUpdated
    ) => {
      state.isTotalCampaignManuallyUpdated = action.payload;
    },
    setShowCampaignLoadingAnimation: (
      state,
      action: JobProps.setShowCampaignLoadingAnimation
    ) => {
      state.showCampaignLoadingAnimation = action.payload;
    },
    reset: () => initialState,
  },
});

export const jobReducer = jobSlice.reducer;
export const jobActions = jobSlice.actions;
