const VerticalLineIcon = () => {
  return (
    <svg
      width="2"
      height="26"
      viewBox="0 0 2 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 -0.125V25.875"
        stroke="#E2E2E2"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VerticalLineIcon;
