import instance from "src/axios/axios-user";
import { CandidateProfile } from "src/components/Settings/CandidateProfile/CandidateProfile";
import { API_PATHS } from "src/utils/API_PATHS";

const getCandidateDetail = () => {
  return instance.get(API_PATHS.myProfile);
};
const updateCandidateDetail = (value: CandidateProfile.CandidateDetails) => {
  const formdata = new FormData();
  formdata.append("first_name", value.first_name);
  formdata.append("last_name", value.last_name);
  formdata.append("email", value.email);
  return instance.put(API_PATHS.myProfile, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
const getCandidateProfilePhoto = () => {
  return instance.get(API_PATHS.myProfilePicture);
};

const updateCandidateProfilePhoto = (values: File) => {
  const formdata = new FormData();
  formdata.append("profile_photo", values as File);

  return instance.post(API_PATHS.myProfilePicture, formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateCandidatePassword = (value: CandidateProfile.Password) => {
  const formdata = new FormData();
  formdata.append("current_password", value.current_password);
  formdata.append("new_password", value.new_password);
  return instance.put(API_PATHS.myProfileUpdatePassword, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const CANDIDATE_SETTINGS = {
  getCandidateDetail,
  updateCandidateDetail,
  getCandidateProfilePhoto,
  updateCandidateProfilePhoto,
  updateCandidatePassword,
};
