import { API_INSTANCES } from "src/API_INSTANCES";
import store from "src/Redux";
import { settingsActions } from "src/Redux/slices/Settings";
const dispatch = store.dispatch;
const getReceipts = () => {
  API_INSTANCES.RECEIPTS.getReceipts()
    .then((res) => dispatch(settingsActions.setReceiptData(res.data)))
    .catch((error) => console.log(error));
};

const downloadReceipt = (id: string, filename: string) => {
  API_INSTANCES.RECEIPTS.downloadReceipt(id)
    .then((res) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(res.data);
      downloadLink.download = `${filename}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((error) => console.log(error));
};
export const RECEIPTS = { getReceipts, downloadReceipt };
