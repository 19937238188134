import { ICandidateTabHeader } from "../TabHeaderComponent/CandidateTabHeader/CandidateTabHeader";
import { IPipeLine } from "../PipeLine";
import VerticalLineIcon from "src/assets/custom_Icons/VerticalLineIcon";
import GreaterThanIcon from "src/assets/custom_Icons/GreaterThanIcon";
import BriefCaseIconWithVerticalLine from "src/assets/custom_Icons/BriefCaseIconWithVerticalLine";
import PaperClip from "src/assets/custom_Icons/PaperClip";
import MessageIcon from "src/assets/custom_Icons/MessageIcon";
import ActivityIcon from "src/assets/custom_Icons/ActivityIcon";
import QualifierIcon from "src/assets/custom_Icons/QualifierIcon";

export const PipelineKey = {
  "-1": "Regretted",
  0: "Inbox",
  1: "Screening",
  2: "Shortlist",
  3: "Interview",
  4: "Offer made",
  5: "Onboarding",
};
export const pipelinePhaseData: IPipeLine.PipelinePhase[] = [
  {
    label: "Regretted",
    isOnBoarding: false,
    icon: <VerticalLineIcon />,
    key: "-1",
  },
  {
    label: "Inbox",
    isOnBoarding: false,
    icon: <GreaterThanIcon />,
    key: 0,
  },
  {
    label: "Screening",
    isOnBoarding: false,
    icon: <GreaterThanIcon />,
    key: 1,
  },
  {
    label: "Shortlist",
    isOnBoarding: false,
    icon: <GreaterThanIcon />,
    key: 2,
  },
  {
    label: "Interview",
    isOnBoarding: false,
    icon: <GreaterThanIcon />,
    key: 3,
  },
  {
    label: "Offer made",
    isOnBoarding: false,
    icon: <GreaterThanIcon />,
    key: 4,
  },
  {
    label: "Onboarding",
    isOnBoarding: true,
    key: 5,
  },
];

export const activityData = [
  {
    name: "Emperio",
    description:
      "Project assigned by the manager all files and folders were seen to be stamped and ready.",
    date: "24, oct 2021",
  },
  {
    name: "Anesthesia",
    description: "Admin and other team accepted your work request.",
    date: "30, sep 2021",
  },
  {
    name: "Hughes",
    description:
      "Temporary data will be deleted once dedicated time completed by tomorrow after the meeting.",
    date: "11, sep 2021",
  },
  {
    name: "Samantha Melon",
    description: "Approved date for sanction of loan is verified",
    date: "18, sep 2021",
  },
  {
    name: "Nexus Ronaldo",
    description:
      "Social network accounts are at risk check your login details as soon as possible.",
    date: "18, sep 2021",
  },
];

export const CandidateTabData: ICandidateTabHeader.Tab[] = [
  {
    title: "Experience",
    key: "section_experience",
    width: 93,
    icon: <BriefCaseIconWithVerticalLine width={7} />,
  },
  {
    title: "Files",
    key: "section_files",
    width: 50,
    icon: <PaperClip width={7} />,
  },
  {
    title: "Comments",
    key: "section_comments",
    width: 92,
    icon: <MessageIcon width={7} />,
  },
  {
    title: "Activity",
    key: "section_activity",
    width: 72,
    icon: <ActivityIcon width={7} />,
  },
  {
    title: "Qualifiers",
    key: "section_qualifiers",
    width: 81,
    icon: <QualifierIcon width={1.75} />,
  },
];
