import { useEffect, useState } from "react";
import Select from "react-select";
import { Field, FieldProps } from "formik";
import { Form, InputGroup } from "react-bootstrap";

import { FormFieldsProps } from "../../FormFields";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { CurrencySelectorStyles } from "./currencySelectorStyles";
import { REGEX } from "../../REGEX";
import { CurrencyUtils } from "./utils";
import { useAppSelector } from "src/Redux";

const classNamePrefix = "currencyselect";

const getDropdownDefaultValue = (label: string) =>
  CurrencyUtils.currencyOptions.find(
    (option) => option.label.toLowerCase() === label.toLowerCase()
  );

//Used to update every currency field within form in one go
const updateCurrencyValue = (currency: FormFieldsProps.currencyOption) => {
  const currency_selectors = document.getElementsByClassName(
    `${classNamePrefix}__single-value`
  );
  for (let i = 0; i < currency_selectors.length; i++) {
    const existingValue = currency_selectors[i]
      .innerHTML as FormFieldsProps.CurrencyLabels;
    const value = CurrencyUtils.currency_dict[currency?.label];

    if (!existingValue || !value) continue;
    currency_selectors[i].innerHTML = value;
  }
};

export const CurrencySelector = (props: FormFieldsProps.CurrencySelector) => {
  const { currencyOptions, currencyMap, DEFAULT_COUNTRY } = CurrencyUtils;
  const { selected_country } = useAppSelector((store) => store.common);

  const {
    fieldName,
    idPrefix,
    isRequired,
    disabled = false,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    currencyType = currencyMap[
      selected_country?.id ? selected_country.id : DEFAULT_COUNTRY
    ],
    currencyTypeFieldName,
    onCurrencyChangeHandler,
    showLabel = true,
  } = props;

  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [currency, setCurrency] = useState(currencyType);

  useEffect(() => {
    updateCurrencyValue(currency);
  }, [currency]);
  useEffect(() => {
    const currency = currencyMap[selected_country.id];
    selected_country?.id && setCurrency(currency);
    updateCurrencyValue(currency);
  }, [selected_country, currencyMap]);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form, field }: FieldProps) => {
        const { onBlur, onChange } = field;
        const { touched, value = "", error } = meta;
        const { setFieldValue, getFieldMeta } = form;

        const errorConditions = isRequired && !isFocused && touched;
        const hasError = errorConditions && ((error ? true : false) || !value);
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        //setting default currency value in case of edit form
        const { value: currencyValue } = getFieldMeta(currencyTypeFieldName);
        let newCurrencyType = currencyValue || currencyType;

        if (typeof currencyValue === "string") {
          const defaultValue = getDropdownDefaultValue(currencyValue);
          if (defaultValue) {
            newCurrencyType = defaultValue;
            setFieldValue(currencyTypeFieldName, newCurrencyType);
            updateCurrencyValue(
              newCurrencyType as FormFieldsProps.currencyOption
            );
          }
        }
        if (!isDefaultSet && newCurrencyType) {
          setFieldValue(currencyTypeFieldName, newCurrencyType);
          setIsDefaultSet(true);
        }

        return (
          <div>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <CurrencySelectorStyles.Container
              disabled={disabled}
              Error={hasError}
            >
              <CurrencySelectorStyles.InputGroupWrapper
                id={`${idPrefix}_${fieldName}`}
                className="form-control"
              >
                <InputGroup>
                  <Select
                    id={`${idPrefix}_${currencyTypeFieldName}`}
                    name={`${currencyTypeFieldName}`}
                    onChange={(item) => {
                      setFieldValue(currencyTypeFieldName, item);
                      setCurrency(item as FormFieldsProps.currencyOption);
                      onCurrencyChangeHandler && onCurrencyChangeHandler(item);
                    }}
                    options={currencyOptions}
                    classNamePrefix={classNamePrefix}
                    className="currency-selector-select"
                    isSearchable={false}
                    isMulti={false}
                    isDisabled={disabled}
                    value={newCurrencyType}
                    isOptionSelected={() => false}
                  />
                  <CurrencySelectorStyles.InputContainer>
                    <Form.Control
                      id={`${idPrefix}_${fieldName}`}
                      type="text"
                      className="currency-input-field"
                      name={fieldName}
                      placeholder={placeholder}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          onChange(e);
                          return;
                        }
                        if (!REGEX.NUMBER.test(value)) return;

                        const indexOfDecimalPoint = value.indexOf(".");
                        if (indexOfDecimalPoint < value.length - 2)
                          setFieldValue(fieldName, Number.parseFloat(value));
                        else setFieldValue(fieldName, value);
                      }}
                      value={value}
                      disabled={disabled}
                      onFocus={() => setFocused(true)}
                      onBlur={(e) => {
                        onBlur(e);
                        setFocused(false);
                      }}
                    />
                  </CurrencySelectorStyles.InputContainer>
                </InputGroup>
              </CurrencySelectorStyles.InputGroupWrapper>
            </CurrencySelectorStyles.Container>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </div>
        );
      }}
    </Field>
  );
};
