import { IIconProps } from "./customIcons";

export const EditIcon = ({ fill, height, width }: IIconProps.Iprops) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17_21710)">
      <path
        d="M14.9317 1.98899C14.6457 2.10744 14.3859 2.28106 14.167 2.49993L2.91699 13.7499L1.66699 18.3333L6.25033 17.0833L17.5003 5.83326C17.7192 5.61439 17.8928 5.35455 18.0113 5.06859C18.1297 4.78262 18.1907 4.47612 18.1907 4.16659C18.1907 3.85706 18.1297 3.55057 18.0113 3.2646C17.8928 2.97863 17.7192 2.7188 17.5003 2.49993C17.2815 2.28106 17.0216 2.10744 16.7357 1.98899C16.4497 1.87054 16.1432 1.80957 15.8337 1.80957C15.5241 1.80957 15.2176 1.87054 14.9317 1.98899Z"
        stroke={fill || "#939DB5"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_21710">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
