const LinkedinIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.88499 17.5H0.0761758V6.07356H3.88499V17.5ZM1.98058 4.47386C1.4553 4.47386 0.951529 4.26519 0.580098 3.89376C0.208667 3.52233 0 3.01856 0 2.49328C0 1.968 0.208667 1.46423 0.580098 1.0928C0.951529 0.721364 1.4553 0.512696 1.98058 0.512696C2.25951 0.481063 2.54197 0.508702 2.80947 0.593803C3.07698 0.678903 3.32349 0.819547 3.53287 1.00652C3.74225 1.1935 3.90978 1.42259 4.02448 1.6788C4.13918 1.93502 4.19848 2.21256 4.19848 2.49328C4.19848 2.77399 4.13918 3.05154 4.02448 3.30775C3.90978 3.56396 3.74225 3.79306 3.53287 3.98003C3.32349 4.16701 3.07698 4.30765 2.80947 4.39275C2.54197 4.47786 2.25951 4.50549 1.98058 4.47386ZM17.6221 17.5H13.8133V11.3678C13.8133 9.83159 13.2674 8.8286 11.8835 8.8286C11.4552 8.83174 11.0382 8.96608 10.6886 9.21351C10.339 9.46095 10.0737 9.80959 9.9283 10.2125C9.82895 10.5109 9.7859 10.8251 9.80134 11.1393V17.4873H5.99253C5.99253 17.4873 5.99253 7.10194 5.99253 6.06087H9.80134V7.67326C10.1473 7.07288 10.6506 6.57826 11.2569 6.24268C11.8631 5.9071 12.5495 5.74328 13.242 5.76886C15.7812 5.76886 17.6221 7.40665 17.6221 10.9235V17.5Z"
        fill="#58B4FA"
      />
    </svg>
  );
};

export default LinkedinIcon;
