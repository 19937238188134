const LocationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C8 15 14 11.1818 14 6.72727C14 5.20831 13.3679 3.75155 12.2426 2.67748C11.1174 1.60341 9.5913 1 8 1C6.4087 1 4.88258 1.60341 3.75736 2.67748C2.63214 3.75155 2 5.20831 2 6.72727C2 11.1818 8 15 8 15Z"
        stroke="#1E96F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z"
        stroke="#1E96F2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
