import { TabletAdvertCardsTitle } from "./TabletAdvertCardsTitle";
import { StandardCard } from "./1-StandardCard";
import { PremiumCard } from "./2-PremiumCard";
import { BoostedCard } from "./3-BoostedCard";
import { useContext } from "react";
import { AdvertiseContext } from ".";
import { AppContext } from "src/App";
import { AdvertiseStyles } from "./Styles";

export const AdvertCards = () => {
  const { isStandardOpen, isPremiumOpen, isBoostedOpen } =
    useContext(AdvertiseContext);
  const { isSmallTablet, isTablet, isDesktopOrBig } = useContext(AppContext);
  const isAnyTablet = isSmallTablet || isTablet;

  return (
    <>
      {isAnyTablet ? (
        <>
          <TabletAdvertCardsTitle />
          {isStandardOpen && <StandardCard />}
          {isPremiumOpen && <PremiumCard />}
          {isBoostedOpen && <BoostedCard />}
        </>
      ) : (
        <>
          <StandardCard />
          <PremiumCard />
          {isDesktopOrBig && <AdvertiseStyles.VerticalLine />}
          <BoostedCard />
        </>
      )}
    </>
  );
};
