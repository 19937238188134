import { Formik } from "formik";
import { NavLink } from "react-router-dom";

import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { Typography } from "src/components/Basic";
import Input from "../Components/input";
import { ButtonWrapper, ErrorLabel } from "../Components/style";
import { registerFirstValidationSchema } from "../Components/Utils";
import {
  Circle,
  Container,
  ContentWrapper,
  InputContainer,
  InputFieldWrapper,
  Wrapper,
} from "./style";
import { RegisterProps } from "src/Redux/slices/Register/Register";
import { RegisterUtils } from "./utils";
import { useState } from "react";

const FirstStep = () => {
  const dispatch = useAppDispatch();
  const { stepNumber, errorMessageFromAPI, FormValues } = useAppSelector(
    (store) => store.register
  );
  const [isSubmitClicked, setSubmitClicked] = useState(false);

  const { email, first_name, last_name, password } = FormValues;
  const { formFields } = RegisterUtils;

  return (
    <Formik
      initialValues={
        {
          email,
          first_name,
          last_name,
          password,
        } as RegisterProps.Step1Values
      }
      onSubmit={(values) => {
        dispatch(actions.register.setStep1Values(values));
        dispatch(actions.register.setStepNumber(2));
      }}
      validationSchema={registerFirstValidationSchema}
    >
      {(formik) => {
        const onChangeHandler = () => {
          errorMessageFromAPI &&
            dispatch(actions.login.setErrorMessageFromAPI(""));
        };

        return (
          <>
            <InputFieldWrapper>
              <InputContainer>
                <Input {...formFields.first_name} {...{ onChangeHandler }} />
                <Input {...formFields.last_name} {...{ onChangeHandler }} />
                <Input {...formFields.email} {...{ onChangeHandler }} />
                <Input {...formFields.password} {...{ onChangeHandler }} />
              </InputContainer>
              {errorMessageFromAPI && (
                <ErrorLabel>{errorMessageFromAPI}</ErrorLabel>
              )}

              <Container step={stepNumber}>
                <Circle
                  onClick={() => dispatch(actions.register.setStepNumber(1))}
                />
                <Circle onClick={() => formik.submitForm()} />
              </Container>
              <ButtonWrapper
                className="w-100"
                disabled={isSubmitClicked && !formik.isValid}
                type="submit"
                onClick={() => {
                  setSubmitClicked(true);
                  formik.isValid && formik.submitForm();
                }}
              >
                <Typography
                  content="Next"
                  styleName="tableButton"
                  color="white_pure"
                />
              </ButtonWrapper>
            </InputFieldWrapper>
            <Wrapper>
              <ContentWrapper>
                <Typography
                  content="By registering you accept our"
                  styleName="authDescription"
                  color="black60"
                />
                <Typography
                  content=" terms and conditions"
                  styleName="authDescription"
                  color="black60"
                />
              </ContentWrapper>

              <div className="d-flex gap-2">
                <Typography
                  content="Have an acount?"
                  styleName="createAccount"
                  color="black60"
                />
                <NavLink to={"/auth/sign-in"}>
                  <Typography
                    content="Sign In"
                    styleName="createAccount"
                    color="black200"
                  />
                </NavLink>
              </div>
            </Wrapper>
          </>
        );
      }}
    </Formik>
  );
};

export default FirstStep;
