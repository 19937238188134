import { FormFieldsProps } from "../../FormFields";

const countryIds = [
  "af",
  "ax",
  "al",
  "dz",
  "as",
  "ad",
  "ao",
  "ai",
  "aq",
  "ag",
  "ar",
  "am",
  "aw",
  "au",
  "at",
  "az",
  "bs",
  "bh",
  "bd",
  "bb",
  "by",
  "be",
  "bz",
  "bj",
  "bm",
  "bt",
  "bo",
  "bq",
  "ba",
  "bw",
  "bv",
  "br",
  "io",
  "bn",
  "bg",
  "bf",
  "bi",
  "cv",
  "kh",
  "cm",
  "ca",
  "ky",
  "cf",
  "td",
  "cl",
  "cn",
  "cx",
  "cc",
  "co",
  "km",
  "ck",
  "cr",
  "hr",
  "cu",
  "cw",
  "cy",
  "cz",
  "ci",
  "cd",
  "dk",
  "dj",
  "dm",
  "do",
  "ec",
  "eg",
  "sv",
  "gq",
  "er",
  "ee",
  "et",
  "fk",
  "fo",
  "fj",
  "fi",
  "fr",
  "gf",
  "pf",
  "tf",
  "ga",
  "gm",
  "ge",
  "de",
  "gh",
  "gi",
  "gr",
  "gl",
  "gd",
  "gp",
  "gu",
  "gt",
  "gg",
  "gn",
  "gw",
  "gy",
  "ht",
  "hm",
  "hn",
  "hk",
  "hu",
  "is",
  "in",
  "id",
  "ir",
  "iq",
  "ie",
  "im",
  "il",
  "it",
  "jm",
  "jp",
  "je",
  "jo",
  "kz",
  "ke",
  "ki",
  "kp",
  "kr",
  "kw",
  "kg",
  "la",
  "lv",
  "lb",
  "ls",
  "lr",
  "ly",
  "li",
  "lt",
  "lu",
  "mo",
  "mk",
  "mg",
  "mw",
  "my",
  "mv",
  "ml",
  "mt",
  "mh",
  "mq",
  "mr",
  "mu",
  "yt",
  "mx",
  "fm",
  "md",
  "mc",
  "mn",
  "me",
  "ms",
  "ma",
  "mz",
  "mm",
  "na",
  "nr",
  "np",
  "nl",
  "nc",
  "nz",
  "ni",
  "ne",
  "ng",
  "nu",
  "nf",
  "mp",
  "no",
  "om",
  "pk",
  "pw",
  "ps",
  "pa",
  "pg",
  "py",
  "pe",
  "ph",
  "pn",
  "pl",
  "pt",
  "pr",
  "qa",
  "cg",
  "re",
  "ro",
  "ru",
  "rw",
  "bl",
  "sh",
  "kn",
  "lc",
  "mf",
  "pm",
  "vc",
  "ws",
  "sm",
  "st",
  "sa",
  "sn",
  "rs",
  "sc",
  "sl",
  "sg",
  "sx",
  "sk",
  "si",
  "sb",
  "so",
  "za",
  "gs",
  "ss",
  "es",
  "lk",
  "sd",
  "sr",
  "sj",
  "sz",
  "se",
  "ch",
  "sy",
  "tw",
  "tj",
  "tz",
  "th",
  "tl",
  "tg",
  "tk",
  "to",
  "tt",
  "tn",
  "tr",
  "tm",
  "tc",
  "tv",
  "ug",
  "ua",
  "ae",
  "gb",
  "us",
  "um",
  "uy",
  "uz",
  "vu",
  "va",
  "ve",
  "vn",
  "vg",
  "vi",
  "wf",
  "eh",
  "ye",
  "zm",
  "zw",
] as const;

const countries: FormFieldsProps.Countries = {
  af: {
    id: "af",
    name: "Afghanistan",
    flag: "🇦🇫",
    alpha2: "af",
    alpha3: "afg",
    ioc: "afg",
  },
  ax: {
    id: "ax",
    name: "Åland Islands",
    flag: "🇦🇽",
    alpha2: "ax",
    alpha3: "ala",
    ioc: "",
  },
  al: {
    id: "al",
    name: "Albania",
    flag: "🇦🇱",
    alpha2: "al",
    alpha3: "alb",
    ioc: "alb",
  },
  dz: {
    id: "dz",
    name: "Algeria",
    flag: "🇩🇿",
    alpha2: "dz",
    alpha3: "dza",
    ioc: "alg",
  },
  as: {
    id: "as",
    name: "American Samoa",
    flag: "🇦🇸",
    alpha2: "as",
    alpha3: "asm",
    ioc: "asa",
  },
  ad: {
    id: "ad",
    name: "Andorra",
    flag: "🇦🇩",
    alpha2: "ad",
    alpha3: "and",
    ioc: "and",
  },
  ao: {
    id: "ao",
    name: "Angola",
    flag: "🇦🇴",
    alpha2: "ao",
    alpha3: "ago",
    ioc: "ang",
  },
  ai: {
    id: "ai",
    name: "Anguilla",
    flag: "🇦🇮",
    alpha2: "ai",
    alpha3: "aia",
    ioc: "",
  },
  aq: {
    id: "aq",
    name: "Antarctica",
    flag: "🇦🇶",
    alpha2: "aq",
    alpha3: "ata",
    ioc: "",
  },
  ag: {
    id: "ag",
    name: "Antigua And Barbuda",
    flag: "🇦🇬",
    alpha2: "ag",
    alpha3: "atg",
    ioc: "ant",
  },
  ar: {
    id: "ar",
    name: "Argentina",
    flag: "🇦🇷",
    alpha2: "ar",
    alpha3: "arg",
    ioc: "arg",
  },
  am: {
    id: "am",
    name: "Armenia",
    flag: "🇦🇲",
    alpha2: "am",
    alpha3: "arm",
    ioc: "arm",
  },
  aw: {
    id: "aw",
    name: "Aruba",
    flag: "🇦🇼",
    alpha2: "aw",
    alpha3: "abw",
    ioc: "aru",
  },
  au: {
    id: "au",
    name: "Australia",
    flag: "🇦🇺",
    alpha2: "au",
    alpha3: "aus",
    ioc: "aus",
  },
  at: {
    id: "at",
    name: "Austria",
    flag: "🇦🇹",
    alpha2: "at",
    alpha3: "aut",
    ioc: "aut",
  },
  az: {
    id: "az",
    name: "Azerbaijan",
    flag: "🇦🇿",
    alpha2: "az",
    alpha3: "aze",
    ioc: "aze",
  },
  bs: {
    id: "bs",
    name: "Bahamas",
    flag: "🇧🇸",
    alpha2: "bs",
    alpha3: "bhs",
    ioc: "bah",
  },
  bh: {
    id: "bh",
    name: "Bahrain",
    flag: "🇧🇭",
    alpha2: "bh",
    alpha3: "bhr",
    ioc: "brn",
  },
  bd: {
    id: "bd",
    name: "Bangladesh",
    flag: "🇧🇩",
    alpha2: "bd",
    alpha3: "bgd",
    ioc: "ban",
  },
  bb: {
    id: "bb",
    name: "Barbados",
    flag: "🇧🇧",
    alpha2: "bb",
    alpha3: "brb",
    ioc: "bar",
  },
  by: {
    id: "by",
    name: "Belarus",
    flag: "🇧🇾",
    alpha2: "by",
    alpha3: "blr",
    ioc: "blr",
  },
  be: {
    id: "be",
    name: "Belgium",
    flag: "🇧🇪",
    alpha2: "be",
    alpha3: "bel",
    ioc: "bel",
  },
  bz: {
    id: "bz",
    name: "Belize",
    flag: "🇧🇿",
    alpha2: "bz",
    alpha3: "blz",
    ioc: "biz",
  },
  bj: {
    id: "bj",
    name: "Benin",
    flag: "🇧🇯",
    alpha2: "bj",
    alpha3: "ben",
    ioc: "ben",
  },
  bm: {
    id: "bm",
    name: "Bermuda",
    flag: "🇧🇲",
    alpha2: "bm",
    alpha3: "bmu",
    ioc: "ber",
  },
  bt: {
    id: "bt",
    name: "Bhutan",
    flag: "🇧🇹",
    alpha2: "bt",
    alpha3: "btn",
    ioc: "bhu",
  },
  bo: {
    id: "bo",
    name: "Bolivia, Plurinational State Of",
    flag: "🇧🇴",
    alpha2: "bo",
    alpha3: "bol",
    ioc: "bol",
  },
  bq: {
    id: "bq",
    name: "Bonaire, Saint Eustatius And Saba",
    flag: "🇧🇶",
    alpha2: "bq",
    alpha3: "bes",
    ioc: "",
  },
  ba: {
    id: "ba",
    name: "Bosnia & Herzegovina",
    flag: "🇧🇦",
    alpha2: "ba",
    alpha3: "bih",
    ioc: "bih",
  },
  bw: {
    id: "bw",
    name: "Botswana",
    flag: "🇧🇼",
    alpha2: "bw",
    alpha3: "bwa",
    ioc: "bot",
  },
  bv: {
    id: "bv",
    name: "Bouvet Island",
    flag: "🇧🇻",
    alpha2: "bv",
    alpha3: "bvt",
    ioc: "",
  },
  br: {
    id: "br",
    name: "Brazil",
    flag: "🇧🇷",
    alpha2: "br",
    alpha3: "bra",
    ioc: "bra",
  },
  io: {
    id: "io",
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    alpha2: "io",
    alpha3: "iot",
    ioc: "",
  },
  bn: {
    id: "bn",
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    alpha2: "bn",
    alpha3: "brn",
    ioc: "bru",
  },
  bg: {
    id: "bg",
    name: "Bulgaria",
    flag: "🇧🇬",
    alpha2: "bg",
    alpha3: "bgr",
    ioc: "bul",
  },
  bf: {
    id: "bf",
    name: "Burkina Faso",
    flag: "🇧🇫",
    alpha2: "bf",
    alpha3: "bfa",
    ioc: "bur",
  },
  bi: {
    id: "bi",
    name: "Burundi",
    flag: "🇧🇮",
    alpha2: "bi",
    alpha3: "bdi",
    ioc: "bdi",
  },
  cv: {
    id: "cv",
    name: "Cabo Verde",
    flag: "🇨🇻",
    alpha2: "cv",
    alpha3: "cpv",
    ioc: "cpv",
  },
  kh: {
    id: "kh",
    name: "Cambodia",
    flag: "🇰🇭",
    alpha2: "kh",
    alpha3: "khm",
    ioc: "cam",
  },
  cm: {
    id: "cm",
    name: "Cameroon",
    flag: "🇨🇲",
    alpha2: "cm",
    alpha3: "cmr",
    ioc: "cmr",
  },
  ca: {
    id: "ca",
    name: "Canada",
    flag: "🇨🇦",
    alpha2: "ca",
    alpha3: "can",
    ioc: "can",
  },
  ky: {
    id: "ky",
    name: "Cayman Islands",
    flag: "🇰🇾",
    alpha2: "ky",
    alpha3: "cym",
    ioc: "cay",
  },
  cf: {
    id: "cf",
    name: "Central African Republic",
    flag: "🇨🇫",
    alpha2: "cf",
    alpha3: "caf",
    ioc: "caf",
  },
  td: {
    id: "td",
    name: "Chad",
    flag: "🇹🇩",
    alpha2: "td",
    alpha3: "tcd",
    ioc: "cha",
  },
  cl: {
    id: "cl",
    name: "Chile",
    flag: "🇨🇱",
    alpha2: "cl",
    alpha3: "chl",
    ioc: "chi",
  },
  cn: {
    id: "cn",
    name: "China",
    flag: "🇨🇳",
    alpha2: "cn",
    alpha3: "chn",
    ioc: "chn",
  },
  cx: {
    id: "cx",
    name: "Christmas Island",
    flag: "🇨🇽",
    alpha2: "cx",
    alpha3: "cxr",
    ioc: "",
  },
  cc: {
    id: "cc",
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    alpha2: "cc",
    alpha3: "cck",
    ioc: "",
  },
  co: {
    id: "co",
    name: "Colombia",
    flag: "🇨🇴",
    alpha2: "co",
    alpha3: "col",
    ioc: "col",
  },
  km: {
    id: "km",
    name: "Comoros",
    flag: "🇰🇲",
    alpha2: "km",
    alpha3: "com",
    ioc: "com",
  },
  ck: {
    id: "ck",
    name: "Cook Islands",
    flag: "🇨🇰",
    alpha2: "ck",
    alpha3: "cok",
    ioc: "cok",
  },
  cr: {
    id: "cr",
    name: "Costa Rica",
    flag: "🇨🇷",
    alpha2: "cr",
    alpha3: "cri",
    ioc: "crc",
  },
  hr: {
    id: "hr",
    name: "Croatia",
    flag: "🇭🇷",
    alpha2: "hr",
    alpha3: "hrv",
    ioc: "cro",
  },
  cu: {
    id: "cu",
    name: "Cuba",
    flag: "🇨🇺",
    alpha2: "cu",
    alpha3: "cub",
    ioc: "cub",
  },
  cw: {
    id: "cw",
    name: "Curacao",
    flag: "🇨🇼",
    alpha2: "cw",
    alpha3: "cuw",
    ioc: "",
  },
  cy: {
    id: "cy",
    name: "Cyprus",
    flag: "🇨🇾",
    alpha2: "cy",
    alpha3: "cyp",
    ioc: "cyp",
  },
  cz: {
    id: "cz",
    name: "Czech Republic",
    flag: "🇨🇿",
    alpha2: "cz",
    alpha3: "cze",
    ioc: "cze",
  },
  ci: {
    id: "ci",
    name: "Côte d'Ivoire",
    flag: "🇨🇮",
    alpha2: "ci",
    alpha3: "civ",
    ioc: "civ",
  },
  cd: {
    id: "cd",
    name: "Democratic Republic Of Congo",
    flag: "🇨🇩",
    alpha2: "cd",
    alpha3: "cod",
    ioc: "cod",
  },
  dk: {
    id: "dk",
    name: "Denmark",
    flag: "🇩🇰",
    alpha2: "dk",
    alpha3: "dnk",
    ioc: "den",
  },
  dj: {
    id: "dj",
    name: "Djibouti",
    flag: "🇩🇯",
    alpha2: "dj",
    alpha3: "dji",
    ioc: "dji",
  },
  dm: {
    id: "dm",
    name: "Dominica",
    flag: "🇩🇲",
    alpha2: "dm",
    alpha3: "dma",
    ioc: "dma",
  },
  do: {
    id: "do",
    name: "Dominican Republic",
    flag: "🇩🇴",
    alpha2: "do",
    alpha3: "dom",
    ioc: "dom",
  },
  ec: {
    id: "ec",
    name: "Ecuador",
    flag: "🇪🇨",
    alpha2: "ec",
    alpha3: "ecu",
    ioc: "ecu",
  },
  eg: {
    id: "eg",
    name: "Egypt",
    flag: "🇪🇬",
    alpha2: "eg",
    alpha3: "egy",
    ioc: "egy",
  },
  sv: {
    id: "sv",
    name: "El Salvador",
    flag: "🇸🇻",
    alpha2: "sv",
    alpha3: "slv",
    ioc: "esa",
  },
  gq: {
    id: "gq",
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    alpha2: "gq",
    alpha3: "gnq",
    ioc: "geq",
  },
  er: {
    id: "er",
    name: "Eritrea",
    flag: "🇪🇷",
    alpha2: "er",
    alpha3: "eri",
    ioc: "eri",
  },
  ee: {
    id: "ee",
    name: "Estonia",
    flag: "🇪🇪",
    alpha2: "ee",
    alpha3: "est",
    ioc: "est",
  },
  et: {
    id: "et",
    name: "Ethiopia",
    flag: "🇪🇹",
    alpha2: "et",
    alpha3: "eth",
    ioc: "eth",
  },
  fk: {
    id: "fk",
    name: "Falkland Islands",
    flag: "🇫🇰",
    alpha2: "fk",
    alpha3: "flk",
    ioc: "",
  },
  fo: {
    id: "fo",
    name: "Faroe Islands",
    flag: "🇫🇴",
    alpha2: "fo",
    alpha3: "fro",
    ioc: "fai",
  },
  fj: {
    id: "fj",
    name: "Fiji",
    flag: "🇫🇯",
    alpha2: "fj",
    alpha3: "fji",
    ioc: "fij",
  },
  fi: {
    id: "fi",
    name: "Finland",
    flag: "🇫🇮",
    alpha2: "fi",
    alpha3: "fin",
    ioc: "fin",
  },
  fr: {
    id: "fr",
    name: "France",
    flag: "🇫🇷",
    alpha2: "fr",
    alpha3: "fra",
    ioc: "fra",
  },
  gf: {
    id: "gf",
    name: "French Guiana",
    flag: "🇬🇫",
    alpha2: "gf",
    alpha3: "guf",
    ioc: "",
  },
  pf: {
    id: "pf",
    name: "French Polynesia",
    flag: "🇵🇫",
    alpha2: "pf",
    alpha3: "pyf",
    ioc: "",
  },
  tf: {
    id: "tf",
    name: "French Southern Territories",
    flag: "🇹🇫",
    alpha2: "tf",
    alpha3: "atf",
    ioc: "",
  },
  ga: {
    id: "ga",
    name: "Gabon",
    flag: "🇬🇦",
    alpha2: "ga",
    alpha3: "gab",
    ioc: "gab",
  },
  gm: {
    id: "gm",
    name: "Gambia",
    flag: "🇬🇲",
    alpha2: "gm",
    alpha3: "gmb",
    ioc: "gam",
  },
  ge: {
    id: "ge",
    name: "Georgia",
    flag: "🇬🇪",
    alpha2: "ge",
    alpha3: "geo",
    ioc: "geo",
  },
  de: {
    id: "de",
    name: "Germany",
    flag: "🇩🇪",
    alpha2: "de",
    alpha3: "deu",
    ioc: "ger",
  },
  gh: {
    id: "gh",
    name: "Ghana",
    flag: "🇬🇭",
    alpha2: "gh",
    alpha3: "gha",
    ioc: "gha",
  },
  gi: {
    id: "gi",
    name: "Gibraltar",
    flag: "🇬🇮",
    alpha2: "gi",
    alpha3: "gib",
    ioc: "",
  },
  gr: {
    id: "gr",
    name: "Greece",
    flag: "🇬🇷",
    alpha2: "gr",
    alpha3: "grc",
    ioc: "gre",
  },
  gl: {
    id: "gl",
    name: "Greenland",
    flag: "🇬🇱",
    alpha2: "gl",
    alpha3: "grl",
    ioc: "",
  },
  gd: {
    id: "gd",
    name: "Grenada",
    flag: "🇬🇩",
    alpha2: "gd",
    alpha3: "grd",
    ioc: "grn",
  },
  gp: {
    id: "gp",
    name: "Guadeloupe",
    flag: "🇬🇵",
    alpha2: "gp",
    alpha3: "glp",
    ioc: "",
  },
  gu: {
    id: "gu",
    name: "Guam",
    flag: "🇬🇺",
    alpha2: "gu",
    alpha3: "gum",
    ioc: "gum",
  },
  gt: {
    id: "gt",
    name: "Guatemala",
    flag: "🇬🇹",
    alpha2: "gt",
    alpha3: "gtm",
    ioc: "gua",
  },
  gg: {
    id: "gg",
    name: "Guernsey",
    flag: "🇬🇬",
    alpha2: "gg",
    alpha3: "ggy",
    ioc: "gci",
  },
  gn: {
    id: "gn",
    name: "Guinea",
    flag: "🇬🇳",
    alpha2: "gn",
    alpha3: "gin",
    ioc: "gui",
  },
  gw: {
    id: "gw",
    name: "Guinea-bissau",
    flag: "🇬🇼",
    alpha2: "gw",
    alpha3: "gnb",
    ioc: "gbs",
  },
  gy: {
    id: "gy",
    name: "Guyana",
    flag: "🇬🇾",
    alpha2: "gy",
    alpha3: "guy",
    ioc: "guy",
  },
  ht: {
    id: "ht",
    name: "Haiti",
    flag: "🇭🇹",
    alpha2: "ht",
    alpha3: "hti",
    ioc: "hai",
  },
  hm: {
    id: "hm",
    name: "Heard Island And McDonald Islands",
    flag: "🇭🇲",
    alpha2: "hm",
    alpha3: "hmd",
    ioc: "",
  },
  hn: {
    id: "hn",
    name: "Honduras",
    flag: "🇭🇳",
    alpha2: "hn",
    alpha3: "hnd",
    ioc: "hon",
  },
  hk: {
    id: "hk",
    name: "Hong Kong",
    flag: "🇭🇰",
    alpha2: "hk",
    alpha3: "hkg",
    ioc: "hkg",
  },
  hu: {
    id: "hu",
    name: "Hungary",
    flag: "🇭🇺",
    alpha2: "hu",
    alpha3: "hun",
    ioc: "hun",
  },
  is: {
    id: "is",
    name: "Iceland",
    flag: "🇮🇸",
    alpha2: "is",
    alpha3: "isl",
    ioc: "isl",
  },
  in: {
    id: "in",
    name: "India",
    flag: "🇮🇳",
    alpha2: "in",
    alpha3: "ind",
    ioc: "ind",
  },
  id: {
    id: "id",
    name: "Indonesia",
    flag: "🇮🇩",
    alpha2: "id",
    alpha3: "idn",
    ioc: "ina",
  },
  ir: {
    id: "ir",
    name: "Iran, Islamic Republic Of",
    flag: "🇮🇷",
    alpha2: "ir",
    alpha3: "irn",
    ioc: "iri",
  },
  iq: {
    id: "iq",
    name: "Iraq",
    flag: "🇮🇶",
    alpha2: "iq",
    alpha3: "irq",
    ioc: "irq",
  },
  ie: {
    id: "ie",
    name: "Ireland",
    flag: "🇮🇪",
    alpha2: "ie",
    alpha3: "irl",
    ioc: "irl",
  },
  im: {
    id: "im",
    name: "Isle Of Man",
    flag: "🇮🇲",
    alpha2: "im",
    alpha3: "imn",
    ioc: "",
  },
  il: {
    id: "il",
    name: "Israel",
    flag: "🇮🇱",
    alpha2: "il",
    alpha3: "isr",
    ioc: "isr",
  },
  it: {
    id: "it",
    name: "Italy",
    flag: "🇮🇹",
    alpha2: "it",
    alpha3: "ita",
    ioc: "ita",
  },
  jm: {
    id: "jm",
    name: "Jamaica",
    flag: "🇯🇲",
    alpha2: "jm",
    alpha3: "jam",
    ioc: "jam",
  },
  jp: {
    id: "jp",
    name: "Japan",
    flag: "🇯🇵",
    alpha2: "jp",
    alpha3: "jpn",
    ioc: "jpn",
  },
  je: {
    id: "je",
    name: "Jersey",
    flag: "🇯🇪",
    alpha2: "je",
    alpha3: "jey",
    ioc: "jci",
  },
  jo: {
    id: "jo",
    name: "Jordan",
    flag: "🇯🇴",
    alpha2: "jo",
    alpha3: "jor",
    ioc: "jor",
  },
  kz: {
    id: "kz",
    name: "Kazakhstan",
    flag: "🇰🇿",
    alpha2: "kz",
    alpha3: "kaz",
    ioc: "kaz",
  },
  ke: {
    id: "ke",
    name: "Kenya",
    flag: "🇰🇪",
    alpha2: "ke",
    alpha3: "ken",
    ioc: "ken",
  },
  ki: {
    id: "ki",
    name: "Kiribati",
    flag: "🇰🇮",
    alpha2: "ki",
    alpha3: "kir",
    ioc: "kir",
  },
  kp: {
    id: "kp",
    name: "Korea, Democratic People's Republic Of",
    flag: "🇰🇵",
    alpha2: "kp",
    alpha3: "prk",
    ioc: "prk",
  },
  kr: {
    id: "kr",
    name: "Korea, Republic Of",
    flag: "🇰🇷",
    alpha2: "kr",
    alpha3: "kor",
    ioc: "kor",
  },
  kw: {
    id: "kw",
    name: "Kuwait",
    flag: "🇰🇼",
    alpha2: "kw",
    alpha3: "kwt",
    ioc: "kuw",
  },
  kg: {
    id: "kg",
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    alpha2: "kg",
    alpha3: "kgz",
    ioc: "kgz",
  },
  la: {
    id: "la",
    name: "Lao People's Democratic Republic",
    flag: "🇱🇦",
    alpha2: "la",
    alpha3: "lao",
    ioc: "lao",
  },
  lv: {
    id: "lv",
    name: "Latvia",
    flag: "🇱🇻",
    alpha2: "lv",
    alpha3: "lva",
    ioc: "lat",
  },
  lb: {
    id: "lb",
    name: "Lebanon",
    flag: "🇱🇧",
    alpha2: "lb",
    alpha3: "lbn",
    ioc: "lib",
  },
  ls: {
    id: "ls",
    name: "Lesotho",
    flag: "🇱🇸",
    alpha2: "ls",
    alpha3: "lso",
    ioc: "les",
  },
  lr: {
    id: "lr",
    name: "Liberia",
    flag: "🇱🇷",
    alpha2: "lr",
    alpha3: "lbr",
    ioc: "lbr",
  },
  ly: {
    id: "ly",
    name: "Libya",
    flag: "🇱🇾",
    alpha2: "ly",
    alpha3: "lby",
    ioc: "lba",
  },
  li: {
    id: "li",
    name: "Liechtenstein",
    flag: "🇱🇮",
    alpha2: "li",
    alpha3: "lie",
    ioc: "lie",
  },
  lt: {
    id: "lt",
    name: "Lithuania",
    flag: "🇱🇹",
    alpha2: "lt",
    alpha3: "ltu",
    ioc: "ltu",
  },
  lu: {
    id: "lu",
    name: "Luxembourg",
    flag: "🇱🇺",
    alpha2: "lu",
    alpha3: "lux",
    ioc: "lux",
  },
  mo: {
    id: "mo",
    name: "Macao",
    flag: "🇲🇴",
    alpha2: "mo",
    alpha3: "mac",
    ioc: "mac",
  },
  mk: {
    id: "mk",
    name: "Macedonia, The Former Yugoslav Republic Of",
    flag: "🇲🇰",
    alpha2: "mk",
    alpha3: "mkd",
    ioc: "mkd",
  },
  mg: {
    id: "mg",
    name: "Madagascar",
    flag: "🇲🇬",
    alpha2: "mg",
    alpha3: "mdg",
    ioc: "mad",
  },
  mw: {
    id: "mw",
    name: "Malawi",
    flag: "🇲🇼",
    alpha2: "mw",
    alpha3: "mwi",
    ioc: "maw",
  },
  my: {
    id: "my",
    name: "Malaysia",
    flag: "🇲🇾",
    alpha2: "my",
    alpha3: "mys",
    ioc: "mas",
  },
  mv: {
    id: "mv",
    name: "Maldives",
    flag: "🇲🇻",
    alpha2: "mv",
    alpha3: "mdv",
    ioc: "mdv",
  },
  ml: {
    id: "ml",
    name: "Mali",
    flag: "🇲🇱",
    alpha2: "ml",
    alpha3: "mli",
    ioc: "mli",
  },
  mt: {
    id: "mt",
    name: "Malta",
    flag: "🇲🇹",
    alpha2: "mt",
    alpha3: "mlt",
    ioc: "mlt",
  },
  mh: {
    id: "mh",
    name: "Marshall Islands",
    flag: "🇲🇭",
    alpha2: "mh",
    alpha3: "mhl",
    ioc: "mhl",
  },
  mq: {
    id: "mq",
    name: "Martinique",
    flag: "🇲🇶",
    alpha2: "mq",
    alpha3: "mtq",
    ioc: "",
  },
  mr: {
    id: "mr",
    name: "Mauritania",
    flag: "🇲🇷",
    alpha2: "mr",
    alpha3: "mrt",
    ioc: "mtn",
  },
  mu: {
    id: "mu",
    name: "Mauritius",
    flag: "🇲🇺",
    alpha2: "mu",
    alpha3: "mus",
    ioc: "mri",
  },
  yt: {
    id: "yt",
    name: "Mayotte",
    flag: "🇾🇹",
    alpha2: "yt",
    alpha3: "myt",
    ioc: "",
  },
  mx: {
    id: "mx",
    name: "Mexico",
    flag: "🇲🇽",
    alpha2: "mx",
    alpha3: "mex",
    ioc: "mex",
  },
  fm: {
    id: "fm",
    name: "Micronesia, Federated States Of",
    flag: "🇫🇲",
    alpha2: "fm",
    alpha3: "fsm",
    ioc: "fsm",
  },
  md: {
    id: "md",
    name: "Moldova",
    flag: "🇲🇩",
    alpha2: "md",
    alpha3: "mda",
    ioc: "mda",
  },
  mc: {
    id: "mc",
    name: "Monaco",
    flag: "🇲🇨",
    alpha2: "mc",
    alpha3: "mco",
    ioc: "mon",
  },
  mn: {
    id: "mn",
    name: "Mongolia",
    flag: "🇲🇳",
    alpha2: "mn",
    alpha3: "mng",
    ioc: "mgl",
  },
  me: {
    id: "me",
    name: "Montenegro",
    flag: "🇲🇪",
    alpha2: "me",
    alpha3: "mne",
    ioc: "mne",
  },
  ms: {
    id: "ms",
    name: "Montserrat",
    flag: "🇲🇸",
    alpha2: "ms",
    alpha3: "msr",
    ioc: "",
  },
  ma: {
    id: "ma",
    name: "Morocco",
    flag: "🇲🇦",
    alpha2: "ma",
    alpha3: "mar",
    ioc: "mar",
  },
  mz: {
    id: "mz",
    name: "Mozambique",
    flag: "🇲🇿",
    alpha2: "mz",
    alpha3: "moz",
    ioc: "moz",
  },
  mm: {
    id: "mm",
    name: "Myanmar",
    flag: "🇲🇲",
    alpha2: "mm",
    alpha3: "mmr",
    ioc: "mya",
  },
  na: {
    id: "na",
    name: "Namibia",
    flag: "🇳🇦",
    alpha2: "na",
    alpha3: "nam",
    ioc: "nam",
  },
  nr: {
    id: "nr",
    name: "Nauru",
    flag: "🇳🇷",
    alpha2: "nr",
    alpha3: "nru",
    ioc: "nru",
  },
  np: {
    id: "np",
    name: "Nepal",
    flag: "🇳🇵",
    alpha2: "np",
    alpha3: "npl",
    ioc: "nep",
  },
  nl: {
    id: "nl",
    name: "Netherlands",
    flag: "🇳🇱",
    alpha2: "nl",
    alpha3: "nld",
    ioc: "ned",
  },
  nc: {
    id: "nc",
    name: "New Caledonia",
    flag: "🇳🇨",
    alpha2: "nc",
    alpha3: "ncl",
    ioc: "",
  },
  nz: {
    id: "nz",
    name: "New Zealand",
    flag: "🇳🇿",
    alpha2: "nz",
    alpha3: "nzl",
    ioc: "nzl",
  },
  ni: {
    id: "ni",
    name: "Nicaragua",
    flag: "🇳🇮",
    alpha2: "ni",
    alpha3: "nic",
    ioc: "nca",
  },
  ne: {
    id: "ne",
    name: "Niger",
    flag: "🇳🇪",
    alpha2: "ne",
    alpha3: "ner",
    ioc: "nig",
  },
  ng: {
    id: "ng",
    name: "Nigeria",
    flag: "🇳🇬",
    alpha2: "ng",
    alpha3: "nga",
    ioc: "ngr",
  },
  nu: {
    id: "nu",
    name: "Niue",
    flag: "🇳🇺",
    alpha2: "nu",
    alpha3: "niu",
    ioc: "",
  },
  nf: {
    id: "nf",
    name: "Norfolk Island",
    flag: "🇳🇫",
    alpha2: "nf",
    alpha3: "nfk",
    ioc: "",
  },
  mp: {
    id: "mp",
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    alpha2: "mp",
    alpha3: "mnp",
    ioc: "",
  },
  no: {
    id: "no",
    name: "Norway",
    flag: "🇳🇴",
    alpha2: "no",
    alpha3: "nor",
    ioc: "nor",
  },
  om: {
    id: "om",
    name: "Oman",
    flag: "🇴🇲",
    alpha2: "om",
    alpha3: "omn",
    ioc: "oma",
  },
  pk: {
    id: "pk",
    name: "Pakistan",
    flag: "🇵🇰",
    alpha2: "pk",
    alpha3: "pak",
    ioc: "pak",
  },
  pw: {
    id: "pw",
    name: "Palau",
    flag: "🇵🇼",
    alpha2: "pw",
    alpha3: "plw",
    ioc: "plw",
  },
  ps: {
    id: "ps",
    name: "Palestinian Territory, Occupied",
    flag: "🇵🇸",
    alpha2: "ps",
    alpha3: "pse",
    ioc: "ple",
  },
  pa: {
    id: "pa",
    name: "Panama",
    flag: "🇵🇦",
    alpha2: "pa",
    alpha3: "pan",
    ioc: "pan",
  },
  pg: {
    id: "pg",
    name: "Papua New Guinea",
    flag: "🇵🇬",
    alpha2: "pg",
    alpha3: "png",
    ioc: "png",
  },
  py: {
    id: "py",
    name: "Paraguay",
    flag: "🇵🇾",
    alpha2: "py",
    alpha3: "pry",
    ioc: "par",
  },
  pe: {
    id: "pe",
    name: "Peru",
    flag: "🇵🇪",
    alpha2: "pe",
    alpha3: "per",
    ioc: "per",
  },
  ph: {
    id: "ph",
    name: "Philippines",
    flag: "🇵🇭",
    alpha2: "ph",
    alpha3: "phl",
    ioc: "phi",
  },
  pn: {
    id: "pn",
    name: "Pitcairn",
    flag: "🇵🇳",
    alpha2: "pn",
    alpha3: "pcn",
    ioc: "",
  },
  pl: {
    id: "pl",
    name: "Poland",
    flag: "🇵🇱",
    alpha2: "pl",
    alpha3: "pol",
    ioc: "pol",
  },
  pt: {
    id: "pt",
    name: "Portugal",
    flag: "🇵🇹",
    alpha2: "pt",
    alpha3: "prt",
    ioc: "por",
  },
  pr: {
    id: "pr",
    name: "Puerto Rico",
    flag: "🇵🇷",
    alpha2: "pr",
    alpha3: "pri",
    ioc: "pur",
  },
  qa: {
    id: "qa",
    name: "Qatar",
    flag: "🇶🇦",
    alpha2: "qa",
    alpha3: "qat",
    ioc: "qat",
  },
  cg: {
    id: "cg",
    name: "Republic Of Congo",
    flag: "🇨🇬",
    alpha2: "cg",
    alpha3: "cog",
    ioc: "cgo",
  },
  re: {
    id: "re",
    name: "Reunion",
    flag: "🇷🇪",
    alpha2: "re",
    alpha3: "reu",
    ioc: "",
  },
  ro: {
    id: "ro",
    name: "Romania",
    flag: "🇷🇴",
    alpha2: "ro",
    alpha3: "rou",
    ioc: "rou",
  },
  ru: {
    id: "ru",
    name: "Russian Federation",
    flag: "🇷🇺",
    alpha2: "ru",
    alpha3: "rus",
    ioc: "rus",
  },
  rw: {
    id: "rw",
    name: "Rwanda",
    flag: "🇷🇼",
    alpha2: "rw",
    alpha3: "rwa",
    ioc: "rwa",
  },
  bl: {
    id: "bl",
    name: "Saint Barthélemy",
    flag: "🇧🇱",
    alpha2: "bl",
    alpha3: "blm",
    ioc: "",
  },
  sh: {
    id: "sh",
    name: "Saint Helena, Ascension And Tristan Da Cunha",
    flag: "🇸🇭",
    alpha2: "sh",
    alpha3: "shn",
    ioc: "",
  },
  kn: {
    id: "kn",
    name: "Saint Kitts And Nevis",
    flag: "🇰🇳",
    alpha2: "kn",
    alpha3: "kna",
    ioc: "skn",
  },
  lc: {
    id: "lc",
    name: "Saint Lucia",
    flag: "🇱🇨",
    alpha2: "lc",
    alpha3: "lca",
    ioc: "lca",
  },
  mf: {
    id: "mf",
    name: "Saint Martin",
    flag: "🇲🇫",
    alpha2: "mf",
    alpha3: "maf",
    ioc: "",
  },
  pm: {
    id: "pm",
    name: "Saint Pierre And Miquelon",
    flag: "🇵🇲",
    alpha2: "pm",
    alpha3: "spm",
    ioc: "",
  },
  vc: {
    id: "vc",
    name: "Saint Vincent And The Grenadines",
    flag: "🇻🇨",
    alpha2: "vc",
    alpha3: "vct",
    ioc: "vin",
  },
  ws: {
    id: "ws",
    name: "Samoa",
    flag: "🇼🇸",
    alpha2: "ws",
    alpha3: "wsm",
    ioc: "sam",
  },
  sm: {
    id: "sm",
    name: "San Marino",
    flag: "🇸🇲",
    alpha2: "sm",
    alpha3: "smr",
    ioc: "smr",
  },
  st: {
    id: "st",
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    alpha2: "st",
    alpha3: "stp",
    ioc: "stp",
  },
  sa: {
    id: "sa",
    name: "Saudi Arabia",
    flag: "🇸🇦",
    alpha2: "sa",
    alpha3: "sau",
    ioc: "ksa",
  },
  sn: {
    id: "sn",
    name: "Senegal",
    flag: "🇸🇳",
    alpha2: "sn",
    alpha3: "sen",
    ioc: "sen",
  },
  rs: {
    id: "rs",
    name: "Serbia",
    flag: "🇷🇸",
    alpha2: "rs",
    alpha3: "srb",
    ioc: "srb",
  },
  sc: {
    id: "sc",
    name: "Seychelles",
    flag: "🇸🇨",
    alpha2: "sc",
    alpha3: "syc",
    ioc: "sey",
  },
  sl: {
    id: "sl",
    name: "Sierra Leone",
    flag: "🇸🇱",
    alpha2: "sl",
    alpha3: "sle",
    ioc: "sle",
  },
  sg: {
    id: "sg",
    name: "Singapore",
    flag: "🇸🇬",
    alpha2: "sg",
    alpha3: "sgp",
    ioc: "sin",
  },
  sx: {
    id: "sx",
    name: "Sint Maarten",
    flag: "🇸🇽",
    alpha2: "sx",
    alpha3: "sxm",
    ioc: "",
  },
  sk: {
    id: "sk",
    name: "Slovakia",
    flag: "🇸🇰",
    alpha2: "sk",
    alpha3: "svk",
    ioc: "svk",
  },
  si: {
    id: "si",
    name: "Slovenia",
    flag: "🇸🇮",
    alpha2: "si",
    alpha3: "svn",
    ioc: "slo",
  },
  sb: {
    id: "sb",
    name: "Solomon Islands",
    flag: "🇸🇧",
    alpha2: "sb",
    alpha3: "slb",
    ioc: "sol",
  },
  so: {
    id: "so",
    name: "Somalia",
    flag: "🇸🇴",
    alpha2: "so",
    alpha3: "som",
    ioc: "som",
  },
  za: {
    id: "za",
    name: "South Africa",
    flag: "🇿🇦",
    alpha2: "za",
    alpha3: "zaf",
    ioc: "rsa",
  },
  gs: {
    id: "gs",
    name: "South Georgia And The South Sandwich Islands",
    flag: "🇬🇸",
    alpha2: "gs",
    alpha3: "sgs",
    ioc: "",
  },
  ss: {
    id: "ss",
    name: "South Sudan",
    flag: "🇸🇸",
    alpha2: "ss",
    alpha3: "ssd",
    ioc: "ssd",
  },
  es: {
    id: "es",
    name: "Spain",
    flag: "🇪🇸",
    alpha2: "es",
    alpha3: "esp",
    ioc: "esp",
  },
  lk: {
    id: "lk",
    name: "Sri Lanka",
    flag: "🇱🇰",
    alpha2: "lk",
    alpha3: "lka",
    ioc: "sri",
  },
  sd: {
    id: "sd",
    name: "Sudan",
    flag: "🇸🇩",
    alpha2: "sd",
    alpha3: "sdn",
    ioc: "sud",
  },
  sr: {
    id: "sr",
    name: "Suriname",
    flag: "🇸🇷",
    alpha2: "sr",
    alpha3: "sur",
    ioc: "sur",
  },
  sj: {
    id: "sj",
    name: "Svalbard And Jan Mayen",
    flag: "🇸🇯",
    alpha2: "sj",
    alpha3: "sjm",
    ioc: "",
  },
  sz: {
    id: "sz",
    name: "Swaziland",
    flag: "🇸🇿",
    alpha2: "sz",
    alpha3: "swz",
    ioc: "swz",
  },
  se: {
    id: "se",
    name: "Sweden",
    flag: "🇸🇪",
    alpha2: "se",
    alpha3: "swe",
    ioc: "swe",
  },
  ch: {
    id: "ch",
    name: "Switzerland",
    flag: "🇨🇭",
    alpha2: "ch",
    alpha3: "che",
    ioc: "sui",
  },
  sy: {
    id: "sy",
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    alpha2: "sy",
    alpha3: "syr",
    ioc: "syr",
  },
  tw: {
    id: "tw",
    name: "Taiwan",
    flag: "🇹🇼",
    alpha2: "tw",
    alpha3: "twn",
    ioc: "tpe",
  },
  tj: {
    id: "tj",
    name: "Tajikistan",
    flag: "🇹🇯",
    alpha2: "tj",
    alpha3: "tjk",
    ioc: "tjk",
  },
  tz: {
    id: "tz",
    name: "Tanzania, United Republic Of",
    flag: "🇹🇿",
    alpha2: "tz",
    alpha3: "tza",
    ioc: "tan",
  },
  th: {
    id: "th",
    name: "Thailand",
    flag: "🇹🇭",
    alpha2: "th",
    alpha3: "tha",
    ioc: "tha",
  },
  tl: {
    id: "tl",
    name: "Timor-Leste, Democratic Republic of",
    flag: "🇹🇱",
    alpha2: "tl",
    alpha3: "tls",
    ioc: "tls",
  },
  tg: {
    id: "tg",
    name: "Togo",
    flag: "🇹🇬",
    alpha2: "tg",
    alpha3: "tgo",
    ioc: "tog",
  },
  tk: {
    id: "tk",
    name: "Tokelau",
    flag: "🇹🇰",
    alpha2: "tk",
    alpha3: "tkl",
    ioc: "",
  },
  to: {
    id: "to",
    name: "Tonga",
    flag: "🇹🇴",
    alpha2: "to",
    alpha3: "ton",
    ioc: "tga",
  },
  tt: {
    id: "tt",
    name: "Trinidad And Tobago",
    flag: "🇹🇹",
    alpha2: "tt",
    alpha3: "tto",
    ioc: "tto",
  },
  tn: {
    id: "tn",
    name: "Tunisia",
    flag: "🇹🇳",
    alpha2: "tn",
    alpha3: "tun",
    ioc: "tun",
  },
  tr: {
    id: "tr",
    name: "Turkey",
    flag: "🇹🇷",
    alpha2: "tr",
    alpha3: "tur",
    ioc: "tur",
  },
  tm: {
    id: "tm",
    name: "Turkmenistan",
    flag: "🇹🇲",
    alpha2: "tm",
    alpha3: "tkm",
    ioc: "tkm",
  },
  tc: {
    id: "tc",
    name: "Turks And Caicos Islands",
    flag: "🇹🇨",
    alpha2: "tc",
    alpha3: "tca",
    ioc: "",
  },
  tv: {
    id: "tv",
    name: "Tuvalu",
    flag: "🇹🇻",
    alpha2: "tv",
    alpha3: "tuv",
    ioc: "tuv",
  },
  ug: {
    id: "ug",
    name: "Uganda",
    flag: "🇺🇬",
    alpha2: "ug",
    alpha3: "uga",
    ioc: "uga",
  },
  ua: {
    id: "ua",
    name: "Ukraine",
    flag: "🇺🇦",
    alpha2: "ua",
    alpha3: "ukr",
    ioc: "ukr",
  },
  ae: {
    id: "ae",
    name: "United Arab Emirates",
    flag: "🇦🇪",
    alpha2: "ae",
    alpha3: "are",
    ioc: "uae",
  },
  gb: {
    id: "gb",
    name: "United Kingdom",
    flag: "🇬🇧",
    alpha2: "gb",
    alpha3: "gbr",
    ioc: "gbr",
  },
  us: {
    id: "us",
    name: "United States",
    flag: "🇺🇸",
    alpha2: "us",
    alpha3: "usa",
    ioc: "usa",
  },
  um: {
    id: "um",
    name: "United States Minor Outlying Islands",
    flag: "🇺🇲",
    alpha2: "um",
    alpha3: "umi",
    ioc: "",
  },
  uy: {
    id: "uy",
    name: "Uruguay",
    flag: "🇺🇾",
    alpha2: "uy",
    alpha3: "ury",
    ioc: "uru",
  },
  uz: {
    id: "uz",
    name: "Uzbekistan",
    flag: "🇺🇿",
    alpha2: "uz",
    alpha3: "uzb",
    ioc: "uzb",
  },
  vu: {
    id: "vu",
    name: "Vanuatu",
    flag: "🇻🇺",
    alpha2: "vu",
    alpha3: "vut",
    ioc: "van",
  },
  va: {
    id: "va",
    name: "Vatican City State",
    flag: "🇻🇦",
    alpha2: "va",
    alpha3: "vat",
    ioc: "",
  },
  ve: {
    id: "ve",
    name: "Venezuela, Bolivarian Republic Of",
    flag: "🇻🇪",
    alpha2: "ve",
    alpha3: "ven",
    ioc: "ven",
  },
  vn: {
    id: "vn",
    name: "Viet Nam",
    flag: "🇻🇳",
    alpha2: "vn",
    alpha3: "vnm",
    ioc: "vie",
  },
  vg: {
    id: "vg",
    name: "Virgin Islands (British)",
    flag: "🇻🇬",
    alpha2: "vg",
    alpha3: "vgb",
    ioc: "ivb",
  },
  vi: {
    id: "vi",
    name: "Virgin Islands (US)",
    flag: "🇻🇮",
    alpha2: "vi",
    alpha3: "vir",
    ioc: "isv",
  },
  wf: {
    id: "wf",
    name: "Wallis And Futuna",
    flag: "🇼🇫",
    alpha2: "wf",
    alpha3: "wlf",
    ioc: "",
  },
  eh: {
    id: "eh",
    name: "Western Sahara",
    flag: "🇪🇭",
    alpha2: "eh",
    alpha3: "esh",
    ioc: "",
  },
  ye: {
    id: "ye",
    name: "Yemen",
    flag: "🇾🇪",
    alpha2: "ye",
    alpha3: "yem",
    ioc: "yem",
  },
  zm: {
    id: "zm",
    name: "Zambia",
    flag: "🇿🇲",
    alpha2: "zm",
    alpha3: "zmb",
    ioc: "zam",
  },
  zw: {
    id: "zw",
    name: "Zimbabwe",
    flag: "🇿🇼",
    alpha2: "zw",
    alpha3: "zwe",
    ioc: "zim",
  },
};

export const countryUtils = { countryIds, countries };
