import { useEffect, useState } from "react";
import clsx from "clsx";
import { Grid, Select } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EditorState, Modifier } from "draft-js";

import { MessageEditor } from "./MessageEditor";
import { Buttons, FormFields, Typography } from "src/components/Basic";
import {
  MessagingTemplatesMainContainer,
  Container,
  Wrapper,
  ButtonContainer,
  SelectContainer,
  Line,
} from "./style";
import { messageTemplateField, idPrefix } from "./utils";
import {
  getHTMLContentFromEditorState,
  getInitialContentState_WithHTML,
} from "src/components/Basic/FormFields/FormikFields/richTextArea/richTextEditor.helpers";
import { ICONS } from "src/assets/custom_Icons";
import { API_CALLS } from "src/API_CALLS";
import { useAppDispatch, useAppSelector } from "src/Redux";
import { messageTemplateActions } from "src/Redux/slices/MessageTemplate";
import { formikSubmitHandler } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import TemplateNotification from "./TemplateNotification";
import { DeactivateModal } from "../Modal";

const MessagingTemplates = () => {
  const editorStateContent = getInitialContentState_WithHTML("");
  const [editorState, setEditorState] = useState(editorStateContent);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const { Option } = Select;
  const screen = Grid.useBreakpoint();
  const dispatch = useAppDispatch();
  const { tags, templates, selectedTemplates } = useAppSelector(
    (state) => state.messageTemplate
  );

  useEffect(() => {
    API_CALLS.MESSAGE_TEMPLATE.getTag();
    API_CALLS.MESSAGE_TEMPLATE.getMessageTemplate();
  }, []);

  const onSubmitHandler = (value: FormikValues) => {
    let message = value.message;
    tags.map((tag) => (message = message.replaceAll(tag.title, tag.tag)));

    if (selectedTemplates.id) {
      API_CALLS.MESSAGE_TEMPLATE.updateMessageTemplate({
        message: message,
        subject: value.subject,
        template_name: value.template_name,
        id: selectedTemplates.id,
      });
    } else {
      API_CALLS.MESSAGE_TEMPLATE.createMessageTemplate({
        message: message,
        subject: value.subject,
        template_name: value.template_name,
      });
    }
    setEditorState(getInitialContentState_WithHTML(""));
    dispatch(messageTemplateActions.setSelectedTemplates({}));
  };

  const templateDropdownSelect = (value: string) => {
    if (value === "0") {
      dispatch(
        messageTemplateActions.setSelectedTemplates({
          id: 0,
          template_name: "",
          subject: "",
          message: "",
          template_description:
            "Add your own new template here. Write what you would like to send to the potential candidate.",
          deletable: false,
          is_default: false,
        })
      );
      setEditorState(getInitialContentState_WithHTML(""));
    } else {
      const templateIndex = templates.findIndex(
        (template) => template.id === Number(value)
      );
      let message = templates[templateIndex].message;
      tags.map((tag) => (message = message.replaceAll(tag.tag, tag.title)));

      setEditorState(
        EditorState.moveFocusToEnd(getInitialContentState_WithHTML(message))
      );

      dispatch(
        messageTemplateActions.setSelectedTemplates({
          ...templates[templateIndex],
          message,
        })
      );
    }
  };

  const tagDropdownSelect = (value: string, formik: FormikProps<any>) => {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const tagIndex = tags.findIndex((tag) => tag.id === Number(value));
    const updatedContent = Modifier.replaceText(
      currentContent,
      selectionState,
      tags[tagIndex].title
    );
    const newEditorState = EditorState.push(
      editorState,
      updatedContent,
      "change-block-type"
    );
    formik.setFieldValue(
      "message",
      getHTMLContentFromEditorState(newEditorState)
    );
    setEditorState(newEditorState);
  };

  return (
    <MessagingTemplatesMainContainer>
      <Typography
        content="MESSAGING TEMPLATE"
        styleName="candidateNameEmailOpen"
        color="black80"
      />
      <Formik
        enableReinitialize
        initialValues={{
          message: selectedTemplates?.message,
          subject: selectedTemplates?.subject ?? "",
          template_name:
            selectedTemplates.id === 0 ? "" : selectedTemplates?.template_name,
        }}
        onSubmit={() => {}}
      >
        {(formik) => {
          const { subject, template_name, message } = formik.values;

          return (
            <>
              <Container>
                <Typography
                  content="Choose Template. Choose Template. Choose Template. Choose template and add information. Choose Template. This is where we talk talk talk."
                  styleName="tableRowDefault"
                  color="black80"
                />
                <div className="d-flex gap-2">
                  <SelectContainer isfocused={isFocused}>
                    <Typography
                      content="Choose Template"
                      styleName="inputLabel"
                      color="black80"
                    />
                    <Select
                      onSelect={templateDropdownSelect}
                      value={
                        selectedTemplates.id === undefined
                          ? undefined
                          : String(selectedTemplates.id)
                      }
                      placeholder="Choose Template"
                      style={{
                        width: screen.lg ? 287 : "100%",
                      }}
                      onBlur={() => setFocused(false)}
                      onClick={() => setFocused(!isFocused)}
                      suffixIcon={
                        <>
                          <ICONS.VerticalLineIcon />
                          <div
                            className={clsx(
                              isFocused && "select-template-dropdown-open"
                            )}
                          >
                            <ICONS.AngelDown />
                          </div>
                        </>
                      }
                    >
                      <Option value="0">
                        <div className="d-flex align-items-center gap-1">
                          <ICONS.PlusCircleIcon color="success_default" />
                          <span className="d-flex align-items-center ">
                            Add New Template
                          </span>
                        </div>
                      </Option>
                      {templates
                        .filter((template) => template.is_default === true)
                        .map((template) => (
                          <Option value={String(template.id)}>
                            <span>{template.template_name}</span>
                          </Option>
                        ))}
                      <Option className="option-disable">
                        <div className="d-flex align-items-center gap-2">
                          <Line />
                          <ICONS.PlusCircleIcon
                            color="black50"
                            width={25}
                            height={25}
                          />
                          <Line />
                        </div>
                      </Option>
                      {templates
                        .filter((template) => template.is_default === false)
                        .map((template) => (
                          <Option value={String(template.id)}>
                            <span>{template.template_name}</span>
                          </Option>
                        ))}
                    </Select>

                    {Object.keys(selectedTemplates).length > 0 && (
                      <TemplateNotification
                        heading={
                          selectedTemplates.is_default
                            ? selectedTemplates.template_name
                            : "Add New Template"
                        }
                        description={selectedTemplates.template_description}
                      />
                    )}
                  </SelectContainer>

                  {Object.keys(selectedTemplates).length > 0 &&
                    !selectedTemplates.is_default && (
                      <div className="w-100 pl-1">
                        <FormFields.Input
                          {...messageTemplateField.template_name}
                        />
                      </div>
                    )}
                </div>
              </Container>
              <div>
                <div className="subject">
                  <Typography
                    content="Subject*"
                    styleName="text1"
                    color="black80"
                  />
                  <div className="w-100">
                    <FormFields.Input
                      {...{
                        ...messageTemplateField.subject,
                        disabled:
                          Object.keys(selectedTemplates).length > 0
                            ? selectedTemplates.is_default
                            : true,
                      }}
                    />
                  </div>
                </div>
              </div>
              <Wrapper
                disable={
                  Object.keys(selectedTemplates).length > 0 ? false : true
                }
                className={clsx(
                  !(Object.keys(selectedTemplates).length > 0) &&
                    "disable-message-editor"
                )}
              >
                <DropdownButton
                  title="+ tag"
                  variant=""
                  disabled={
                    Object.keys(selectedTemplates).length > 0 ? false : true
                  }
                  onSelect={(value) => {
                    tagDropdownSelect(value as string, formik);
                  }}
                  className="d-flex justify-content-end"
                >
                  {tags.map((tag) => (
                    <Dropdown.Item eventKey={tag.id}>{tag.title}</Dropdown.Item>
                  ))}
                </DropdownButton>
                <div className="message-label">
                  <Typography
                    content="Message*"
                    styleName="text1"
                    color="black80"
                  />
                  {tags.length > 0 && (
                    <MessageEditor
                      {...{
                        ...messageTemplateField.message,
                        editorState: editorState,
                        setEditorState: setEditorState,
                        disabled:
                          Object.keys(selectedTemplates).length > 0
                            ? false
                            : true,
                      }}
                    />
                  )}
                </div>
              </Wrapper>
              <ButtonContainer>
                <button
                  className="delete-button"
                  disabled={
                    Object.keys(selectedTemplates).length > 0
                      ? !selectedTemplates.deletable
                      : true
                  }
                  onClick={() => setIsDeleteModalOpen(true)}
                >
                  Delete
                </button>
                <div>
                  <Buttons
                    isDisable={!(Object.keys(selectedTemplates).length > 0)}
                    handleClick={() => {
                      if (
                        message === selectedTemplates.message &&
                        selectedTemplates.subject === subject &&
                        selectedTemplates.template_name === template_name
                      ) {
                        setEditorState(getInitialContentState_WithHTML(""));
                        dispatch(
                          messageTemplateActions.setSelectedTemplates({})
                        );
                      } else {
                        setIsResetModalOpen(true);
                      }
                    }}
                    label="Cancel"
                    variant="light"
                  />
                  <Buttons
                    isDisable={!(Object.keys(selectedTemplates).length > 0)}
                    handleClick={() => {
                      formikSubmitHandler({
                        formFields: messageTemplateField,
                        formik,
                        idPrefix,
                        onSubmitHandler,
                      });
                    }}
                    label="Save"
                    variant="success"
                  />
                </div>
              </ButtonContainer>
              <DeactivateModal
                isModalOpen={isDeleteModalOpen}
                setIsModalOpen={setIsDeleteModalOpen}
                heading="Delete Template"
                content={"Are you sure you want to delete this template?"}
                handleclick={() => {
                  API_CALLS.MESSAGE_TEMPLATE.deleteMessageTemplate(
                    selectedTemplates.id
                  );
                  setEditorState(getInitialContentState_WithHTML(""));
                  dispatch(messageTemplateActions.setSelectedTemplates({}));
                  setIsDeleteModalOpen(false);
                }}
              />
              <DeactivateModal
                isModalOpen={isResetModalOpen}
                setIsModalOpen={setIsResetModalOpen}
                heading="Reset Template"
                content={"Are you sure you want to revert this changes?"}
                handleclick={() => {
                  formik.resetForm();
                  setEditorState(
                    getInitialContentState_WithHTML(selectedTemplates.message)
                  );
                  setIsResetModalOpen(false);
                }}
              />
            </>
          );
        }}
      </Formik>
    </MessagingTemplatesMainContainer>
  );
};

export default MessagingTemplates;
