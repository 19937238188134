import {
  Card,
  CardContainer,
  CardNumber,
  Container,
  DropDownContainer,
  DropDownMenuContainer,
} from "./style";
import { cardTypeIcon } from "./utils";
import { Typography } from "src/components/Basic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";

const CardList = () => {
  const { cardList } = useAppSelector((state) => state.stripe);
  return (
    <Container>
      {cardList.map(({ last_4_digits, expiry_date, id, type, is_default }) => {
        return (
          <CardContainer key={id}>
            <Card
              selected={is_default}
              className={clsx(is_default && "selected-card")}
            >
              {cardTypeIcon[type as keyof typeof cardTypeIcon]}
              <CardNumber>
                <Typography
                  styleName="billingCardNumber"
                  content={"****"}
                  color={"jobCheckoutCardNumber"}
                />
                <Typography
                  styleName="billingCardNumber"
                  content={"****"}
                  color={"jobCheckoutCardNumber"}
                />
                <Typography
                  styleName="billingCardNumber"
                  content={"****"}
                  color={"jobCheckoutCardNumber"}
                />
                <Typography
                  styleName="billingCardNumber"
                  content={last_4_digits}
                  color={"jobCheckoutCardNumber"}
                />
              </CardNumber>

              <Typography
                styleName="billingCardExpiry"
                content={expiry_date}
                color={"jobCheckoutCardNumber"}
                className="expiry-date"
              />
              <Dropdown>
                <DropDownContainer className="btn">
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    size="lg"
                    color={theme.colors.settings_icon}
                  />
                </DropDownContainer>
                <DropDownMenuContainer className="dropdown-menu tx-13">
                  <Dropdown.Item href="#" onClick={() => {}}>
                    <Typography
                      content="Set as default"
                      styleName="tableSubHeading"
                      color="black_pure"
                    />
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      API_CALLS.STRIPE.deleteStripeCard(id);
                    }}
                  >
                    <Typography
                      content="Remove"
                      styleName="tableSubHeading"
                      color="black_pure"
                    />
                  </Dropdown.Item>
                </DropDownMenuContainer>
              </Dropdown>
            </Card>
            {is_default && (
              <Typography
                styleName="text1"
                content="DEFAULT"
                className="default-card-tag"
              />
            )}
          </CardContainer>
        );
      })}
    </Container>
  );
};

export default CardList;
