import { API_INSTANCES } from "src/API_INSTANCES";
import { JobProps } from "src/Redux/slices";
import { store, actions } from "src/Redux";
import { jobCreateUtils } from "src/components/pages/CreateJob/1-JobCreate/Utils";

const dispatch = store.dispatch;

// const create = (_values: JobProps.CreateNovaJobAPI) => {
//   dispatch(actions.job.setJobCreated(true));
//   dispatch(actions.job.setStepNumber(2));
//   dispatch(actions.job.setCreateNovaJobError(``));
// };

const create = (values: JobProps.CreateNovaJobAPI) => {
  dispatch(actions.job.setCreateNovaJobAPIInProgress(true));
  API_INSTANCES.JOB.create(values)
    .then((response) => {
      console.log("CREATE JOB RESPONSE", response);
      dispatch(actions.job.setJobCreated(true));
      dispatch(actions.job.setStepNumber(2));
      dispatch(actions.job.setCreateNovaJobError(``));
    })
    .catch((error) => {
      const errorMessage = error.message || "Error while creating job";

      const errorDetail = error.response?.data?.detail[0];
      const fieldName = errorDetail?.loc[1];
      let fieldLabel = jobCreateUtils.initialJobCreateFields[fieldName]?.label;

      const APIError = errorDetail
        ? `${fieldLabel || fieldName} ${errorDetail?.msg}`
        : errorMessage;
      dispatch(actions.job.setCreateNovaJobError(`${APIError}`));
      console.log("CREATE JOB ERROR", error);
    })
    .finally(() => {
      dispatch(actions.job.setCreateNovaJobAPIInProgress(false));
    });
};

const get = (id: string | number) => {
  API_INSTANCES.JOB.get(id)
    .then((response) => {
      console.log("GET JOB RESPONSE", response);
    })
    .catch((error) => {
      console.log("GET JOB ERROR", error);
    });
};

const getChartData = (data: JobProps.getChartDataAPI) => {
  dispatch(actions.job.setChartDataAPIInProgress(true));
  API_INSTANCES.JOB.getChartData(data)
    .then((response) => {
      console.log("GET CHART RESPONSE", response.data);
      dispatch(actions.job.setChartData(response.data));
      dispatch(actions.job.setChartDataError(""));
    })
    .catch((error) => {
      if (error.response.status === 400)
        dispatch(actions.job.setChartDataError("No results"));
      else if (error.response.status === 406)
        dispatch(actions.job.setChartDataError("Not enough data"));
      else dispatch(actions.job.setChartDataError(error.response.data.detail));
      console.log("GET CHART ERROR", error);
    })
    .finally(() => {
      dispatch(actions.job.setChartDataAPIInProgress(false));
    });
};

export const JOBS = { create, get, getChartData };
