const FileDownloadIcon = () => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60254 -0.000244141C1.94568 -0.000244141 0.602539 1.3429 0.602539 2.99976V20.9998C0.602539 22.6566 1.94569 23.9998 3.60254 23.9998H15.6025C17.2594 23.9998 18.6025 22.6566 18.6025 20.9998V7.99976L14.6025 3.99976L10.6025 -0.000244141H3.60254Z"
        fill="#EDEDF5"
      />
      <path
        d="M18.6025 7.99976L12.6025 7.99976C11.498 7.99976 10.6025 7.10432 10.6025 5.99976L10.6025 -0.00024484L14.6025 3.99976L18.6025 7.99976Z"
        fill="#AEB7C6"
      />
      <path
        d="M9.60254 10.9998V18.4998M9.60254 18.4998L6.60254 14.9998M9.60254 18.4998L12.6025 14.9998"
        stroke="#AEB7C6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileDownloadIcon;
