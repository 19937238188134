import React, { useEffect } from "react";
import { AddUserModal, EditUserModal, DeactivateModal } from "../Modal";
import OverviewTable from "./OverviewTable";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";

const OverView = () => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    React.useState(false);
  const { editUserData } = useAppSelector((state) => state.settings);

  useEffect(() => {
    API_CALLS.TEAM_OVERVIEW.getTeamOverview();
  }, []);
  return (
    <div className="w-100">
      <OverviewTable
        setIsAddUserModalOpen={setIsAddUserModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        setIsDeactivateModalOpen={setIsDeactivateModalOpen}
      />
      <AddUserModal
        isModalOpen={isAddUserModalOpen}
        setIsModalOpen={setIsAddUserModalOpen}
      />
      <EditUserModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />
      <DeactivateModal
        isModalOpen={isDeactivateModalOpen}
        setIsModalOpen={setIsDeactivateModalOpen}
        heading={`${
          editUserData.member_status === "deactivated"
            ? "Activate"
            : editUserData.member_status === "active"
            ? "Deactivate"
            : "Remove"
        } User`}
        content={`Are you sure you want to ${
          editUserData.member_status === "deactivated"
            ? "activate"
            : editUserData.member_status === "active"
            ? "deactivate"
            : "remove"
        }  this user ?`}
        handleclick={() => {
          if (editUserData.member_status === "invited") {
            API_CALLS.TEAM_OVERVIEW.deleteInvitedMember(editUserData.id);
          } else {
            API_CALLS.TEAM_OVERVIEW.activeDeactiveUser(
              editUserData.is_active,
              editUserData.id
            );
          }
          setIsDeactivateModalOpen(false);
        }}
      />
    </div>
  );
};

export default OverView;
