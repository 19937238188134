import { Row, Col } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";

import { IconButton, FormFields } from "src/components/Basic";
import { CreateJobProps } from "../CreateJob";
import { QualificationStyles } from "../Styles";
import { CreateJobStep1Context } from ".";
import { Field, FieldProps } from "formik";
import { jobCreateUtils } from "./Utils";
import { ICONS } from "src/assets/custom_Icons";
import { useAppSelector } from "src/Redux";

export const Question = ({ questionNumber }: CreateJobProps.Question) => {
  const { removeQuestion } = useContext(CreateJobStep1Context);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const xIconFillColor = isDarkTheme ? "#A9ABBD" : "black";

  //#region Field names
  const {
    qualifyingQuestion,
    responseType,
    idealAnswerNumeric,
    rejectCriteria,
    idealAnswerYesNo,
  } = jobCreateUtils.QualifyingQuestionFields;
  const getFieldName = (object: any) => object.fieldName + questionNumber;
  const qualifyingQuestionFieldName = getFieldName(qualifyingQuestion);
  const responseTypeFieldName = getFieldName(responseType);
  const idealAnswerFieldName = getFieldName(idealAnswerNumeric);
  const rejectCriteriaFieldName = getFieldName(rejectCriteria);
  //#endregion

  return (
    <QualificationStyles.FormContainer
      key={questionNumber}
      className="question-container"
    >
      <QualificationStyles.DeleteIconContainer
        onClick={() => removeQuestion(questionNumber)}
        className="delete-icon-container"
      >
        <ICONS.XIcon fill={xIconFillColor} />
      </QualificationStyles.DeleteIconContainer>
      <Row>
        <Col xs={11} sm={10} lg={6}>
          <FormFields.TextArea
            {...qualifyingQuestion}
            fieldName={qualifyingQuestionFieldName}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={3}>
          <FormFields.Dropdown
            {...responseType}
            fieldName={responseTypeFieldName}
          />
        </Col>
        <Col sm={6} lg={3}>
          <Field>
            {({ form }: FieldProps) => {
              const { getFieldMeta } = form;
              const { value } = getFieldMeta(responseTypeFieldName);
              return (
                <>
                  {value === "yesno" && (
                    <FormFields.Dropdown
                      key={`ideal_answer${questionNumber}_yesno`}
                      {...idealAnswerYesNo}
                      fieldName={idealAnswerFieldName}
                    />
                  )}
                  {value === "numeric" && (
                    <FormFields.Input
                      key={`ideal_answer${questionNumber}_numeric`}
                      {...idealAnswerNumeric}
                      fieldName={idealAnswerFieldName}
                    />
                  )}
                </>
              );
            }}
          </Field>
        </Col>
        <Col>
          <FormFields.Checkbox
            {...rejectCriteria}
            fieldName={rejectCriteriaFieldName}
          />
        </Col>
      </Row>
    </QualificationStyles.FormContainer>
  );
};

export const QualifyingQuestion = () => {
  const { questionComponents } = useAppSelector((store) => store.job);

  const { addQuestion } = useContext(CreateJobStep1Context);
  const MAX_QUESTION_COUNT = 5;
  const canAddQuestion =
    Object.keys(questionComponents).length < MAX_QUESTION_COUNT;

  return (
    <QualificationStyles.QualificationContainer>
      {Object.values(questionComponents).map((question) => question)}
      <Row style={{ width: "fit-content", margin: "auto" }}>
        <IconButton
          handleClick={addQuestion}
          icon={faPlus}
          position="right"
          label="Add another"
          isDisable={!canAddQuestion}
        />
      </Row>
    </QualificationStyles.QualificationContainer>
  );
};
