import React from "react";
import { Modal } from "react-bootstrap";
import { IModal } from "./CustomModal";

export const CustomModal: React.FC<IModal.IProps> = ({ isModalOpen, children }) => {
  return (
    <Modal centered show={isModalOpen}>
      {children}
    </Modal>
  );
};
