import { createSlice } from "@reduxjs/toolkit";
import { StripeProps } from "./Stripe";

const initialState: { cardList: StripeProps.StripeCard[] } = {
  cardList: [],
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setCardList: (state, action) => {
      state.cardList = action.payload;
    },
  },
});

export const stripeActions = stripeSlice.actions;
export const stripeReducer = stripeSlice.reducer;
