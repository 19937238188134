import { Col } from "react-bootstrap";
import { createContext, useContext, useState } from "react";

import { Typography } from "src/components/Basic";
import { CheckoutStyles } from "../Styles";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { CreateJobProps } from "../../CreateJob";
import { Bill } from "./Bill";
import { Heading } from "./Heading";
import { Modal } from "./Modal";

export const CheckoutSummaryContext = createContext(
  {} as CreateJobProps.CheckoutSummaryContext
);

export const CheckoutSummary = () => {
  const { isTabletOrBig } = useContext(AppContext);
  const { common, job } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { advertType, CreateNovaJobForm } = job;

  const [showModal, setShowModal] = useState(false);
  const [discount, setDiscount] = useState("0");

  const priceColor = isDarkTheme ? "black30" : "black90";
  const priceClasses = `d-flex flex-row justify-content-between`;
  const currency = CreateNovaJobForm.base_currency?.value;
  const subTotal = "39.00";
  const salesTax = "3.18";
  const salesTaxPercentage = 6.5;
  const total = `${(
    parseFloat(subTotal) +
    parseFloat(salesTax) -
    parseFloat(discount)
  ).toFixed(2)}`;

  return (
    <CheckoutSummaryContext.Provider
      value={{
        showModal,
        setShowModal,
        priceClasses,
        currency,
        subTotal,
        salesTax,
        salesTaxPercentage,
        total,
        discount,
        setDiscount,
      }}
    >
      {!isTabletOrBig && showModal && <Modal />}
      <Col sm={12} md={6} className="checkout checkout-left gap-0">
        <div>
          <Heading />
          <CheckoutStyles.BillSummary>
            <Typography
              styleName="jobCheckoutTitle"
              content={`${advertType} package`}
              color="primary_default"
            />
            <Typography
              styleName="jobCheckoutPrice"
              content={`${currency}${total}`}
              color={priceColor}
            />
          </CheckoutStyles.BillSummary>

          {isTabletOrBig && <Bill />}
        </div>
      </Col>
    </CheckoutSummaryContext.Provider>
  );
};
