import { useContext } from "react";
import styled from "styled-components";

import { CheckoutCardsContext } from ".";
import { theme } from "src/components/Theme";
import { Typography } from "src/components/Basic";
import { Form } from "react-bootstrap";
import { CheckoutUtils } from "../Utils";
import { PayNowButton } from "../Common";
import { AppContext } from "src/App";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";

export const CheckOutCardsList = () => {
  const dispatch = useAppDispatch();
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const { isDesktopOrBig, isTabletOrBig } = useContext(AppContext);
  const { cards, selectedCardIndex, setSelectedCardIndex } =
    useContext(CheckoutCardsContext);

  const cardNumberColor =
    isTabletOrBig && isDarkTheme ? "black50" : "jobCheckoutCardNumber";
  return (
    <Container>
      {cards.map(({ cardNumber, cardType, expiry }, index) => {
        let cardLastDigits = cardNumber.slice(cardNumber.length - 4);
        const cardNumberStar = (
          <Typography
            styleName="jobCheckoutCardNumber"
            content={"****"}
            color={cardNumberColor}
          />
        );

        return (
          <CardContainer key={cardNumber}>
            {isDesktopOrBig && (
              <Form.Label className="custom-control custom-radio">
                <Form.Control
                  type="radio"
                  className="custom-control-input"
                  checked={index === selectedCardIndex}
                  onClick={() => setSelectedCardIndex(index)}
                />
                <span className="custom-control-label" />
              </Form.Label>
            )}
            <Card
              selected={index === selectedCardIndex}
              onClick={() => setSelectedCardIndex(index)}
            >
              {CheckoutUtils.cardTypeIcon[cardType]}
              <CardNumber>
                {cardNumberStar}
                {cardNumberStar}
                {cardNumberStar}
                <Typography
                  styleName="jobCheckoutCardNumber"
                  content={cardLastDigits}
                  color={cardNumberColor}
                />
              </CardNumber>

              <Typography
                styleName="jobCheckoutCardNumber"
                content={expiry}
                color={cardNumberColor}
              />
            </Card>
          </CardContainer>
        );
      })}
      <PayNowButton
        disabled={false}
        clickHandler={() => {
          dispatch(actions.job.setShowCampaignLoadingAnimation(true));
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Card = styled.div<{ selected: boolean }>`
  height: 66px;
  width: 353px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  padding: 20px 32px 20px 20px;
  background: ${({ selected }) =>
    selected ? theme.colors.success_default : "#f4f5f4"};
  box-shadow: ${({ selected }) =>
    selected ? theme.Effects.card_selected : "none"};
  @media (min-width: 768px) {
    background: none;
    box-shadow: none;
    border: 1px solid
      ${({ selected }) =>
        selected ? theme.colors.primary_default : theme.colors.black30};
  }
`;
const CardNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  flex-grow: 1;
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .custom-control {
    cursor: pointer;
  }
`;
