import { theme } from "src/components/Theme";
import styled from "styled-components";
import angeldowndark from "./AngelDownDark.svg";
import angeldownlight from "./AngelDownLight.svg";

export const MessagingTemplatesMainContainer = styled.div`
  max-width: 699px;
  background: ${theme.colors.white_pure};
  border-radius: 5px;
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  padding: 19px 20px;
  .subject {
    display: flex;
    gap: 25px;
    @media (max-width: 991px) {
      flex-direction: column;
      gap: 6px;
    }
    & > label {
      width: 52px;
    }
  }
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 20px 12px;
    & > label {
      padding-left: 4px;
    }
  }
  #message-template_subject:disabled {
    background: ${theme.colors.grey_disable};
    box-shadow: none;
    .dark-theme & {
      background: ${theme.colors.black120};
      box-shadow: none !important;
      opacity: 1;
    }
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    & > label {
      color: ${theme.colors.grey10};
    }
    .subject > label {
      color: ${theme.colors.dark_input_label};
    }
  }
`;

export const Container = styled.div`
  padding: 24px 0px 16px 77px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 991px) {
    padding: 15px 4px 20px;
    gap: 28px;
    & > div {
      flex-direction: column !important;
    }
  }
  & > label {
    @media (min-width: 992px) {
      max-width: 534px;
    }
  }
  .dark-theme & > label {
    color: ${theme.colors.grey_subtitle2};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 7px 0px 72px;
  button {
    font-weight: 600;
  }
  @media (max-width: 991px) {
    margin: 0 4px 16px 0;
  }
  div {
    gap: 12px;
    display: flex;
  }
  .delete-button,
  .dark-theme & .delete-button {
    color: ${theme.colors.secondary_default} !important;
    border: none;
    background: none !important;
    text-decoration: underline;
    font-weight: 500;
    font-size: 11px;
    font-family: Poppins;
    padding: 0;
    &:hover {
      color: ${theme.colors.secondary_hover} !important;
    }
    &:disabled {
      color: ${theme.colors.secondary_disable} !important;
      cursor: not-allowed;
    }
  }
  .dark-theme & {
    div button,
    div button:hover {
      color: ${theme.colors.black90};
    }
    div button:first-child,
    div button:first-child:hover {
      background: ${theme.colors.light_default}!important;
    }
  }
`;

export const Wrapper = styled.div<{ disable: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 36px;
  border-bottom: 1px solid ${theme.colors.black10};
  @media (max-width: 991px) {
    padding: 20px 0 8px;
    border: none;
  }
  .show .dropdown-toggle {
    box-shadow: 0px 0px 1px 0px #1e96f2 inset, 0px 0px 3px 0px #1e96f2;
  }
  .dropdown-toggle {
    border-radius: 4px;
    border: 1px solid ${theme.colors.primary_default};
    background: ${theme.colors.white_pure};
    color: ${theme.colors.primary_default};
    &:hover {
      box-shadow: 0px 0px 1px 0px #1e96f2 inset, 0px 0px 3px 0px #1e96f2;
    }
    ${theme.text.authInputText};
    .dark-theme & {
      &:hover {
        color: ${theme.colors.primary_default};
      }
      background: ${theme.colors.black190};
    }
    &::after {
      display: none;
    }
    &:active {
      border: 1px solid ${theme.colors.primary_default};
      background: ${theme.colors.white_pure};
      color: ${theme.colors.primary_default};
      .dark-theme & {
        background: ${theme.colors.black190};
      }
    }
    &:disabled {
      border-color: ${theme.colors.primary_disable};
      color: ${theme.colors.primary_disable};
      .dark-theme & {
        border: none;
        background: ${theme.colors.black190};
      }
    }
  }
  .dropdown-menu {
    inset: auto !important;
    transform: translate(-6px, 46px) !important;
    width: 190px;
  }
  & .rdw-editor-toolbar {
    background: ${({ disable }) => disable && theme.colors.grey_disable};
    border: ${({ disable }) => disable && "none"};
  }
  & .rdw-option-wrapper {
    background: ${({ disable }) => disable && theme.colors.grey_disable};
  }
  .public-DraftStyleDefault-block {
    ${theme.text.text_14pt};
    line-height: 28px;
    .dark-theme & {
      color: ${theme.colors.black20};
    }
  }
  .message-label {
    & > label {
      width: 63px;
    }
    display: flex;
    gap: 14px;
    @media (max-width: 991px) {
      flex-direction: column;
      gap: 6px;
    }
  }
  ${({ disable }) =>
    disable &&
    `  .rdw-editor-wrapper {
    background: ${theme.colors.grey_disable};
    
    .public-DraftEditorPlaceholder-inner {
      color: ${theme.colors.black50};
    }
  }`}
  .dark-theme & {
    border-bottom: none;
    .message-label > label {
      color: ${theme.colors.dark_input_label};
    }
  }
`;

export const SelectContainer = styled.div<{ isfocused: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (min-width: 1440px) {
    gap: 0;
    & > label {
      margin-bottom: 6px;
    }
  }
  .select-template-dropdown-open {
    transform: rotate(180deg);
    svg path {
      fill: ${theme.colors.black_pure};
      .dark-theme & {
        fill: ${theme.colors.grey_disable_outline};
      }
    }
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    ${theme.text.authInputText};
    display: flex;
    align-items: center;
    gap: 3px;
    font-weight: 400;
  }

  .ant-select-selector {
    border-radius: 4px;
    border: 1px solid ${theme.colors.black10} !important;
    background: ${theme.colors.white_pure};
    height: 40px !important;
  }
  .ant-select-focused .ant-select-selector {
    box-shadow: ${({ isfocused }) =>
      isfocused
        ? `${theme.colors.success_default} 0px 0px 3px, ${theme.colors.white_pure} 0px 0px 0px 1000px inset`
        : "none"} !important;
    border-color: ${theme.colors.black10} !important;
    .dark-theme & {
      box-shadow: ${({ isfocused }) =>
        isfocused
          ? `${theme.colors.success_default} 0px 0px 3px, ${theme.colors.black110} 0px 0px 0px 1000px inset`
          : "none"} !important;
      border-color: ${theme.colors.black400} !important;
    }
  }
  .ant-select:hover .ant-select-selector,
  .ant-select:focus .ant-select-selector,
  .ant-select:active .ant-select-selector {
    box-shadow: ${theme.colors.success_default} 0px 0px 3px,
      ${theme.colors.white_pure} 0px 0px 0px 1000px inset !important;
    border-color: ${theme.colors.black10}!important;
    .dark-theme & {
      box-shadow: ${theme.colors.success_default} 0px 0px 3px,
        ${theme.colors.black110} 0px 0px 0px 1000px inset !important;
      border-color: ${theme.colors.black400} !important;
    }
  }
  .ant-select-open .ant-select-selection-item {
    color: ${theme.colors.black_pure} !important;
  }
  .template-notification {
    ${theme.text.pipelineDefaultNav}
    color:  ${theme.colors.info_default};
    font-weight: 500;
    display: none;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding-top: 6px;
    @media (min-width: 992px) and (max-width: 1439px) {
      display: flex;
    }
  }

  .dark-theme & {
    & > label {
      color: ${theme.colors.dark_input_label};
    }
    .ant-select-selector {
      background: ${theme.colors.black110};
      border-color: ${theme.colors.black400} !important;
      -webkit-text-fill-color: ${theme.colors.dark_input_text};
    }
    .ant-select-selection-placeholder {
      color: ${theme.colors.placeholder_color};
    }
    .ant-select-selection-item,
    .ant-select-open .ant-select-selection-item {
      color: ${theme.colors.black20} !important;
    }
  }
`;

export const NotificationContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
  .accordion-item {
    border-radius: 5px;
    border: 1px solid ${theme.colors.black10};
    border-top: 2px solid ${theme.colors.info_default};
    background: ${theme.colors.white_pure};
    box-shadow: 1px 1px 2px 0px rgba(154, 154, 204, 0.3);
    .accordion-button {
      padding: 10px;
      color: ${theme.colors.info_default};
      display: flex;
      gap: 12px;
      text-transform: uppercase;
      ${theme.text.pipelineDefaultNav}
      &:not(.collapsed) {
        color: ${theme.colors.info_default} !important;
        background: ${theme.colors.white_pure};
        border-top: 6px solid ${theme.colors.info_default};
        border-radius: 0;
      }
      &::after {
        background-image: url(${angeldownlight});
        width: 9px;
        height: 5px;
        background-size: auto;
        .dark-theme & {
          background-image: url(${angeldowndark});
        }
      }
    }
    .accordion-body {
      padding: 12px 26px 22px 14px;
      color: ${theme.colors.blue_grey};
      ${theme.text.activityTextDesc}
    }
  }
  .dark-theme & {
    .accordion .accordion-header button {
      color: ${theme.colors.info_default} !important;
      background: rgba(78, 194, 240, 0.2) !important;
    }
    .accordion-item {
      border: 1px solid rgba(114, 210, 249, 0.2) !important;
      border-top: 2px solid ${theme.colors.info_default}!important;
      background: rgba(78, 194, 240, 0.2) !important;
    }
    .accordion-body {
      background: rgba(78, 194, 240, 0.2) !important;
      color: ${theme.colors.grey_subtitle2} !important;
    }
  }
`;

export const TagContainer = styled.span`
  padding: 4px 6px 3px 6px;
  background: ${theme.colors.grey_tag};
  border-radius: 2px;
  white-space: nowrap;
  ${theme.text.activityTextDesc}
  .dark-theme & {
    background: ${theme.colors.grey100};
    color: ${theme.colors.black80};
  }
`;

export const NotificationContainerDesktop = styled.div`
  position: absolute;
  top: 115px;
  right: 23px;
  width: 272px;
  border-radius: 5px;
  background: ${theme.colors.white_pure};
  border-top: 2px solid ${theme.colors.primary_default};
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  padding: 14px 20px 20px 14px;

  display: none;
  @media (min-width: 1440px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    & > label {
      color: ${theme.colors.grey_subtitle2};
    }
    & > div > label {
      color: ${theme.colors.white_pure};
    }
  }
`;

export const WarningIconContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.info_default};
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: #b8e6f9;
    }
  }

  .dark-theme & {
    svg {
      width: 28px;
      height: 28px;
      path {
        fill: #b8e6f9;
      }
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  background: ${theme.colors.black50};
`;
