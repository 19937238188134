import { useState } from "react";
import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELDS, FIELD_COLORS } from "../FormFieldsStyles/common";

export const Spinners = (props: FormFieldsProps.Spinner) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    spinnerType = "normal",
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    showLabel = true,
  } = props;

  const [isFocused, setFocused] = useState(false);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta }: FieldProps) => {
        const { onChange, value, onBlur } = field;
        const { touched, error } = meta;

        const hasError = !isFocused && isRequired && touched && !value;
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <div>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <SpinnerContainer
              disabled={disabled}
              Error={hasError}
              className="is-loading"
              spinnerType={hasError ? "error" : spinnerType}
            >
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                type="text"
                className="form-control"
                name={fieldName}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                disabled={disabled}
                onFocus={() => setFocused(true)}
                onBlur={(e) => {
                  setFocused(false);
                  onBlur(e);
                }}
              />
              <div className="spinner-border spinner-border-sm"></div>
            </SpinnerContainer>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </div>
        );
      }}
    </Field>
  );
};

const SpinnerContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  spinnerType: FormFieldsProps.spinnerType;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_FORM_CONTROL : FIELDS.FORM_CONTROL;
  }};
  .spinner-border {
    border-color: ${({ spinnerType }) => {
      if (spinnerType === "normal") return FIELD_COLORS.SPINNER_NORMAL;
      if (spinnerType === "error") return FIELD_COLORS.SPINNER_ERROR;
      if (spinnerType === "warning") return FIELD_COLORS.SPINNER_WARNING;
      if (spinnerType === "success") return FIELD_COLORS.SPINNER_SUCCESS;
    }};
    border-right-color: transparent;
  }
`;
