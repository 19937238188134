import { IIconProps } from "./customIcons";

export const BackIcon = ({ fill, height, width }: IIconProps.Iprops) => (
  <svg
    width={width || "16"}
    height={height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.41679 6.99996H14.9998C15.265 6.99996 15.5194 7.10532 15.7069 7.29285C15.8944 7.48039 15.9998 7.73474 15.9998 7.99996C15.9998 8.26518 15.8944 8.51953 15.7069 8.70707C15.5194 8.8946 15.265 8.99996 14.9998 8.99996H3.41679L8.00779 13.591C8.19556 13.7786 8.3011 14.0331 8.3012 14.2986C8.30129 14.5641 8.19593 14.8187 8.00829 15.0065C7.82065 15.1942 7.5661 15.2998 7.30064 15.2999C7.03518 15.3 6.78056 15.1946 6.59279 15.007L0.292786 8.70696C0.105315 8.51943 0 8.26512 0 7.99996C0 7.73479 0.105315 7.48049 0.292786 7.29296L6.59279 0.992959C6.78069 0.805318 7.03544 0.700008 7.30099 0.700196C7.56655 0.700383 7.82115 0.806053 8.00879 0.993959C8.19643 1.18186 8.30174 1.43661 8.30155 1.70217C8.30136 1.96772 8.19569 2.22232 8.00779 2.40996L3.41679 6.99996Z"
      fill={fill || "#8792A2"}
    />
  </svg>
);
