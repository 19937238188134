import React from "react";
import { Card, Nav } from "react-bootstrap";
import styled from "styled-components";

import { theme } from "src/components/Theme";
import { settingsOption } from "../Utils";
import { Typography } from "src/components/Basic";
import { ISettings } from "../Settings";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";

const SettingsTabHeader: React.FC<ISettings.IProps> = ({
  setActiveTab,
  setIsHeaderShow,
  activeTab,
}) => {
  return (
    <CustomCard className="card custom-card">
      <Card.Body className="main-content-left main-content-left-mail card-body">
        <div className="main-settings-menu">
          <SettingNavbar
            className="nav main-nav-column "
            defaultActiveKey={activeTab}
          >
            {settingsOption.map((option, index) => (
              <Nav.Item
                as="li"
                key={index}
                onClick={() => {
                  setActiveTab(option.key);
                  localStorage.setItem(
                    LOCAL_STORAGE_KEY.ACTIVE_SETTINGS_TAB,
                    option.key
                  );
                  setIsHeaderShow(false);
                }}
              >
                <NavLink
                  className="nav-link thumb  mb-2 "
                  eventKey={option.key}
                >
                  {option.icon}
                  <Typography
                    content={option.title}
                    color="black70"
                    styleName="authInputText"
                  />
                </NavLink>
              </Nav.Item>
            ))}
          </SettingNavbar>
        </div>
      </Card.Body>
    </CustomCard>
  );
};

export default SettingsTabHeader;

export const NavLink = styled(Nav.Link)`
  display: flex;
  gap: 13px;
  label {
    cursor: pointer;
    white-space: nowrap;
  }
  & svg path {
    @media (max-width: 991px) {
      stroke: ${theme.colors.primary_default};
      .dark-theme & {
        stroke: ${theme.colors.primary_default} !important;
      }
    }
  }
  &:hover {
    background: rgba(30, 150, 242, 0.2) !important;
    border-radius: 5px;
  }
  &.active {
    background: rgba(30, 150, 242, 0.2) !important;
    & label {
      @media (min-width: 992px) {
        color: ${theme.colors.primary_default} !important;
      }
      cursor: pointer;
    }
    svg path {
      @media (min-width: 992px) {
        stroke: ${theme.colors.primary_default};
      }
    }
  }
  .dark-theme & {
    label {
      color: ${theme.colors.black50};
    }
    svg path {
      stroke: ${theme.colors.black50};
    }
    &.active svg path {
      stroke: ${theme.colors.primary_default};
    }
  }
`;

const SettingNavbar = styled(Nav)`
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 1px;
    .nav-item {
      height: 68px;
      background: ${theme.colors.light_default};
      display: flex;
      align-items: center;
      cursor: pointer;
      a {
        margin-bottom: 0px !important;
        background: transparent !important;
      }
    }
    .dark-theme & .nav-item {
      background: ${theme.colors.black110};
      border-bottom: 1px solid ${theme.colors.blue_grey};
    }
  }
`;
const CustomCard = styled(Card)`
  @media (max-width: 991px) {
    box-shadow: none;
    margin: 0px;
    width:100%;
    & .main-content-left.main-content-left-mail.card-body {
      padding: 0;
    }
  }
  .dark-theme & {
    @media (max-width: 991px) {
      background: none !important;
    }
  }
`;
