import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { headerNavLinksData } from "../../../utils/headerNavLinksData";
import { CustomNavLink, IconButton } from "../../Basic";
import MobileLogo from "./MobileLogo";
import { ROUTES } from "src/utils/ROUTES";

const LeftHeaderSection = () => {
  const router = useLocation();
  const navigate = useNavigate();

  return (
    <div className="main-header-left">
      <MobileLogo />
      <div className="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group">
        <div className="d-flex gap-5">
          {headerNavLinksData.map((menuItem) => {
            return (
              <CustomNavLink
                key={menuItem.title}
                {...menuItem}
                currentPathName={router?.pathname}
              />
            );
          })}
          <IconButton
            handleClick={() => navigate(ROUTES.CREATE_JOB)}
            icon={faPlus}
            position="right"
            label="Create Job"
          />
        </div>
      </div>
    </div>
  );
};
export default LeftHeaderSection;
