export const AuthBackGround = () => (
  <svg
    width="449"
    height="740"
    viewBox="0 0 449 740"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_231_321)">
      <rect width="450" height="740" transform="translate(-1)" fill="white" />
      <rect x="-1" width="450" height="740" fill="#4A4A69" />
      <g clipPath="url(#clip1_231_321)">
        <path
          d="M-41.5 711C25.0503 711 79 657.05 79 590.5C79 523.95 25.0503 470 -41.5 470C-108.05 470 -162 523.95 -162 590.5C-162 657.05 -108.05 711 -41.5 711Z"
          fill="white"
        />
        <path
          d="M264.581 914.06C419.369 914.06 544.851 788.578 544.851 633.79C544.851 479.001 419.369 353.52 264.581 353.52C109.792 353.52 -15.6895 479.001 -15.6895 633.79C-15.6895 788.578 109.792 914.06 264.581 914.06Z"
          fill="white"
        />
        <path
          d="M630.59 1024.56C832.738 1024.56 996.61 860.687 996.61 658.54C996.61 456.393 832.738 292.52 630.59 292.52C428.443 292.52 264.57 456.393 264.57 658.54C264.57 860.687 428.443 1024.56 630.59 1024.56Z"
          fill="white"
        />
        <rect x="-973" y="610" width="2280" height="510" fill="white" />
      </g>
      <path
        d="M164.811 421C264.498 421 345.311 337.725 345.311 235C345.311 132.275 264.498 49 164.811 49C65.1231 49 -15.6895 132.275 -15.6895 235C-15.6895 337.725 65.1231 421 164.811 421Z"
        fill="#AEB7C6"
      />
      <path
        d="M117.919 521.641L106.531 604.037L142.953 561.196L175.538 567.724L154.684 507.7L117.919 521.641Z"
        fill="#24D5B8"
      />
      <path
        d="M333.08 521.641L344.468 604.037L308.046 561.196L275.454 567.724L296.316 507.7L333.08 521.641Z"
        fill="#24D5B8"
      />
      <ellipse
        cx="225.781"
        cy="577.357"
        rx="18.5678"
        ry="85.2315"
        fill="#FFD747"
      />
      <path
        d="M225.249 297.363C183.089 297.363 148.917 379.693 148.917 481.252C148.917 519.501 163.232 555.016 171.526 584.437H278.964C287.258 555.016 301.573 519.501 301.573 481.252C301.573 379.693 267.401 297.363 225.249 297.363Z"
        fill="#1E96F2"
      />
      <path
        d="M225.074 440.9C240.572 440.9 253.136 424.337 253.136 403.906C253.136 383.474 240.572 366.912 225.074 366.912C209.575 366.912 197.011 383.474 197.011 403.906C197.011 424.337 209.575 440.9 225.074 440.9Z"
        fill="#0C5E9D"
      />
      <path
        d="M165.803 565.49C167.849 572.048 169.815 578.373 171.526 584.437H278.964C280.675 578.373 282.641 572.04 284.687 565.49H165.803Z"
        fill="#68BCFC"
      />
      <g clipPath="url(#clip2_231_321)">
        <path
          d="M236.506 505.669C236.506 499.401 232.625 466.687 226.48 464.743C225.875 464.496 225.205 464.496 224.594 464.743C218.426 466.593 214.538 499.387 214.538 505.669C212.659 533.639 212.062 548.77 215.601 570.067C218.295 586.287 221.819 602.579 225.409 605.451V605.611C225.409 605.611 225.482 605.553 225.511 605.531C225.548 605.56 225.584 605.589 225.613 605.611V605.451C229.203 602.586 232.727 586.294 235.421 570.067C238.96 548.77 238.363 533.639 236.484 505.669H236.506Z"
          fill="#24D5B8"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_231_321">
        <rect
          width="450"
          height="740"
          fill="white"
          transform="translate(-1)"
        />
      </clipPath>
      <clipPath id="clip1_231_321">
        <rect
          width="2280.21"
          height="840.8"
          fill="white"
          transform="translate(-973 190)"
        />
      </clipPath>
      <clipPath id="clip2_231_321">
        <rect
          width="24.9682"
          height="141.049"
          fill="white"
          transform="translate(213.038 464.562)"
        />
      </clipPath>
    </defs>
  </svg>
);
